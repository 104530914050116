import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleStore } from "../../store/actionCreators/Inventory/InventoryAction";

const ViewStore = ({ sideToggle }) => {
  const store = useSelector((state) => state.get_single_store_data);
  const dispatch = useDispatch();
  const params = useParams();

  const [data, setData] = useState({
    store_name: "",
    city: "",
    region: "",
    address: "",
    email: "",
    contact: "",
    timing: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    dispatch(getSingleStore(params.store_id));
  }, []);

  console.log(store, "data");

  useEffect(() => {
    setData(store);
  }, [store]);

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {store ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      View Store
                    </Card.Title>
                  </div>
                  <Row className="mb-2">
                    <Col>
                      <label>Store Name:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data.store_name}
                        onChange={(e) =>
                          setData({ ...data, store_name: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>City:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data.city}
                        onChange={(e) =>
                          setData({ ...data, city: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Region:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data.region}
                        onChange={(e) =>
                          setData({ ...data, region: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Address:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data.address}
                        onChange={(e) =>
                          setData({ ...data, address: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Email:</label>
                      <input
                        required
                        type="email"
                        className="form-control"
                        value={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Contact:</label>
                      <input
                        required
                        type="number"
                        className="form-control"
                        value={data.contact}
                        onChange={(e) =>
                          setData({ ...data, contact: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>timing:</label>
                      <input
                        type="text"
                        placeholder="00 AM - 00 PM"
                        className="form-control"
                        value={data.timing}
                        onChange={(e) =>
                          setData({ ...data, timing: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Latitude:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={data.latitude}
                        onChange={(e) =>
                          setData({ ...data, latitude: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Longitude:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={data.longitude}
                        onChange={(e) =>
                          setData({ ...data, longitude: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default ViewStore;
