import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSync } from "react-icons/fa";

const SyncButton = ({ zomato_outlets_id }) => {
  const [loading, setLoading] = useState(false);

  const handleSync = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/sync_zomato_menu`;
    const data = {
      zomato_outlets_id: zomato_outlets_id,
    };

    try {
      setLoading(true);
      const response = await axios.post(url, data);

      if (response.status === 200) {
        toast.success("Sync successfully");
      }
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button className="btn btn-primary m-2" onClick={handleSync} disabled={loading}>
      {loading ? <FaSync className="fa-spin" /> : <FaSync />} Sync
    </button>
  );
};

export default SyncButton;
