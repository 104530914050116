import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import { getAllStoreReqSender } from "../../../store/actionCreators/Inventory/InventoryAction";
import StoreSentReqListPagination from "./StoreSentReqListPagination";

const StoreSentReqList = (props) => {
  const { query, clickedData, storeValue } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeReqSenderList = useSelector(
    (state) => state.get_all_store_req_sender
  );
  const page = useSelector((state) => state.store_req_receiver_pagination);

  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllStoreReqSender(query, page, storeValue));
  }, []);

  useEffect(() => {
    dispatch(getAllStoreReqSender(query, page, storeValue));
  }, [query, page, storeValue]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [storeReqSenderList]);

  const setDefaultData = () => {
    let storeList = [];
    if (storeReqSenderList.data && storeReqSenderList.data.data) {
      storeList = storeReqSenderList.data.data.map((c) => ({
        ...c,

        createdAt: new Date(`${c?.createdAt}`).toLocaleString(undefined, {
          timeZone: "Asia/Kolkata",
        }),
      }));
    }
    setStoreList(storeList);
  };

  console.log(props.editPermission, "props.editPermission");

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() =>
              navigate(`view_store_request/${row.store_request_id}`)
            }
            className="btn btn-sm btn-warning mx-2"
          >
            View
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() =>
              navigate(`view_store_request/${row.store_request_id}`)
            }
            className="btn btn-sm btn-warning mx-2"
          >
            View
          </button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "store_request_id",
      text: "Store Request ID",
      sort: true,
    },
    {
      dataField: "receiver_store_name",
      text: "Receiver Store Name",
      sort: true,
    },
    {
      dataField: "sender_store_name",
      text: "Sender Store Name",
      sort: true,
    },
    {
      dataField: "total_price",
      text: "Total Price",
      sort: true,
    },
    {
      dataField: "sub_total",
      text: "Subtotal",
      sort: true,
    },

    {
      dataField: "tax",
      text: "Tax",
      sort: true,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "sender_store_msg",
      text: "Sender Store Msg",
      sort: true,
    },
    {
      dataField: "receiver_store_msg",
      text: "Receiver Store Msg",
      sort: true,
    },
    {
      dataField: "order_status_history",
      text: "Order Status History",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: storeReqSenderList.data?.per_page,
    totalSize: storeReqSenderList.data
      ? storeReqSenderList.data.total_items
        ? storeReqSenderList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const storeReqSenderList = [...storeList];

    return storeReqSenderList;
  };

  if (!loading) {
    return (
      <React.Fragment>
        <Container>
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            All {clickedData?.status}
          </Col>

          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="store_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="store_id"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="store_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                      {(storeList || []).length === 0 && (
                                        <strong>No Data Found</strong>
                                      )}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <StoreSentReqListPagination
                                    pageNum={Math.ceil(
                                      storeReqSenderList?.data
                                        ? storeReqSenderList?.data
                                            ?.total_items /
                                            storeReqSenderList.data?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  } else return <Loader />;
};

export default StoreSentReqList;
