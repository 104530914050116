import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getLogoutReport } from "../../../store/actionCreators/Employees/EmployeeAction";
import { useNavigate } from "react-router-dom";

const ReportModal = ({ show, close, data }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const reportData = useSelector((state) => state.logout_report_data);

  console.log(reportData.data, "data");

  const showReportData = useCallback(() => {
    //   console.log( "shi");
    dispatch(getLogoutReport(data));
    navigate("/employee/individual/employeeReport/report", {
      state: { report: reportData?.data },
    });
    // }
  }, [data, dispatch]);

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Report Type</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: "auto" }}>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "60vh",
            display: "flex",
            justifyContent: "space-around",
            gap: "10px",
            width: "fit-content",
          }}
        >
          <Button
            className="btn btn-warning"
            type="submit"
            onClick={() => {
              showReportData();
              close();
            }}
          >
            Investor
          </Button>
          <Button
            className="btn btn-warning"
            type="submit"
            onClick={() => {
              showReportData();
              close();
            }}
          >
            Admin
          </Button>
          <Button
            className="btn btn-warning"
            type="submit"
            onClick={() => {
              showReportData();
              close();
            }}
          >
            Employee
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReportModal;
