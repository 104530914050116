export const couponsReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_COUPONS":
      return action.coupons;
    default:
      return state;
  }
};

export const couponReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_COUPON":
      return action.coupon;
    case "UPDATE_COUPON":
      return action.coupon;
    default:
      return state;
  }
};

export const CouponsPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_COUPONS_PAGE":
      return action.page;
    default:
      return state;
  }
};

// promos in coupon section

export const promosReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_PROMOS":
      return action.promos;
    default:
      return state;
  }
};
export const deletePromoReducer = (state = "", action) => {
  switch (action.type) {
    case "DEL_PROMOCODE":
      return state;
    default:
      return state;
  }
};

export const singlePromoReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_PROMO":
      return action.promo;
    case "UPDATE_PROMO":
      return action.promo;
    default:
      return state;
  }
};

// gift card reducers

export const deleteGiftCardReducer = (state = "", action) => {
  switch (action.type) {
    case "DEL_GIFTCARD":
      return state;
    default:
      return state;
  }
};

export const singleGiftReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_GIFT_CARD":
      return action.giftCard;
    case "UPDATE_GIFT_CARD":
      return action.giftCard;
    default:
      return state;
  }
};

export const giftCardsReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_GIFTS":
      return action.giftCards;
    default:
      return state;
  }
};

export const couponGroupReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_GROUPS":
      return action.groups;
    case "ADD_NEW_GROUP_COUPON":
      return {
        ...state,
        data: { ...state.data, data: [...state.data.data, action.group] },
      };
    case "UPDATE_GROUP_COUPON":
       return action.group;
    case "DELETE_SINGLE_GROUP":
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.filter(
            (g) => g.customer_group_name !== action.group.customer_group_name
          ),
        },
      };
    default:
      return state;
  }
};

export const getSingleProductReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_PRODUCT_LIST":
      return action.get_single_product_list;
    default:
      return state;
  }
};

// new coupons

export const allCouponsReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_COUPONS":
      return action.allcoupons;
    default:
      return state;
  }
};

export const getSingleCouponReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_COUPON":
      return action.get_single_coupon_new;
    default:
      return state;
  }
};

export const deleteCouponReducer = (state = "", action) => {
  switch (action.type) {
    case "DELETE_COUPON":
      return state;
    default:
      return state;
  }
};

export const GiftCardPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_GIFTCARD_PAGINGATION":
      return action.page;
    default:
      return state;
  }
};
export const promoCodePaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_PROMO_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
