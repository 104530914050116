import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getProductAddons } from "../../../store/actionCreators/Catalog/Catalog";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteAddonProduct } from "../../../store/actionCreators/Catalog/Catalog";
import AddMoreAddons from "./AddMoreAddons";
import EditAddonsOrder from "./EditAddonsOrder";
import { updateAddonsOrder } from "./../../../store/actionCreators/Catalog/Catalog";

const ShowProductAddons = ({ show, data, close }) => {
  console.log(data.product_list_id);
  const dispatch = useDispatch();

  const [editOrderModal, setEditOrderModal] = useState(false);
  const addons = useSelector((state) => state.get_product_addons);
  const [selectedAddons, setSelectedAddons] = useState([]);

  console.log(addons, "chel");
  const [showAddProductsModal, setShowAddProductModal] = useState(false);

  const handleClose = () => {
    setShowAddProductModal(false);
  };

  useEffect(() => {
    console.log("hello");
    dispatch(getProductAddons(data?.product_list_id));
  }, [data]);

  useEffect(() => {
    console.log("addons", addons);
    if (addons && addons.length > 0) {
      const selectedAddonsClone = addons.map((ao) => ({
        ...ao,
        title: ao.add_on.title,
      }));

      setSelectedAddons(selectedAddonsClone);
    }else{
      setSelectedAddons([]);
    }
  }, [addons]);

  const onDelete = (id) => {
    const selectedAddonsClone = selectedAddons.filter(
      (ao) => ao.per_product_add_ons_id !== id
    );
    setSelectedAddons(selectedAddonsClone);
    dispatch(deleteAddonProduct(id));
  };

  const orderAddons = (per_product_add_ons_id, order, required) => {
    const selectedAddonsClone = selectedAddons.map((ao) =>
      ao.per_product_add_ons_id === per_product_add_ons_id
        ? { ...ao, order, required }
        : ao
    );
    setSelectedAddons(selectedAddonsClone);
  };

  const onSubmitForAddOns = (e) => {
    e.preventDefault();
    const data = selectedAddons.map((ao) => ({
      per_product_add_ons_id: ao.per_product_add_ons_id,
      order: parseInt(ao.order),
      required: ao.required,
    }));
    console.log(data);
    dispatch(updateAddonsOrder(data));
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Addons List</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column ">
            {!addons ? (
              <div>No data available</div>
            ) : (
              addons &&
              selectedAddons.map((item, i) => {
                return (
                  <>
                    <div className="d-flex m-2">
                      <div key={i} className="">
                        {item.add_on?.title}
                      </div>
                      :
                      <button
                        type="button"
                        onClick={() => onDelete(item?.per_product_add_ons_id)}
                        className="btn btn-danger m-0 p-0 ms-3"
                        style={{
                          width: "81px",
                          height: "30px",
                          // borderRadius: "50%",
                        }}
                      >
                        <span className="font-bold text-2xl">Delete</span>
                      </button>
                    </div>
                  </>
                );
              })
            )}
          </div>

          <button
            className="btn btn-warning mt-2 mb-2"
            onClick={() => setShowAddProductModal(true)}
          >
            Add+ more Addons to Product
          </button>
          <button
            className="btn btn-warning mt-2 mb-2"
            style={{ marginLeft: "1rem" }}
            onClick={() => setEditOrderModal(true)}
          >
            Edit Addons order
          </button>
          <EditAddonsOrder
            show={editOrderModal}
            closeModal={() => setEditOrderModal(false)}
            selectedAddons={selectedAddons}
            orderAddons={orderAddons}
            onSubmit={onSubmitForAddOns}
          />
          <AddMoreAddons
            data={data}
            show={showAddProductsModal}
            close={handleClose}
            product_list_id={data.product_list_id}
            closeParent={() => close()}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ShowProductAddons;
