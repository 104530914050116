import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  get_category_branches,
  get_product_branch,
} from "../../../store/actionCreators/Catalog/Catalog";
import NoData from "../../NoData";
import Unauthorized from "../../unauthorized";
import BigCard from "./BigCard";
import { useParams } from "react-router-dom";
import { currentBranch } from "./../../../store/actionCreators/Catalog/Catalog";
import Loader from "../../Loader/Loader";

const IndividualCat = (props) => {
  const dispatch = useDispatch();
  const Branches = useSelector((state) => state.getBranchInCat);
  const jj = useSelector((state) => state.getCurrentCat);
  const branch = useSelector((state) => state.getCurrentBranch);
  const [currbranch, setCurrBranch] = useState({});
  const [selected, setSelected] = useState(+false);
  const CategoryData = useSelector((state) => state.getCatByBranch);
  const params = useParams();

  useEffect(() => {}, [CategoryData, Branches, branch]);

  const displayBranches = () => {
    if (Branches.data) {
      return Branches.data.map((item, index) => {
        return (
          <Dropdown.Item
            key={item.branch_id}
            eventKey={`["${item.branch.branch_name}","${item.branch_id}"]`}
          >
            {" "}
            {item.branch.branch_name}
          </Dropdown.Item>
        );
      });
    }
  };

  useEffect(() => {
    console.log("Id", params.id);
    dispatch(get_product_branch(params.id));
  }, [params.id]);

  useEffect(() => {
    if (Branches.data) {
      dispatch(currentBranch(Branches?.data[0]));
      setCurrBranch(Branches?.data[0]);
    }
  }, [Branches]);

  useEffect(() => {
    console.log("Current branch", currbranch);
    if (currbranch.branch) {
      dispatch(get_category_branches(params.id, currbranch.branch.branch_id));
    } else {
      dispatch(get_category_branches(params.id, currbranch[1]));
    }
  }, [currbranch]);

  const handleSelectB = (e) => {
    const item = JSON.parse(e);
    setSelected(+true);

    console.log({ item, Branches }, "item");
    setCurrBranch({ branch: { branch_name: item[0], branch_id: item[1] } });
  };

  const BigCard1 = () => {
    if (CategoryData.status === "success") {
      return CategoryData.data.map((item, index) => {
        return (
          <BigCard
            key={index}
            item={item}
            index={index}
            currbranch={currbranch}
            props={props}
            
          />
        );
      });
    }
  };

  const main = () => {
    if (props.viewPermission) {
      if (CategoryData) {
        if (
          CategoryData.data &&
          CategoryData.data.length > 0 &&
          Branches.data &&
          branch
        ) {
          return (
            <Container
              fluid
              className={props.sideToggle === true ? "closeDash" : "openDash"}
              style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
            >
              <Row>
                <Col lg={10} xs={12} className="dash-head">
                  DASHBOARD
                </Col>
                <Col lg={2} xs={12}>
                  <Row>
                    <Col>
                      {" "}
                      <div className=" p-2 ">
                        <div className="d-flex">
                          <DropdownButton
                            variant="light"
                            title={String(currbranch.branch?.branch_name)}
                            id="dropdown-menu-align-right"
                            onSelect={handleSelectB}
                          >
                            {displayBranches()}
                          </DropdownButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xl={6} sm={12} className="mb-4">
                  <Card
                    className=""
                    style={{ backgroundColor: "#fff", color: "grey" }}
                  >
                    <Card.Body className="">
                      <h5>
                        <b>
                          {jj.category_name} category in{" "}
                          { currbranch.branch?.branch_name}
                        </b>
                      </h5>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>{BigCard1()}</Row>
            </Container>
          );
        } else {
          return <Loader />;
        }
      } else {
        return (
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <NoData data="Not Available Currently" />
          </Container>
        );
      }
    }
  };
  return <>{main()}</>;
};

export default IndividualCat;
