import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Type } from "react-bootstrap-table2-editor";
// import IdentifiersModal from "./IdentifiersModal";
import { identifiers, updateProduct } from "./data";
import OutletProductPagination from "./OutletProductPagination";

function OutletTable(props) {
  const { products, catglist, perpage, page, total_items } = props.data;

  const [data, setData] = useState(products);

  const handlePageChange = useCallback(
    (page, sizePerPage) => {
      console.log(page, sizePerPage, props.filters, "FILTERS");
      props.setFilters({
        ...props.filters,
        pageNumber: parseInt(page),
        pagesize: parseInt(sizePerPage),
      });
    },
    [props.filters, props.setFilters, page]
  );

  console.log(props.filters.pagesize, "PAGESIZE");

  const options = useMemo(
    () => ({
      paginationSize: 5,
      pageStartIndex: 1,
      sizePerPage: props.filters.pagesize,
      hideSizePerPage: true,
      totalSize: total_items,
      onPageChange: handlePageChange,
    }),
    [handlePageChange, perpage, total_items, props.filters.pagesize]
  );

  useEffect(() => {
    setData(props?.data?.products);
  }, [props.data]);

  function getCategoryNameById(categoryId) {
    console.log({ categoryId, catglist });
    // Find the category with the matching categoryId
    const category = catglist.find(
      (cat) => cat?.zomato_category_id === categoryId
    );
    // Check if category is undefined or null
    if (!category) {
      return "";
    }
    // Return the category name if it exists, or an empty string if it doesn't
    return category.category_list?.category_name || "";
  }

  const columns = [
    {
      dataField: "product_display_name",
      text: "Product Name",
      editable: true,
      headerStyle: { width: "200px" },
      sort: true,
      style: { width: "200px" },
      validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: "Name is required",
          };
        }
        return true;
      },
    },
    {
      dataField: "zomato_category.zomato_category_id",
      text: "Category",
      headerStyle: { width: "200px" },
      style: { width: "200px" },
      editor: {
        type: Type.SELECT,
        options: catglist.map((catg) => ({
          value: catg.zomato_category_id,
          label: catg.category_list?.category_name,
        })),
      },
      formatter: (cell, row) => getCategoryNameById(cell),
    },
    // {
    //   dataField: "product_list.food_type",
    //   text: "Food Type",
    //   headerStyle: { width: "100px" },
    //   style: { width: "100px" },
    //   editable: false,
    // },
    {
      dataField: "product_list.price",
      text: "Price",
      headerStyle: { width: "100px" },
      style: { width: "100px" },
      editable: false,
    },
    // {
    //   dataField: "no_of_order",
    //   text: "Number of Orders",
    //   headerStyle: { width: "200px" },
    //   style: { width: "200px" },
    //   editable: false,
    // },
    {
      dataField: "additional_price",
      text: "Additional Price",
      headerStyle: { width: "200px" },
      style: { width: "200px" },
      editable: true,
    },
    {
      dataField: "desktop_type",
      text: "Desktop type",
      headerStyle: { width: "100px" },
      style: { width: "100px" },
      editable: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Form.Check
            type="checkbox"
            id={`delivery-${rowIndex}`}
            label="Delivery"
            checked={true} // always checked
            disabled // disable the checkbox
            onChange={() =>
              formatExtraData.handleCheckboxChange(rowIndex, "delivery")
            }
          />
        );
      },
      editor: {
        type: Type.CHECKBOX,
        value: "delivery",
        options: [
          {
            value: "delivery",
            label: "Delivery",
          },
          {
            value: "takeaway",
            label: "Takeaway",
          },
        ],
      },
      formatExtraData: {
        handleCheckboxChange: (rowIndex, fieldName) => {
          // do nothing since the checkbox is always checked and disabled
        },
      },
    },

    {
      dataField: "tags",
      text: "Goods and Services",
      editable: false,
      formatter: (cell, row) => {
        console.log({ cell });
        const isChecked = cell.includes("services");
        return (
          <div>
            <input
              type="checkbox"
              checked={isChecked}
              disabled
              style={{
                appearance: "none",
                borderRadius: "50%",
                width: "12px",
                height: "12px",
                marginRight: "6px",
              }}
            />
            <span style={{ lineHeight: "1.5" }}>Services</span>
          </div>
        );
      },
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "tags",
      text: "Choice",
      headerStyle: { width: "200px" },
      style: { width: "200px" },
      editable: true,
      editor: {
        type: Type.SELECT,
        options: identifiers.map((identifier) => ({
          value: identifier,
          label: identifier,
        })),
      },
      formatter: (cell, row) => {
        const selected = cell.split(",")[0].trim();
        console.log({ selected });
        return (
          <div>
            <span>{selected}</span>
          </div>
        );
      },
    },

    {
      dataField: "stock",
      text: "Stock",
      headerStyle: { width: "100px" },
      style: { width: "100px" },
      editable: true,
      editor: {
        type: Type.SELECT,
        options: [
          { value: true, label: "True" },
          { value: false, label: "False" },
        ],
      },
      formatter: (cell) => (cell ? "True" : "False"),
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",
      csvExport: false,
      formatter: rankFormatter,
      editable: false,
      headerStyle: { width: "200px" },
      style: { width: "200px" },
    },
  ];

  const handleSaveCell = (oldValue, newValue, row, column) => {
    console.log({ oldValue, newValue, row, column });
    // check if the edited column is the 'Category' column
    if (column.dataField === "zomato_category.zomato_category_id") {
      // find the category with the matching category_list_id and update the zomato_category_id field
      const category = catglist.find(
        (cat) => cat?.zomato_category_id === newValue
      );

      if (category) {
        row.zomato_category_id = category.zomato_category_id;
        // update the data source with the new row data

        setData([...data]);
      }
    } else {
      // for other columns, simply update the data source with the new row data
      setData([...data]);
    }
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        style={{
          textAlign: "start",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Button
          exact="true"
          to="/oulets/items"
          className="btn btn-sm btn-warning"
          style={{ marginLeft: "0.6rem" }}
          onClick={() => {
            updateProduct(row.zomato_product_id, { ...row });
          }}
        >
          Update
        </Button>
        <Button
          className="btn btn-sm btn-danger"
          style={{ marginLeft: "0.6rem" }}
          onClick={() => props.handleDeleteProducts(row.zomato_product_id)}
        >
          Delete
        </Button>
      </div>
    );
  }

  return (
    <Row>
      <Col className="col-12 mb-5">
        <Card>
          <Card.Body style={{ padding: "40px" }}>
            <Card.Title className="h4 mb-2 ">Products</Card.Title>

            {data.length > 0 ? (
              <Row>
                <Col xl="12">
                  <div className="table-responsive overflow-auto">
                    {data.map((item, index) => (
                      <BootstrapTable
                        key={index}
                        keyField="zomato_product_id"
                        data={item}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        condensed
                        responsive
                        classes={"table align-middle table-nowrap"}
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          autoSelectText: true,
                          afterSaveCell: handleSaveCell,
                        })}
                        // pagination={paginationFactory(options)}
                        noDataIndication={() => (
                          <div className="text-center">No data found</div>
                        )}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="text-center">No data found</div>
            )}

            <Row>
              <OutletProductPagination
                page={page}
                filters={props.filters}
                setFilters={props.setFilters}
                pageNum={Math.ceil(!!data ? total_items / perpage : 1)}
              />
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default OutletTable;
