import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllBranchId } from "../../../store/actionCreators/Employees/EmployeeAction";
import Select from "react-select";
import CitySelector from "./CitySelector";
import PincodeSelector from "./PincodeSelector";
import { useLocation } from "react-router-dom";

const EditOndcStore = (props) => {
  const location = useLocation();
  const row = location?.state?.row;
  const dispatch = useDispatch();
  const [franchiseOptions, setFranchiseOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedPincode, setSelectedPincode] = useState(null);

  const AllBranchList = useSelector((state) => state.branches);

  const [formData, setFormData] = useState({
    access_key: row?.access_key || "",
    branches: row?.branches || [],
    franchise_id: row?.franchise_id[0] || "",
    name: row?.name || "",
    city: row?.city || "",
    region: row?.region || "",
    longitude: row?.longitude || "",
    latitude: row?.latitude || "",
    address: row?.address || "",
    contact: row?.contact || "",
    email: row?.email || "",
    timing: row?.timing || "",
    location_availability_mode: "",
    location_availability_array: [],
  });

  console.log({ formData, location });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/get_franchise`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Success") {
          setFranchiseOptions(
            data.data.map((franchise) => ({
              value: franchise.franchise_id,
              label: franchise.franchise_name,
            }))
          );
          // Get all branches from the franchises
          const branches = data.data.reduce((acc, franchise) => {
            acc.push(...franchise.branches);
            return acc;
          }, []);
          setBranchOptions(
            (branches || []).map((branch) => ({
              value: branch.branch_id,
              label: branch.branch_name,
            }))
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching franchise data:", error);
      });
  }, []);

  useEffect(() => {
    // Update the form data with the initial values from useLocation
    setFormData((prevState) => ({
      ...prevState,
      ...row,
    }));
  }, [row]);

  useEffect(() => {
    dispatch(getAllBranchId());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const selectedBranchesString = selectedOptions
      .map((option) => option.value)
      .join(",").split(",");

    setFormData((prevFormData) => ({
      ...prevFormData,
      branches: selectedBranchesString,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const selectedCitiesString = (selectedCities || [])
      .map((city) => city.value)
      .join(",");

    const selectedPincodesString = (selectedPincode || [])
      .map((pin) => pin.label)
      .join(",");

 

    const franchiseId = Array.isArray(formData.franchise_id)
      ? [formData.franchise_id[0].toString()]
      : [formData.franchise_id.toString()];

    const locationArray = selectedCitiesString
      ? selectedCitiesString.split(",")
      : selectedPincodesString
      ? selectedPincodesString.split(",")
      : [];

    const updatedFormData = {
      ...formData,
      location_availability_array: locationArray, // Use map to extract the value properties
      
      franchise_id: franchiseId,
    };

    // Remove the keys from the updatedFormData object
    delete updatedFormData.menu_branch_id;
    delete updatedFormData.ondc_store_id;
    delete updatedFormData.store_name;
    delete updatedFormData.sync;
    delete updatedFormData.store_url;
    delete updatedFormData.mystore_seller_id;
    delete updatedFormData.createdAt;
    delete updatedFormData.active;
    delete updatedFormData.updatedAt;

    console.log({ updatedFormData });

    const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/edit_ondc_store/${row.ondc_store_id}`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedFormData),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "success") {
          toast.success(`Congratulations! Store updated successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${data?.msg}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    if (row.location_availability_mode === "zip_codes") {
      const pincodeOptions = row.location_availability_array.map((pin) => ({
        label: pin,
        value: pin,
      }));
      setSelectedPincode(pincodeOptions);
    } else if (row.location_availability_mode === "cities") {
      const cityOptions = row.location_availability_array.map((city) => ({
        label: city,
        value: city,
      }));
      setSelectedCities(cityOptions);
    }
  }, []);

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="row d-flex justify-content-center">
        <div className="form-container">
          <div className="form-head">Add New Store</div>
          <div className="form-body">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Access Key</label>
                    <input
                      type="text"
                      className="form-control"
                      name="access_key"
                      value={formData.access_key}
                      onChange={handleChange}
                      required
                      disabled
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Store Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="store_name"
                      value={formData.store_name}
                      onChange={handleChange}
                      required
                      disabled
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Menu Branch ID</label>
                    <select
                      className="form-select"
                      name="menu_branch_id"
                      value={formData.menu_branch_id}
                      onChange={handleChange}
                      required
                      disabled
                    >
                      <option value="">Select Menu Branch ID</option>
                      {(AllBranchList?.data?.data || []).map((branch) => (
                        <option key={branch.branch_id} value={branch.branch_id}>
                          {branch.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Franchise</label>
                    <select
                      className="form-select"
                      name="franchise_id"
                      value={formData.franchise_id}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Franchise</option>
                      {franchiseOptions.map((franchise) => (
                        <option key={franchise.value} value={franchise.value}>
                          {franchise.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Branches</label>
                    <Select
                      name="branches"
                      value={branchOptions.filter((option) =>
                        formData.branches.includes(option.value)
                      )}
                      onChange={handleMultiSelectChange}
                      options={branchOptions}
                      isMulti // Enable multiple select
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Region</label>
                    <input
                      type="text"
                      className="form-control"
                      name="region"
                      value={formData.region}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Longitude</label>
                    <input
                      type="text"
                      className="form-control"
                      name="longitude"
                      value={formData.longitude}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Latitude</label>
                    <input
                      type="text"
                      className="form-control"
                      name="latitude"
                      value={formData.latitude}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Timing</label>
                    <input
                      type="text"
                      className="form-control"
                      name="timing"
                      value={formData.timing}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">
                      Location Availability Mode
                    </label>
                    <select
                      className="form-select"
                      name="location_availability_mode"
                      value={formData.location_availability_mode}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        Select Location Availability Mode
                      </option>
                      <option value="cities">Cities</option>
                      <option value="zip_codes">Zip Code</option>
                    </select>
                  </div>
                </Col>
              </Row>

              {formData.location_availability_mode === "cities" && (
                <Row>
                  <Col>
                    <div className="mb-3 p-2">
                      <label className="form-label">
                        Location Availability
                      </label>

                      <CitySelector
                        setSelectedCities={setSelectedCities}
                        selectedCities={selectedCities}
                        locations={row?.location_availability_array}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {formData.location_availability_mode === "zip_codes" && (
                <Row>
                  <Col>
                    <div className="mb-3 p-2">
                      <label className="form-label">
                        Location Availability
                      </label>
                      <PincodeSelector
                        selectedPincode={selectedPincode}
                        setSelectedPincode={setSelectedPincode}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              <button className="btn btn-primary mx-2" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EditOndcStore;
