import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProductList } from "../../../store/actionCreators/Coupons/CouponsAction";

const EditAddonModal = ({
  show,
  close,
  data,
  setAddonsArr,
  productFullData,
  setProductFullData,
}) => {
  const addonsList = useSelector((state) => state.get_single_product);
  const dispatch = useDispatch();

  const [addonChange, setAddonChange] = useState([]);

  useEffect(() => {
    if (addonsList?.data?.data?.per_product_add_ons) {
      setAddonChange(addonsList?.data?.data?.per_product_add_ons);
    }
  }, [addonsList]);

  // console.log(productFullData, "fulldata");

  useEffect(() => {
    if (data?.product_list_id) {
      dispatch(getSingleProductList(data?.product_list_id));
    }
  }, [data]);

  const closeModal = () => {
    setProductFullData((list) => {
      const updatedList = [...list];
      const updatedItem = {
        ...updatedList[data.index],
        addonData: addonChange,
      };

      updatedList[data.index] = updatedItem;

      // console.log("SMARTSHIVA 222", { 'data.index':data.index,list, updatedList, addonChange, updatedItem });
      return updatedList;
    });

    close();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Addons Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            <div className="mb-2">
              <label>Addon Options</label>
            </div>
            <div allowZeroExpanded>
              {addonChange.map((add_on, i) => {
                // console.log(add_on.add_on.add_on_type);
                return (
                  <div className="mb-2" key={i}>
                    <div>
                      <div className="flex justify-between w-full px-4 py-2 text-sm text-left text-primaryColor bg-primaryLightColor25 rounded-[10px] hover:bg-primaryLightColor30 focus:outline-none uppercase font-bold">
                        {add_on.add_on.title}
                      </div>
                    </div>

                    {/* checkbox map */}
                    <div className="p-2">
                      <ul className="flex flex-wrap gap-2">
                        {add_on.add_on.add_on_options.map(
                          (add_on_option, j) => {
                            console.log(setAddonsArr(add_on_option), "radio");
                            return (
                              <li className="" key={j}>
                                <label
                                  className="flex p-1 text-gray-500 bg-white  border-2 border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-transparent"
                                  htmlFor={add_on_option.price}
                                >
                                  {add_on_option.title}
                                </label>
                                <input
                                  className="sr-only peer"
                                  type="number"
                                  value={add_on_option?.price}
                                  name={add_on_option?.price}
                                  onChange={(e) => {
                                    addonChange[i].add_on.add_on_options[
                                      j
                                    ].price = e.target.value;
                                    setAddonChange([...addonChange]);
                                  }}
                                  id={add_on_option.price}
                                />
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>

            <Button
              className="btn btn-warning"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              Save the Change
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditAddonModal;
