import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/LoaderTable";
import OndcProductTable from "./OndcProductTable";

const ViewOndcStore = (props) => {
  const params = useParams();
  const ondcStoreId = params.ondc_store_id;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useState({
    ondc_store_id: "",
  });

  const fetchData = () => {
    setLoading(true)
    const url = new URL(
      `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/single_ondc_store/${pageNumber}/${ondcStoreId}?ondc_store_category_id=${filters.ondc_store_id}`
    );

    // Check if any filter values are empty or "All" before appending to URL
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== "" && filters[key] !== "All") {
        url.searchParams.append(key, filters[key]);
      }
    });

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      }).finally(() => setLoading(false));;
  };

  useEffect(() => {
    fetchData();
  }, [ondcStoreId, filters,pageNumber]);

  const handleFiltersChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const store = data?.data || {};

  const categoryOptions = (store?.ondc_store_categories || []).map((catg) => ({
    value: catg.ondc_store_category_id,
    label: catg?.ondc_catg_name,
  }));

 

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Card>
        <Card.Body>
          {" "}
          <div className="d-flex justify-content-between mb-3">
            <h4>Store Details</h4>
          </div>
          {data && (
            <Table bordered responsive>
              <tbody>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>{store.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>City</strong>
                  </td>
                  <td>{store.city}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>Filters</Card.Header>
        <Card.Body>
          <Form>
            <Row className="align-items-center">
              <Col xs={12} sm={4} md={3} lg={2}>
                <Form.Group controlId="formZomatoCategoryId">
                  <Form.Label>ONDC category </Form.Label>
                  <Form.Select
                    name="ondc_store_id"
                    value={filters.ondc_store_id}
                    onChange={handleFiltersChange}
                  >
                    <option value="">All</option>
                    {categoryOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* <Col xs={12} sm={2}>
                <Button variant="primary m-2" type="submit">
                  Apply
                </Button>
              </Col> */}
            </Row>
          </Form>

          {/* <SyncButton ondc_store_id={store?.ondc_store_id} /> */}
        </Card.Body>
        <div>
          {data && !loading ? (
            <OndcProductTable
              data={data}
              filters={filters}
              setFilters={setFilters}
              page={pageNumber}
              setPageNumber={setPageNumber}
            />
          ) : (
            <p>
              <div className="m-5">
                <Loader />
              </div>
            </p>
          )}

          {!loading && !data ? "No Data Found" : null}
        </div>
      </Card>
    </Container>
  );
};

export default ViewOndcStore;
