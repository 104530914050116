import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddInvestors = (props) => {
  const navigate=useNavigate();
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="row d-flex justify-content-center">
        <div className="form-container">
          <div className="form-head">Add New Investor</div>
          <div className="form-body">
            <form onSubmit={props.nextStep}>
              <Row>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="NAME"
                      value={props.newInvestor.name}
                      onChange={props.handleChange("name")}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="GST"
                      value={props.newInvestor.gst_no}
                      onChange={props.handleChange("gst_no")}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="Contact"
                      value={props.newInvestor.contact}
                      onChange={props.handleChange("contact")}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      aria-describedby="Email"
                      value={props.newInvestor.email}
                      onChange={props.handleChange("email")}
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="Address"
                      value={props.newInvestor.address}
                      onChange={props.handleChange("address")}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Bank</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="Bank"
                      required
                      value={props.newInvestor.bank}
                      onChange={props.handleChange("bank")}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">IFSC CODE</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="IFSC"
                      value={props.newInvestor.ifsc}
                      onChange={props.handleChange("ifsc")}
                      required
                    />
                  </div>
                </Col>

                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Account Number</label>
                    <input
                      type="number"
                      className="form-control"
                      aria-describedby="account-number"
                      required
                      value={props.newInvestor.account_no}
                      onChange={props.handleChange("account_no")}
                    />
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="mb-2 p-2">
                    {/* <label className="form-label">Select Branches(S)</label> */}

                    <div className="mb-3 p-2">{props.displayBranch()}</div>
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Send Mails Daily</label>
                    <div className="d-flex">
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          value={true}
                          onChange={props.handleChange("mail_daily")}
                          id="flexRadioDefault1"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          value={false}
                          onChange={props.handleChange("mail_daily")}
                          id="flexRadioDefault2"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Send Mails Monthly</label>
                    <div className="d-flex">
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault1"
                          value={true}
                          onChange={props.handleChange("mail_monthly")}
                          id="flexRadioDefault3"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault1"
                          value={false}
                          onChange={props.handleChange("mail_monthly")}
                          id="flexRadioDefault4"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="p-2 d-flex gap-2">
              <button onClick={()=>{navigate("/investor")}} className="btn btn-primary  ">
                  Back
                </button>
                <button type="submit" className="btn btn-primary  ">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddInvestors;
