import React from "react";
import MonthPicker from "react-month-picker";
import "react-month-picker/scss/month-picker.scss";

export default function App({ setMonth, value1 }) {
  const [value, setValue] = React.useState({
    year: value1?.year || new Date().getFullYear(),
    month: value1?.month || new Date().getMonth() + 1,
  });

  const monthPickerRef = React.useRef(null);

  const showPicker = () => {
    if (monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };

  const hidePicker = () => {
    if (monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  const handlePickerChange = (year, month) => {
    setValue({ year, month });
    setMonth({ year, month });
    hidePicker();
  };

  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    from: "From",
    to: "To",
  };

  const dic = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  return (
    <MonthPicker
      lang={lang.months}
      ref={monthPickerRef}
      value={value}
      onChange={handlePickerChange}
    >
      <span onClick={showPicker}>
        <input type="text" value={`${dic[value.month]}, ${value.year}`} readOnly />
      </span>
    </MonthPicker>
  );
}
