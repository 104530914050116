import axios from "axios";
// REPORT SECTION LOGOUT REPORT SINGLE EMPLOYEE

export const getSingleEmployeeLogoutReport = (query) => {
  console.log("");

  return (dispatch, getState) => {
    dispatch({
      type: "GET_SINGLE_EMPLOYEE_REPORT",
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_single_employee_logout_report_v2`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_SINGLE_EMPLOYEE_REPORT",
          loading: false,
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// CUSTOM REPORT
export const getCustomLogoutReport = (query) => {
  console.log("");
  return (dispatch, getState) => {
    dispatch({
      type: "GET_SINGLE_EMPLOYEE_REPORT",
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_custom_single_employee_logout_report_v2`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_SINGLE_EMPLOYEE_REPORT",
          loading: false,
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
