import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../Loader/LoaderTable";

const AllOndcProducts = (props) => {
  const allProductList = useSelector((state) => state.allOndcProductList);
  const page = useSelector((state) => state.promo_code_pagination);
  const [displayableProducts, setDisplayableProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([
    { field: "publish", value: "1", operator: "equal" },
  ]);

  useEffect(() => {
    setLoading(true);
    // Simulating an API call to fetch data
    setTimeout(() => {
      const dummyData = [
        { id: 1, Name: "Product 1", createdAt: "2023-06-18" },
        { id: 2, Name: "Product 2", createdAt: "2023-06-17" },
        { id: 3, Name: "Product 3", createdAt: "2023-06-16" },
      ];
      setDisplayableProducts(dummyData);
      setLoading(false);
    }, 2000);
  }, [filters]); // Add filters as a dependency

  useEffect(() => {
    setLoading(true);
    // Simulating an API call to fetch data based on the page
    setTimeout(() => {
      const dummyData = [
        { id: 4, Name: "Product 4", createdAt: "2023-06-15" },
        { id: 5, Name: "Product 5", createdAt: "2023-06-14" },
        { id: 6, Name: "Product 6", createdAt: "2023-06-13" },
      ];
      setDisplayableProducts(dummyData);
      setLoading(false);
    }, 2000);
  }, [page]);

  const setDefaultData = () => {
    setDisplayableProducts([]);
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";

    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Link
          exact="true"
          to={`editOndcProduct/${row.id}`}
          className="btn btn-sm btn-warning"
          style={{ marginLeft: "0.6rem" }}
        >
          Edit
        </Link>

        <button
          className="btn btn-sm btn-danger ms-2"
          onClick={() => console.log(row)}
        >
          Delete
        </button>
        <button
          className="btn btn-sm btn-info ms-2"
          onClick={() => console.log(row)}
        >
          Publish
        </button>
      </div>
    );
  }

  const columns = [
    {
      dataField: "Name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "Name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: displayableProducts.length,
    custom: true,
  };

  const getAllRows = () => {
    return displayableProducts;
  };

  if (loading) {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Loader />
      </Container>
    );
  }

  if (displayableProducts.length === 0) {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="page-content">
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <h4 className="mb-4">All ONDC Products</h4>
                  <p>No data found.</p>
                  <Link
                    className="btn btn-warning mb-3"
                    style={{ marginLeft: "1rem", marginRight: "1rem" }}
                    to={"/ondc/addOndcProduct"}
                  >
                    Add New Product
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="page-content">
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h4 className="mb-4">All ONDC Products</h4>
                    <br />
                    <div className="d-flex align-items-center">
                      <select
                        className="form-select me-2"
                        value={filters[0].value}
                        onChange={(e) =>
                          setFilters([{ ...filters[0], value: e.target.value }])
                        }
                      >
                        <option value="1">Published</option>
                        <option value="0">Not Published</option>
                      </select>
                      <select
                        className="form-select me-2"
                        value={filters[0].operator}
                        onChange={(e) =>
                          setFilters([
                            { ...filters[0], operator: e.target.value },
                          ])
                        }
                      >
                        {/* Add options for the operator */}
                        <option value="equal">Equal</option>
                        <option value="notEqual">Not Equal</option>
                        {/* Add more options based on your requirements */}
                      </select>

                      <input
                        type="text"
                        className="form-control me-2"
                        value={filters[0].value}
                        onChange={(e) =>
                          setFilters([{ ...filters[0], value: e.target.value }])
                        }
                      />
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => console.log("Apply Filters")}
                      >
                        Apply Filters
                      </button>
                    </div>
                  </div>
                  <Link
                    className="btn btn-warning mb-3"
                    style={{ marginLeft: "1rem", marginRight: "1rem" }}
                    to={"/ondc/addOndcProduct"}
                  >
                    Add New Product
                  </Link>
                </div>

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={getAllRows()}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={getAllRows()}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes="table align-middle table-nowrap"
                                  headerWrapperClasses="thead-light"
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          {/* <Row className="mt-3">
                                <PromoCodePagination
                                  pageNum={Math.ceil(
                                    allProductList.data
                                      ? allProductList.data.total_count
                                        ? allProductList.data.total_count /
                                          allProductList.data?.per_page
                                        : 1
                                      : 1
                                  )}
                                />
                              </Row> */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AllOndcProducts;
