import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  CalendarIcon,
  ClipboardListIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import Unauthorized from "../../unauthorized";
import AddonItemwise from "./AddonItemwise";
import HighestItemSell from "./HighestItemSell";
import EmployeeWiseItem from "./EmployeeWiseItem";
import ItemWiseInventoryReport from "./ItemWiseInventoryReport";

// here we replaced location report component to item report
// means use it as a item report component
const ItemReport = (props) => {
  const navigate = useNavigate();
  if (props.viewPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <Col>
            <Button className="my-2" onClick={() => navigate("/reports")}>
              Back to All Reports Section
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>ITEM REPORT</h5>
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <NavLink to="/reports/item/highestitemsellingsummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Highest Item selling summary</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
          {/* <Col>
            <NavLink to="/reports/item/addonitemwisesummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Addon Itemwise summary</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/item/employeewiseitemsummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Employee wise Item summary</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
          <Col>
            <NavLink to="/reports/item/itemwiseinventoryreport">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Itemwise Inventory Report</div>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Routes>
            <Route
              path="highestitemsellingsummary"
              element={<HighestItemSell sideToggle={props.sideToggle} />}
            />
            <Route
              path="addonitemwisesummary"
              element={<AddonItemwise sideToggle={props.sideToggle} />}
            />
            <Route
              path="employeewiseitemsummary"
              element={<EmployeeWiseItem sideToggle={props.sideToggle} />}
            />
            <Route
              path="itemwiseinventoryreport"
              element={
                <ItemWiseInventoryReport sideToggle={props.sideToggle} />
              }
            />
          </Routes>
        </Row>
      </Container>
    );
  else return <Unauthorized />;
};

export default ItemReport;
