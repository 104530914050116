import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { ClipboardListIcon } from "@heroicons/react/outline";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import Unauthorized from "../../unauthorized";
import ExportReportCustomerRoles from "./ExportReportCustomerRoles";

const ExportReport = (props) => {
  const navigate = useNavigate();
  if (props.viewPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <Col>
            <Button className="my-2" onClick={() => navigate("/reports")}>
              Back to All Reports Section
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>EXPORT CUSTOMERS</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/exports/exportcustomers">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Export Customers</div>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Routes>
            <Route
              path="exportcustomers"
              element={
                <ExportReportCustomerRoles sideToggle={props.sideToggle} />
              }
            />
          </Routes>
        </Row>
      </Container>
    );
  else return <Unauthorized />;
};

export default ExportReport;
