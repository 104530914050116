import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../Loader/LoaderTable";
import ScrollButton from "../scrolltotop/ScrollButton";
import { fetchAllUsedCredit } from "../../store/actionCreators/Credits/CreditActions";
import { useNavigate } from "react-router-dom";
import { getAllStoreList } from "../../store/actionCreators/Inventory/InventoryAction";
import StatusUpdateModal from "./StatusUpdateModal";
import { updateInventoryCredit } from "../../store/actionCreators/Credits/CreditActions";
import UsedCreditListPagination from "./AvailableCreditListPagination";


const UsedCredits = (props) => {
  const dispatch = useDispatch();
  const usedCredit = useSelector((state) => state.get_used_credit);
  const allStoreList = useSelector((state) => state.get_all_stores);
  const page = useSelector((state) => state.credit_used_pagination);
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [currStores, setCurrStores] = useState("All");
  const [currStoreName, setCurrStoreName] = useState("");

  const allStatus = [
    { label: "Available", value: "true" },
    { label: "Used", value: "false" },
  ];

  useEffect(() => {
    setProductData([]);
    dispatch(fetchAllUsedCredit(page, currStores));
  }, [page, currStores]);
  useEffect(() => {
    dispatch(getAllStoreList());
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [allStoreList, currStores]);

  useEffect(() => {
    if (usedCredit?.data?.status !== "success") {
      setisDataFetched(false);
    }
    if (usedCredit.data && usedCredit?.data?.status === "success") {
      setisDataFetched(true);

      const productDataClone = usedCredit?.data?.data.map((e) => {
        return {
          ...e,
          tax:
            Number.parseFloat(Math.round(e.tax * 100) / 100).toLocaleString() ||
            0,
          createdAt: new Date(`${e?.createdAt}`).toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          }),
        };
      });

      setProductData(productDataClone);
    } else if (usedCredit?.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [usedCredit, usedCredit?.data]);

  const setDefaultData = () => {
    if (allStoreList?.data && allStoreList.data?.data) {
      const branchArrClone = allStoreList.data?.data.map((b) => ({
        label: b.store_name,
        value: b.store_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
    }
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() => navigate(`edit/${row.id}`)}
            className="btn btn-sm btn-warning"
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-warning mx-2"
            onClick={() => {
              setShowModal(true);
              setRowData(row);
            }}
          >
            Update
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() => navigate(`edit/${row.id}`)}
            className="btn btn-sm btn-warning"
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-warning mx-2"
            onClick={() => {
              setShowModal(true);
              setRowData(row);
            }}
          >
            Update
          </button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "inventory_items_id",
      text: "Inventory Item ID",
      sort: true,
    },
    {
      dataField: "purchase_order_items_id",
      text: "Purchase Order Item ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "tax",
      text: "Total Tax",
      sort: true,
    },
    {
      dataField: "total_price",
      text: "Total Price",
      sort: true,
    },
    {
      dataField: "vendor_name",
      text: "Vendor Name",
      sort: true,
    },

    {
      dataField: "credit_status",
      text: "Credit Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const handleSelect = (e) => {
    const value = e.split(",")[0];
    const name = e.split(",")[1];
    setCurrStores(value);
    setCurrStoreName(name);
  };

  const pageOptions = {
    sizePerPage: productData.length,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const displayStores = () => {
    const additional = [{ store_name: "All", store_id: "All" }];
    return (additional.concat(allStoreList?.data?.data) || []).map(
      (item, index) => {
        return (
          <Dropdown.Item
            key={item?.store_id}
            eventKey={`${item?.store_id},${item?.store_name}`}
          >
            {" "}
            {item?.store_name}
          </Dropdown.Item>
        );
      }
    );
  };

  console.log(showModal, "ssss");

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">USED CREDITS</h5>
        </Col>
      </Row>

      <React.Fragment>
        <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}></Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={productData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={productData}
                      search
                      // bootstrap4
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    srText=""
                                    {...toolkitProps.searchProps}
                                    onChange={(e) => {
                                      console.log(e);
                                    }}
                                  />
                                  <i className="search-box chat-search-box" />
                                </div>
                              </div>

                              <DropdownButton
                                variant="light"
                                title={
                                  currStoreName ? currStoreName : "All Stores"
                                }
                                id="dropdown-menu-align-right"
                                onSelect={handleSelect}
                              >
                                {displayStores()}
                              </DropdownButton>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={true}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                                <StatusUpdateModal
                                  showModal={showModal}
                                  close={() => setShowModal(false)}
                                  data={rowData}
                                  updateInventoryCredit={updateInventoryCredit}
                                  setRowData={setRowData}
                                  currStores={currStores}
                                />

                                {!productData.length > 0 &&
                                usedCredit?.data?.status !== "failure" ? (
                                  <Loader />
                                ) : (
                                  ""
                                )}
                                {usedCredit?.data?.status === "failure" &&
                                  !usedCredit?.data?.data &&
                                  !!isDataFetched &&
                                  "No data found"}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <UsedCreditListPagination
                              pageNum={Math.ceil(
                                usedCredit?.data
                                  ? usedCredit?.data?.total_items /
                                      usedCredit.data?.per_page
                                  : 1
                              )}
                            />
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ScrollButton />
      </React.Fragment>
    </React.Fragment>
  );
};

export default UsedCredits;
