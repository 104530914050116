import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../../unauthorized";
import Async from "react-select/async";
import axios from "axios";
import { addNewGiftCard } from "../../../store/actionCreators/Coupons/CouponsAction";
import { useNavigate } from "react-router-dom";

const AddGiftCard = ({
  editPermission,
  branchArray,
  groupArray,
  empArray,
  empObj,
  branchObj,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate=useNavigate();
  const [data, setData] = useState({
    title: "",
    description: "",
    amount: "",
    created_by: "Heebee",
    status: { label: "False", value: false },
  });

  const loadData = async (customer) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_customers/1?search=${customer}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
            c.mobile_no
          })`,
          value: c.customer_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, description, amount, status, created_by } = data;
    let customer_id = "";
    customers.forEach((c) => {
      customer_id = customer_id + c.value + ",";
    });
    const dataClone = {
      title,
      description,
      amount: parseInt(amount),
      customer_id: customer_id.slice(0, customer_id.length - 1),
      created_by,
      status: status.value,
    };

    dispatch(addNewGiftCard(dataClone));
  };

  if (editPermission) {
    return (
      <div className="page-content ">
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="">
                  <Card.Title className="h4 mb-4 my-auto">
                    Add Gift Card
                  </Card.Title>
                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Title:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.title}
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Description:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.description}
                          onChange={(e) =>
                            setData({ ...data, description: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Created By:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.created_by}
                          onChange={(e) =>
                            setData({ ...data, created_by: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Customers:</label>
                        <div className="">
                          <Async
                            defaultOptions={[]}
                            cacheOptions={[{ label: "1234", value: "1234" }]}
                            isLoading={isLoading}
                            loadOptions={(e) => loadData(e)}
                            onChange={(e) => setCustomers(e)}
                            isMulti={true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Amount:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          value={data.amount}
                          onChange={(e) =>
                            setData({ ...data, amount: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <label>Status:</label>
                        <Select
                          value={data.status}
                          options={[
                            { label: "True", value: true },
                            { label: "False", value: false },
                          ]}
                          onChange={(e) => setData({ ...data, status: e })}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <Button
                          className="mx-2"
                          type="warning"
                          onClick={() => {
                            navigate('/coupons/allgiftcards');
                          }}
                        >
                          Back
                        </Button>
                        <Button type="submit" className="btn btn-warning">
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddGiftCard;
