import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../../unauthorized";
import Async from "react-select/async";
import axios from "axios";
import { addPromoCode } from "../../../store/actionCreators/Coupons/CouponsAction";
import { useNavigate } from "react-router-dom";

const AddPromoCode = ({
  editPermission,
  branchArray,
  groupArray,
  empArray,
  empObj,
  branchObj,
}) => {
  const dispatch = useDispatch();
  // const [step, setStep] = useState(1);
  const navigate=useNavigate();
  const [data, setData] = useState({
    coupon_code: "",
    title: "",
    description: "",
    min_amount: "",
    extra_amount: "",
    start_time: null,
    expiry_time: null,
    status: { label: "False", value: false },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(data.min_amount < 0, "ccc");

    const {
      coupon_code,
      title,
      description,
      min_amount,
      extra_amount,
      start_time,
      expiry_time,
      status,
    } = data;
    const dataClone = {
      coupon_code,
      title,
      description,
      min_amount: parseInt(min_amount),
      extra_amount: parseInt(extra_amount),
      start_time,
      expiry_time,
      status: status.value,
    };
    console.log(dataClone);
    dispatch(addPromoCode(dataClone));
  };

  if (editPermission) {
    return (
      <div className="page-content ">
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="">
                  <Card.Title className="h4 mb-4 my-auto">
                    Add Promo Code
                  </Card.Title>
                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Coupon Code:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.coupon_code}
                          onChange={(e) =>
                            setData({ ...data, coupon_code: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <label>Title:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.title}
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Description:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.description}
                          onChange={(e) =>
                            setData({ ...data, description: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Minimum Amount:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          placeholder="0"
                          value={data.min_amount}
                          onChange={(e) =>
                            setData({ ...data, min_amount: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Extra Amount:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          placeholder="0"
                          min="0"
                          value={data.extra_amount}
                          onChange={(e) =>
                            setData({ ...data, extra_amount: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Start Time:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={data.start_time}
                          onChange={(e) =>
                            setData({ ...data, start_time: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <label>Expiry Time:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={data.expiry_time}
                          onChange={(e) =>
                            setData({ ...data, expiry_time: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <label>Status:</label>
                        <Select
                          value={data.status}
                          options={[
                            { label: "True", value: true },
                            { label: "False", value: false },
                          ]}
                          onChange={(e) => setData({ ...data, status: e })}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                      <Button
                          className="mx-2"
                          type="warning"
                          onClick={() => {
                            navigate('/coupons/allpromocodes');
                          }}
                        >
                          Back
                        </Button>
                        <Button type="submit" className="btn btn-warning">
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddPromoCode;
