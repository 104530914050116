import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  addProductToAddon,
  getProductAddons,
} from "../../../store/actionCreators/Catalog/Catalog";

//add product branch , delete product branch

const AddMoreAddons = ({ show, close, data, product_list_id, closeParent }) => {
  console.log(data, "me parent ka data hu");

  const dispatch = useDispatch();
  const [addons, setAddons] = useState({});

  const allAddons = useSelector((state) => state.addons);

  const closeModal = () => {
    close();
  };

  console.log(allAddons, "me store se addons laya");
  console.log(addons, "me state se addons laya");

  const onSubmitForm = (e) => {
    e.preventDefault();
    const addonData = {
      ...data,
      add_ons_id: addons.value,
    };

    dispatch(addProductToAddon(addonData));
    // dispatch(getProductAddons(product_list_id));
    close();
    closeParent();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add addons to Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitForm}>
          <Row>
            <div className="mb-2">
              <label>Addons</label>
              <Select
                required
                options={allAddons.data.data.map((p) => ({
                  label: p.title,
                  value: p.add_ons_id,
                  price: p.price,
                }))}
                onChange={(e) => setAddons(e)}
                value={addons}
              />
            </div>
          </Row>
          <div>
            <Button className="btn btn-warning" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMoreAddons;
