import axios from "axios";
import { toast } from "react-toastify";

export const getAllInvestors = (page) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/fetch_all_investor/${page}`
      )
      .then((investors) => {
        dispatch({
          type: "GET_ALL_INVESTORS",
          investors,
        });
      });
  };
};
export const getAllCompany = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/fetch_company/`
      )
      .then((company) => {
        dispatch({
          type: "GET_ALL_COMPANY",
          company,
        });
      });
  };
};

export const deleteInvestor = (data) => {
  console.log(data);
  return (dispatch, getState) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/del_investor/${data}`
      )
      .then((res) => {
        console.log(res);
        if (res.data && res.data.status === "success") {
          toast.success(`Investor deleted successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "DEL_INVESTOR",
            data,
          });
        }

        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
};

export const updateInvestor = (data) => {
  console.log(data);
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/edit_investor/${data.investor_id}`,
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          toast.success(`Investor updated successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

// delete branch investor

export const deleteBranchInvestor = (id) => {
  console.log(id);
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/del_branch_investor`,
        {
          investors_branch_id: id,
        }
      )
      .then((res) => {
        console.log(res.data.msg);
        if (res.data.status === "success") {
          toast.success(`Deleted successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DELETE_BRANCH_INVESTOR",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 2500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

export const addBranchInvestor = (data) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/add_branch_investor`,
        {
          branch_id: data.branch_id,
          percentage_cut: data.percentage_cut,
          investor_id: data.investor_id,
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          toast.success(`Branch Investor Added successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "ADD_BRANCH_INVESTOR",
            payload: res.data.data,
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};
