import React from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../Loader/Loader";
import { getAllOutlets } from "../../../store/actionCreators/Zomato/ZomatoAction";
import axios from "axios";
import { toast } from "react-toastify";


const AllOutlets = (props) => {
  const dispatch = useDispatch();
  const OutletData = useSelector((state) => state.zomato_outlets);
  const outletsList = OutletData?.data?.outlet || [];
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllOutlets());
  }, []);

  useEffect(() => {
    if (!OutletData.data) {
      dispatch(getAllOutlets());
    }
  }, [dispatch, OutletData.data]);

  const deleteOutlet = (outletId) => () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/delete_outlet/${outletId}`
      )
      .then((response) => {
        toast.success("Deleted successfully");
        console.log(response);
        dispatch(getAllOutlets());
      })
      .catch((error) => {
        toast.error("failed to delete");
        console.log(error);
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    // if (!props.editPermission) {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
          
        }}
      >
        <Link
          exact="true"
          to={`/zomato/allOutlets/${row.zomato_outlets_id}`}
          className="btn btn-sm btn-warning"
        >
          View
        </Link>
        <Button
          className="btn btn-sm btn-warning"
          style={{ marginLeft: "0.6rem" }}
          onClick={() =>
            navigate("/zomato/allOutlets/editOutlet", {
              state: { outletData: row },
            })
          }
        >
          Edit
        </Button>
        <Button
          onClick={() => {
            deleteOutlet(row.zomato_outlets_id)();
          }}
          className="btn btn-sm btn-danger"
          style={{ marginLeft: "0.6rem" }}
        >
          Delete
        </Button>
      </div>
    );
    // } else {
    //   return (
    //     <Link
    //       exact="true"
    //       to={`/branch/Franchise/${row.franchise_id}`}
    //       // onClick={() => onClickFunction(row)}
    //       className="btn btn-sm btn-warning"
    //     >
    //       View
    //     </Link>
    //   );
    // }
  }

  const columns = [
    {
      dataField: "name",
      text: "Outlet Name",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: false,
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
    },
    // {
    //   dataField: "address",
    //   text: "Address",
    //   sort: true,
    // },
    // {
    //   dataField: "latitude",
    //   text: "Latitude",
    //   sort: true,
    // },
    // {
    //   dataField: "longitude",
    //   text: "Longitude",
    //   sort: true,
    // },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    //   headerStyle: { width: "200px" },
    //   style: { width: "200px" },
    // },
    // {
    //   dataField: "timing",
    //   text: "Timing",
    //   sort: true,
    // },
    // {
    //   dataField: "sync",
    //   text: "Sync",
    //   sort: true,
    // },
    // {
    //   dataField: "createdAt",
    //   text: "Created At",
    //   sort: true,
    //   dataAlign: "center",
    //   formatter: (cell, row) => moment(cell).format("YYYY-MM-DD HH:mm:ss"),
    //   headerStyle: { width: "200px" },
    //   style: { width: "200px" },
    // },
    {
      dataField: "view",
      text: "Actions",
      headerAlign: "center",
      isDummyField: true,
      csvExport: false,
      dataAlign: "center",
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "outlet_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: outletsList.length,
    custom: true,
  };

  const { SearchBar } = Search;

  if (OutletData.data) {
    if (outletsList && outletsList.length > 0) {
      return (

        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Row>
              <div className="page-content ">
                <form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <Card.Body>
                          <Card.Title className="h4 mb-2">
                            All Outlets
                          </Card.Title>

                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="outlets"
                            columns={columns}
                            data={outletsList}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="outlet_id"
                                columns={columns}
                                data={outletsList}
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />
                                            <i className="search-box chat-search-box" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive overflow-scroll">
                                          <BootstrapTable
                                            keyField="outlet_id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            condensed
                                            wrapperClasses="table-responsive"
                                            classes="table align-middle table-nowrap"
                                            headerClasses="thead-light"
                                            bodyClasses="table-body"
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                          
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                            className="table-pagination"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </form>
              </div>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Row>
              <div className="page-content">
                <strong>No data found.</strong>
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>Please Add Outlet by clicking on </p>
                  <button
                    className="btn btn-warning btn-sm mx-2 mb-3"
                    onClick={() => navigate("/zomato/addOutlets")}
                    style={{ height: "30px" }}
                  >
                    Add New
                  </button>
                </div>
              </div>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  } else {
    return <Loader />;
  }
};

export default AllOutlets;
