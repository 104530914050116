import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import OutletTable from "./OutletTable";
import { identifiers } from "./data";
import Loader from "../../../Loader/LoaderTable";
import axios from "axios";
import { toast } from "react-toastify";
import SyncButton from "./SyncButton";
import AddProductModal from "./AddProductModal ";

const ViewOutlets = (props) => {
  const params = useParams();
  const zomatoOutletId = params.zomato_outlets_id;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    zomato_category_id: "All",
    food_type: "All",
    desktop_type: "delivery",
    product_name: "",
    pageNumber: 1,
    pagesize: 20,
  });

  const fetchData = () => {
    const url = new URL(
      `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/get_single_outlet/${zomatoOutletId}`
    );

    // Check if any filter values are empty or "All" before appending to URL
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== "" && filters[key] !== "All") {
        url.searchParams.append(key, filters[key]);
      }
    });

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [zomatoOutletId, filters]);

  const handleFiltersChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const outlet = data?.outlet || {};

  const categoryOptions = (data?.catglist || []).map((catg) => ({
    value: catg.zomato_category_id,
    label: catg.category_list?.category_name,
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const url = new URL(
      `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/get_single_outlet/${zomatoOutletId}`
    );
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== "" && filters[key] !== "All") {
        url.searchParams.append(key, filters[key]);
      }
    });

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleDeleteProduct = async (zomato_product_id) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/delete_product`;

    try {
      const response = await axios.post(url, {
        zomato_product_id: zomato_product_id,
      });

      if (response.status === 200) {
        toast.success("Product deleted successfully");
        fetchData();
      }
    } catch (error) {
      toast.error("Error deleting product");
    }
  };

  useEffect(() => {
    setFilters({
      ...filters,
      pageNumber: 1,
    });
  }, [filters.pagesize]);

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Card>
        <Card.Body>
          {" "}
          <div className="d-flex justify-content-between mb-3">
            <h4>Outlet Details</h4>
          </div>
          {data && (
            <Table bordered responsive>
              <tbody>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>{outlet.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>City</strong>
                  </td>
                  <td>{outlet.city}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Card className="m-3">
        <Card.Header>Filters</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col xs={12} sm={4} md={3} lg={2}>
                <Form.Group controlId="formZomatoCategoryId">
                  <Form.Label>Zomato category ID</Form.Label>
                  <Form.Select
                    name="zomato_category_id"
                    value={filters.zomato_category_id}
                    onChange={handleFiltersChange}
                  >
                    <option value="">All</option>
                    {categoryOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={3} lg={2}>
                <Form.Group controlId="formFoodType">
                  <Form.Label>Food type</Form.Label>
                  <Form.Select
                    name="food_type"
                    value={filters.food_type}
                    onChange={handleFiltersChange}
                  >
                    <option value="">All</option>
                    {identifiers.map((identifier) => (
                      <option key={identifier} value={identifier}>
                        {" "}
                        {identifier}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* <Col xs={12} sm={4} md={3} lg={2}>
                <Form.Group controlId="formDesktopType">
                  <Form.Label>Desktop type</Form.Label>
                  <Form.Select
                    name="desktop_type"
                    value={filters.desktop_type}
                    onChange={handleFiltersChange}
                  >
                    <option value="">All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Group>
              </Col> */}
              <Col xs={12} sm={4} md={3} lg={2}>
                <Form.Group controlId="formProductName">
                  <Form.Label>Product name</Form.Label>
                  <Form.Control
                    type="text"
                    name="product_name"
                    value={filters.product_name}
                    onChange={handleFiltersChange}
                  />
                </Form.Group>
              </Col>
              {/* <Col xs={12} sm={3} md={2}>
                <Form.Group controlId="formPageNumber">
                  <Form.Label>Page number</Form.Label>
                  <Form.Control
                    type="number"
                    name="pageNumber"
                    value={filters.pageNumber}
                    onChange={handleFiltersChange}
                  />
                </Form.Group>
              </Col> */}
              <Col xs={12} sm={3} md={2}>
                <Form.Group controlId="formPageSize">
                  <Form.Label>Page size</Form.Label>
                  <Form.Select
                    name="pagesize"
                    value={filters.pagesize}
                    onChange={handleFiltersChange}
                  >
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} sm={2}>
                <Button variant="primary m-2" type="submit">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>

          <SyncButton zomato_outlets_id={outlet?.zomato_outlets_id} />
          <Button onClick={() => setShowModal(true)}>Add Product</Button>

          <AddProductModal
            showModal={showModal}
            closeModal={() => setShowModal(false)}
            fetchData={fetchData}
          />
        </Card.Body>
        <div>
          {data && !loading ? (
            <OutletTable
              data={data}
              filters={filters}
              setFilters={setFilters}
              handleDeleteProducts={handleDeleteProduct}
            />
          ) : (
            <p>
              <div className="m-5">
                <Loader />
              </div>
            </p>
          )}

          {!loading && !data ? "No Data Found" : null}
        </div>
      </Card>
    </Container>
  );
};
export default ViewOutlets;
