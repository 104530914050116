import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import {
  getAllItemsList,
  setItemsListPagination,
  setSearchTerm,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import { toast } from "react-toastify";
import AllItemsListPagination from "./AllItemsListPagination";
import Unauthorized from "../../unauthorized";
import { FaTimes } from "react-icons/fa";

const AllItems = (props) => {
  const dispatch = useDispatch();
  const allItemsList = useSelector((state) => state.get_all_items_list);
  const page = useSelector((state) => state.all_items_list_pagination);
  const { searchTerm } = useSelector((state) => state.search);

  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);

  const [currItemStatus, setCurrItemStatus] = useState("All");
  const [itemStatus, setItemStatus] = useState("");

  useEffect(() => {
    setLoading(true);
    dispatch(getAllItemsList(page, currItemStatus, searchTerm));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllItemsList(page, currItemStatus, searchTerm));
  }, [page, currItemStatus, searchTerm]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allItemsList]);

  const setDefaultData = () => {
    let itemsList = [];
    if (allItemsList.data && allItemsList.data.data) {
      itemsList = allItemsList.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
      }));
    }
    setItemsList(itemsList);
  };

  const deleteFunction = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/del_item_list?inventory_items_id=${id}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setSearchTerm(""));

          toast.success(`deleted successFully `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getAllItemsList(1, "All", ""));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewitems/${row.inventory_items_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`edititems/${row.inventory_items_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={(e) =>{
              e.stopPropagation()
               deleteFunction(row.inventory_items_id)}}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewitems/${row.inventory_items_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: true,
    },
    {
      dataField: "danger_zone",
      text: "Danger Zone",
      sort: true,
    },
    {
      dataField: "save_zone",
      text: "Safe Zone",
      sort: true,
    },
    {
      dataField: "cgst",
      text: "Tax",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allItemsList.data?.per_page,
    totalSize: allItemsList.data
      ? allItemsList.data.total_items
        ? allItemsList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const allItemsList = [...itemsList];
    return allItemsList;
  };

  const displayStatus = () => {
    return [
      { label: "All", value: "All" },
      { label: "Active", value: "true" },
      { label: "Not Active", value: "false" },
    ].map((item, index) => {
      return (
        <Dropdown.Item key={index} eventKey={`${item?.label},${item?.value}`}>
          {" "}
          {item?.label}
        </Dropdown.Item>
      );
    });
  };

  //   const handleSelect = (e) => {
  //     const value = e.split(",")[0];
  //     const name = e.split(",")[1];
  //     // setCurrStores(value);
  //     setCurrStoreName(name);
  //   };

  const handleSelectStatus = (e) => {
    const value = e.split(",")[1];
    const name = e.split(",")[0];
    setItemStatus(name);
    setCurrItemStatus(value);
  };

  // const { SearchBar } = Search;

  const MySearch = (props) => {
    let input;
    const handleClick = (e) => {
      e.preventDefault();
      dispatch(setItemsListPagination(1));

      props.onSearch(input.value);
      dispatch(setSearchTerm(input.value));

      setDefaultData();
    };
    return (
      <>
        <form onSubmit={handleClick} className="d-flex gap-2">
          <input
            className="form-control"
            style={{ backgroundColor: "none" }}
            ref={(n) => (input = n)}
            type="text"
          />
          <button className="btn btn-warning">Search</button>
        </form>
        {searchTerm !== "" && (
          <div className="search-result d-flex mt-2">
            <p className="mx-2">
              Search Result for : <strong>{searchTerm}</strong>
            </p>
            <button
              style={{
                backgroundColor: "transparent",
                border: "1px solid #ccc",
                borderRadius: "50%",
                padding: "3px",
                height: "fit-content",
                fontSize: "12px",
                cursor: "pointer",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
              onClick={() => {
                dispatch(setSearchTerm(""));
                dispatch(setItemsListPagination(1));
              }}
            >
              <FaTimes />
            </button>
          </div>
        )}
      </>
    );
  };

  if (props.viewPermission) {
    if (!loading) {
      return (
        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
              Inventory Items List{" "}
            </Col>
            <Col lg={4} sm={8} xs={12}></Col>
            <div className="page-content ">
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between mb-2">
                        <div
                          className="d-flex justify-content-between "
                          style={{ gap: "12px" }}
                        >
                          <Card.Title>All Items</Card.Title>

                          <DropdownButton
                            variant="light"
                            title={itemStatus ? itemStatus : "Items Status"}
                            id="dropdown-menu-align-right"
                            onSelect={handleSelectStatus}
                          >
                            {displayStatus()}
                          </DropdownButton>
                        </div>

                        <Link
                          className="btn btn-warning"
                          to={"/inventory/additems"}
                        >
                          Add New Items
                        </Link>
                      </div>

                      {
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="inventory_items_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="inventory_items_id"
                              columns={columns}
                              data={getAllRows()}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <MySearch
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="search-box chat-search-box" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="inventory_items_id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <AllItemsListPagination
                                      pageNum={Math.ceil(
                                        allItemsList?.data
                                          ? allItemsList?.data?.total_items /
                                              allItemsList.data?.per_page
                                          : 1
                                      )}
                                    />
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      );
    } else
      return (
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          {" "}
          <Loader />{" "}
        </Container>
      );
  } else {
    return <Unauthorized />;
  }
};

export default AllItems;
