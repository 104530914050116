import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "../../../css/catalog/editCategory.css";
import { BiImageAdd } from "react-icons/bi";
import {
  updateProduct,
  getProductAddons,
} from "../../../store/actionCreators/Catalog/Catalog";
import ShowProductAddons from "./ShowProductAddons";

const EditProduct = ({ show, close, data }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const [product, setProduct] = useState({});

  const [image, setImg] = useState("");
  const [upload, setUpload] = useState(null);

  useEffect(() => {
    setProduct(data);
    dispatch(getProductAddons(data?.product_list_id));
  }, [data]);

  const closeModal = () => {
    close();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeFields = (field_name, value) => {
    const feild = { ...product };
    feild[field_name] = value;
    setProduct(feild);
  };

  // const onImageChange = (e) => {
  //   const file = e.target.files[0];
  //   const aj = { ...product, image: file };
  //   setUpload(file);
  //   setProduct(aj);
  //   setImg(URL.createObjectURL(file));
  // };

  const onImageChange = async (event) => {
    const file = event.target.files[0];
    const compressedBlob = await compressImage(file, 800, 800);
    const aj = { ...product, image: compressedBlob };
    setUpload(compressedBlob);
    setProduct(aj);
    setImg(URL.createObjectURL(file));
    // upload the compressed blob to your server or database
  };

  const compressImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          if (width <= 600 && height <= 600) {
            width = 400;
            height = 400;
          } else if (width <= 800 && height <= 800) {
            width = 600;
            height = 600;
          } else if (width > maxWidth || height > maxHeight) {
            // Check if the image is already smaller than or equal to the desired dimensions
            const ratio = Math.max(width / maxWidth, height / maxHeight);
            width /= ratio;
            height /= ratio;
          }
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              console.log(`Original size: ${file.size} bytes`);
              console.log(`Compressed size: ${blob.size} bytes`);
              console.log(`Original dimensions: ${img.width} x ${img.height}`);
              console.log(`Compressed dimensions: ${width} x ${height}`);
              resolve(blob);
            },
            "image/png",
            0.6
          );
        };
        img.onerror = (err) => reject(err);
      };
    });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    dispatch(updateProduct(product));

    close();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="btn btn-info mb-2"
        >
          <span>Show Products Addon</span>
        </button>
        <ShowProductAddons
          show={showModal}
          close={handleCloseModal}
          data={data}
        />
        <form onSubmit={onSubmitForm}>
          <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            <div className="mb-2">
              <label>Product Name</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.product_name}
                onChange={(e) => onChangeFields("product_name", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Description</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.description}
                onChange={(e) => onChangeFields("description", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>SKU</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.sku}
                onChange={(e) => onChangeFields("sku", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Price</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.price}
                onChange={(e) => onChangeFields("price", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Prepare Time</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.prepare_time}
                onChange={(e) => onChangeFields("prepare_time", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Product Type</label>

              <select
                className="form-control form-select form-select-sm"
                onChange={(e) => {
                  // console.log(e.target.value, "checkvalu");
                  onChangeFields("product_type", e.target.value);
                }}
              >
                <option value={product.product_type} selected>
                  {product.product_type}
                </option>
                <option value="Barista">Barista</option>
                <option value="Kitchen">Kitchen</option>
              </select>
              {/* <input
                required
                type="text"
                className="form-control"
                value={product.product_type}
                onChange={(e) => onChangeFields("product_type", e.target.value)}
              /> */}
            </div>
            <div className="mb-2">
              <label>Food Type</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.food_type}
                onChange={(e) => onChangeFields("food_type", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Product Active</label>
              {/* <input
              required
              type="text"
              className="form-control"
              value={product.status}
             
            /> */}
              {/* {console.log(product.status)} */}
              {product.status === true || product.status === "true" ? (
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => onChangeFields("status", e.target.value)}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Form.Select>
              ) : (
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => onChangeFields("status", e.target.value)}
                >
                  <option value={false}>Inactive</option>
                  <option value={true}>Active</option>
                </Form.Select>
              )}
            </div>
            <div className="mb-2">
              <label>Show Customer</label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) =>
                  onChangeFields("show_customer", e.target.value)
                }
                value={product.show_customer}
              >
                <option value={false}>False</option>
                <option value={true}>True</option>
              </Form.Select>
            </div>
            <div className="mb-3">
              <p className="mb-1">Image</p>
              <figure className="edit-cat-cont">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <BiImageAdd /> Add
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={onImageChange}
                  accept="image/*"
                />
                {image === "" ? (
                  <img
                    src={product.card_img}
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <img src={image} style={{ width: "100%", height: "100%" }} />
                )}
              </figure>
            </div>
          </div>
          <div>
            <Button className="btn btn-warning" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProduct;
