import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateInvestor } from "../../store/actionCreators/Investors/InvestorsAction";
import UpdateInvestorBranchModal from "./UpdateInvestorBranchModal";
import AddInvestorBranchModal from "./AddInvestorBranchModal";

const EditInvestor = ({ show, close, data }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const [product, setProduct] = useState({});
  const [image, setImg] = useState("");

  useEffect(() => {
    setProduct(data);
  }, [data]);

  const closeModal = () => {
    close();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeFields = (field_name, value) => {
    const feild = { ...product };
    feild[field_name] = value;
    setProduct(feild);
  };

  const onSubmitForm = (e) => {
    console.log(product);
    e.preventDefault();
    dispatch(updateInvestor(product));
    close();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Investor Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="btn btn-info mb-2"
        >
          <span>Update Investor Branches</span>
        </button>
        <UpdateInvestorBranchModal
          show={showModal}
          close={handleCloseModal}
          data={data}
        />
        <form onSubmit={onSubmitForm}>
          <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            <div className="mb-2">
              <label>Investor Name</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.name}
                onChange={(e) => onChangeFields("name", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Contact</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.contact}
                onChange={(e) => onChangeFields("contact", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>GST Number</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.gst_no}
                onChange={(e) => onChangeFields("gst_no", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Email</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.email}
                onChange={(e) => onChangeFields("email", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Address</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.address}
                onChange={(e) => onChangeFields("address", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Bank</label>

              <input
                required
                type="text"
                className="form-control"
                value={product.bank}
                onChange={(e) => onChangeFields("bank", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>IFSC</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.ifsc}
                onChange={(e) => onChangeFields("ifsc", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Account No.</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.account_no}
                onChange={(e) => onChangeFields("account_no", e.target.value)}
              />
            </div>
          </div>
          <div>
            <Button className="btn btn-warning" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditInvestor;
