import axios from "axios";

export const getSalesByStaffReport = (query) => {
  console.log("");
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/staff_reports`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_SALES_BY_STAFF",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setSBSPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_SBS_PAGE",
      page,
    });
  };
};

export const getSalesByStaffDaily = (query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/staff_reports_by_daily`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_SALES_BY_STAFF_DAILY",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setSBSDPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_SBSD_PAGE",
      page,
    });
  };
};

export const getStaffOrderList = (query, records, page) => {
  console.log(query, "hello");
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/staff_order_list/${page}?show=${records}`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_STAFF_ORDER_LIST",
          res,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setSOLPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_SOL_PAGE",
      page,
    });
  };
};

// export const exportSBSCSV = (query) => {
//   console.log("");
//   return (dispatch, getState) => {
//     axios
//       .post(
//         `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_staff_reports`,
//         query
//       )
//       .then((res) => {
//         dispatch({
//           type: "GET_SBS_CSV",
//           res,
//         });
//       })
//       .catch((err) => {
//         console.log("error", err);
//       });
//   };
// };
