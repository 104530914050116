import axios from "axios";
import { toast } from "react-toastify";
import qs from "qs";

export const getStoreList = (page) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_store/${page}`
      )
      .then((res) => {
        dispatch({
          type: "GET_STORE",
          store: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getItemsUnits = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_items_unit`
      )
      .then((res) => {
        dispatch({
          type: "GET_ITEMS_UNIT",
          itemsUnit: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getAllItemsList = (page, query,searchQuery) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_item_list/${page}`,
        { status: query, search:searchQuery }
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_ITEMS_LIST",
          items: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getAllPurchaseOrder = (page, query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_purchase_order/${page}`,
        { status: query }
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_PURCHASE_ORDER",
          allPurchaseOrder: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getCountPurchaseOrder = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/count_purchase_order`
      )
      .then((res) => {
        dispatch({
          type: "GET_COUNT_PURCHASED_ORDER",
          countPurchasedOrders: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getVendorList = (id, page, currVendorStatus) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_vendors/${page}`,
        { store_id: id, status: currVendorStatus }
      )
      .then((res) => {
        dispatch({
          type: "GET_VENDOR",
          allVendors: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getAllStoreList = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_store`
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_STORE",
          allStores: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getInventoryEmployeeList = (page) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_inventory_emp/${page}`
      )
      .then((res) => {
        dispatch({
          type: "GET_INVENTORY_EMPLOYEE",
          inventoryEmployees: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSingleStore = (store_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_store?store_id=${store_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_STORE",
          singleStore: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSinglePurchaseOrder = (purchase_orders_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_purchase_order?purchase_orders_id=${purchase_orders_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_PURCHASE_ORDER",
          singlePurchaseOrder: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSingleItem = (inventory_items_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_item_list?inventory_items_id=${inventory_items_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_ITEM",
          singleItem: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSingleInventoryEmployee = (inventory_login_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_emp?inventory_login_id=${inventory_login_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_INVENTORY_EMPLOYEE",
          singleEmployee: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSingleVendor = (vendor_lists_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_vendors?vendor_lists_id=${vendor_lists_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_VENDOR",
          singleVendor: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setStoreListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_STORE_LIST_PAGINATION",
      page,
    });
  };
};
export const setItemsListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_ITEMS_LIST_PAGINATION",
      page,
    });
  };
};
export const setEmployeeListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_EMPLOYEE_LIST_PAGINATION",
      page,
    });
  };
};
export const setVendorListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_VENDOR_LIST_PAGINATION",
      page,
    });
  };
};
export const setPurchaseOrderListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_PURCHASE_ORDER_LIST_PAGINATION",
      page,
    });
  };
};
// cafe employees actions

export const getAllBranchesInventory = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_branches`
      )
      .then((branches) => {
        dispatch({
          type: "GET_ALL_BRANCHES_INVENTORY",
          inventoryAllBranches: branches,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getCafeEmployeeList = (page) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_cafe_inventory_emp/${page}`
      )
      .then((res) => {
        dispatch({
          type: "GET_CAFE_EMPLOYEE",
          cafeEmployees: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const setCafeEmployeeListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_CAFE_EMPLOYEE_LIST_PAGINATION",
      page,
    });
  };
};
export const getSingleCafeEmployee = (cafe_inventory_login_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_cafe_emp?cafe_inventory_login_id=${cafe_inventory_login_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_CAFE_EMPLOYEE",
          singleCafeEmployee: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// cafe
export const fetchCafeStock = (page, branch_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_cafe_stock/${page}?branch_id=${branch_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_CAFE_STOCK",
          cafeStock: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setCafeStockPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_CAFE_STOCK_PAGINATION",
      page,
    });
  };
};

export const setStoreStockPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_STORE_STOCK_PAGINATION",
      page,
    });
  };
};

export const fetchStoreStock = (page, store_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_inventory_stock/${page}?store_id=${store_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_STORE_STOCK",
          storeStock: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
// count purchas order

export const getCountCafeRequests = (branch_id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/count_cafe_requests`,
        {
          branch_id: branch_id,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_COUNT_CAFE_REQUESTS",
          countCafeRequests: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getAllCafeRequest = (page, query, branch) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_cafe_requests/${page}`,
        {
          status: query,
          branch_id: branch,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_CAFE_REQUESTS",
          allCafeReq: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setCafeRequestListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_CAFE_REQUEST_LIST_PAGINATION",
      page,
    });
  };
};

export const getSingleCafeRequest = (cafe_request_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_cafe_request?cafe_request_id=${cafe_request_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_CAFE_REQUEST",
          singleCafeReq: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSoldProductReport = (startDate,endDate, branchId) => {
  return (dispatch, getState) => 
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_sold_product_report`,
        {
          start_date:startDate,
          end_date:endDate,
          branch_id: branchId,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_SOLD_PRODUCT_REPORT",
          soldProductReport: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };


export const getCountStoreRequestReceiver = (store_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/count_store_request_receiver?store_id=${store_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_COUNT_STORE_REQUEST_RECEIVER",
          countStoreReqReceiver: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getAllStoreReqReceiver = (query, page, store_id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_store_request_receiver/${page}?store_id=${store_id}`,
        { status: query }
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_STORE_REQ_RECEIVER",
          storeReqReceiver: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setStoreReceiveReqPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_STORE_RECEIVE_REQUEST_LIST_PAGINATION",
      page,
    });
  };
};

// store sender req

export const getCountStoreRequestSender = (store_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/count_store_request_sender?store_id=${store_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_COUNT_STORE_REQUEST_SENDER",
          countStoreReqSender: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getAllStoreReqSender = (query, page, store_id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_store_request_sender/${page}?store_id=${store_id}`,
        { status: query }
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_STORE_REQ_SENDER",
          storeReqSender: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setStoreSenderReqPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_STORE_SENDER_REQUEST_LIST_PAGINATION",
      page,
    });
  };
};

export const getSingleStoreReqSender = (store_request_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_store_request?store_request_id=${store_request_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_STORE_REQ_SENDER",
          singleSenderReq: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setSearchTerm = (searchTerm) => ({
  type: "SET_SEARCH_TERM",
  searchTerm,
});