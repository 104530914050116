import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../Loader/LoaderTable";
import { getInventoryEmployeeList } from "../../store/actionCreators/Inventory/InventoryAction";
import { toast } from "react-toastify";
import axios from "axios";
import InventoryEmployeeListPagination from "./InventoryEmployeeListPagination";
import Unauthorized from "../unauthorized";

const AllInventoryEmployees = (props) => {
  const dispatch = useDispatch();
  const inventory_employee_list = useSelector(
    (state) => state.inventory_employee_list
  );
  const page = useSelector((state) => state.employee_list_pagination);

 
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getInventoryEmployeeList(page));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getInventoryEmployeeList(page));
  }, [page]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [inventory_employee_list]);

  const setDefaultData = () => {
    let inventoryEmployeesList = [];
    if (inventory_employee_list.data && inventory_employee_list.data.data) {
      inventoryEmployeesList = inventory_employee_list.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
      }));
    }
    setEmployeeList(inventoryEmployeesList);
  };

  const deleteFunction = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/delete_inventory_employee?inventory_login_id=${id}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          toast.success(`deleted successFully `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getInventoryEmployeeList(1));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewEmployee/${row.inventory_login_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`editEmployee/${row.inventory_login_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row.inventory_login_id)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewStore/${row.inventory_login_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "mobile_no",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "date_of_birth",
      text: "Data of Birth",
      sort: true,
    },

    {
      dataField: "gender",
      text: "Gender",
      sort: true,
    },
    {
      dataField: "store",
      text: "Store",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
    },
    {
      dataField: "last_logged_in",
      text: "Last Logged In",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: inventory_employee_list.data?.per_page,
    totalSize: inventory_employee_list.data
      ? inventory_employee_list.data.total_items
        ? inventory_employee_list.data.total_items
        : 0
      : 0, // replace later with size(customers),
    custom: true,
  };

  const getAllRows = () => {
    const inventory_employee_list = [...employeeList];
    // couponList.unshift(searchBoxes);
    return inventory_employee_list;
  };

  if (props.viewPermission) {
  if (!loading) {
    return (
      <React.Fragment>
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            Inventory store Employees{" "}
          </Col>
          <Col lg={4} sm={8} xs={12}></Col>
          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between">
                      <Card.Title className="h4 mb-4 ml-auto">
                        All Employees
                      </Card.Title>

                      <Link
                        className="btn btn-warning mb-3 "
                        style={{ marginLeft: "1rem", marginRight: "1rem" }}
                        to={"/inventory/addemployees"}
                      >
                        Add New Employee
                      </Link>
                    </div>

                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="inventory_login_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="inventory_login_id"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="inventory_login_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <InventoryEmployeeListPagination
                                    pageNum={Math.ceil(
                                      inventory_employee_list?.data
                                        ? inventory_employee_list?.data
                                            ?.total_items /
                                            inventory_employee_list.data
                                              ?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  } else return <Loader />;
}else {
    return <Unauthorized />;
  }
  
};

export default AllInventoryEmployees;
