import React from "react";
import { Container, Table } from "react-bootstrap";
import Logo from "../../assets/images/logo-sm-light.png";
const DownloadPreview = ({ data, companyD, componentref }) => {
  var d = new Date();
  d = new Date(d.getFullYear(), d.getMonth() + 1, 10);

  const nextMonth = d.toLocaleDateString();
  return (
    <Container fluid style={{ backgroundColor: "#F1F5F7" }}>
      <div
        ref={componentref}
        style={{ width: "800px", margin: "auto", padding: "10px" }}
      >
        {/* <div className="ml-auto">
          <img
            alt="logo"
            src={Logo}
            className=" cursor-pointer float-left"
            style={{ width: "75px", margin: "10px" }}
          />
        </div> */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Pay Period</th>
              <th>Percentage Cut</th>

              <th>Sale Punched</th>
              <th>GST</th>
              {/* <th>Sale After Tax</th>
              <th>Commission</th>
              <th>TDS</th>
              <th>New Payable Amount</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.date}</td>
              <td>{data?.percentage_cut}</td>

              <td>{data?.sale}</td>
              <td>{data?.gst}</td>
              {/* <td>{data?.sale_after_tax}</td>
              <td>{data?.commission}</td>
              <td>{data?.tds}</td>
              <td>{data?.net_payable}</td> */}
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sale After Tax</th>
              <th>Commission</th>
              <th>TDS</th>
              <th>New Payable Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.sale_after_tax}</td>
              <td>{data?.commission}</td>
              <td>{data?.tds}</td>
              <td>{data?.net_payable}</td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Company</th>
              <th>GST Number</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{companyD?.name}</td>
              <td>{companyD?.gst_no}</td>
              <td>{companyD?.contact}</td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Investor Name</th>
              <th>Contact</th>
              <th>Banking Details:</th>
              {/* <th>Location</th>
              <th>IFSC</th>
              <th>Account no.</th>

              <th>Due Date of Credit:</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.name}</td>

              <td>{data?.contact}</td>

              <td>{data?.bank}</td>
              {/* <td>{data?.branch_name}</td>
              <td>{data?.ifsc}</td>
              <td>{data?.account_no}</td>

              <td>{nextMonth}</td> */}
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Location</th>
              <th>IFSC</th>
              <th>Account no.</th>

              <th>Due Date of Credit:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.branch_name}</td>
              <td>{data?.ifsc}</td>
              <td>{data?.account_no}</td>

              <td>{nextMonth}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default DownloadPreview;
