import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";

const AddOrEditVariantModal = ({
  showModal,
  handleModalClose,
  options,
  tags,
}) => {
  return (
    <Modal show={showModal} size="md" onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add or Edit Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Options</Form.Label>
            <Form.Control type="text" placeholder="Enter Options" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Values</Form.Label>
            <Select
              isMulti
              options={tags.map((tag) => ({ value: tag, label: tag }))}
              placeholder="Select Values"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Price</Form.Label>
            <Form.Control type="number" placeholder="Enter Price" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Compare Price</Form.Label>
            <Form.Control type="number" placeholder="Enter Compare Price" />
          </Form.Group>
          <Form.Group>
            <Form.Label>SKU</Form.Label>
            <Form.Control type="text" placeholder="Enter SKU" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Inventory Management</Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                label="Don't track my inventory"
                name="inventoryManagement"
              />
              <Form.Check
                inline
                type="radio"
                label="Track my inventory"
                name="inventoryManagement"
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Check type="checkbox" label="Inventory Allow Out Of Stock" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Inventory Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Inventory Quantity"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Barcode</Form.Label>
            <Form.Control type="text" placeholder="Enter Barcode" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Weight</Form.Label>
            <Form.Control type="number" placeholder="Enter Weight" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Min Limit To Buy This Product</Form.Label>
            <Form.Control type="number" placeholder="Enter Min Limit" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Max Limit To Buy This Product</Form.Label>
            <Form.Control type="number" placeholder="Enter Max Limit" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Tags</Form.Label>
            <Form.Control type="text" placeholder="Enter Tags" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
        <Button variant="primary">Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOrEditVariantModal;
