import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleStore } from "../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import { toast } from "react-toastify";

const EditStore = ({ sideToggle }) => {
  const store = useSelector((state) => state.get_single_store_data);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate=useNavigate()

  const [data, setData] = useState({
    store_name: "",
    city: "",
    region: "",
    address: "",
    email: "",
    contact: "",
    timing: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    dispatch(getSingleStore(params.store_id));
  }, []);

  console.log(store, "data");

  useEffect(() => {
    setData(store);
  }, [store]);

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      store_name,
      city,
      region,
      address,
      email,
      contact,
      timing,
      latitude,
      longitude,
    } = data;

    const dataClone = {
      store_name,
      city,
      region,
      address,
      email,
      contact,
      timing,
      latitude,
      longitude,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_store?store_id=${params.store_id}`,
        dataClone
      )
      .then((res) => {
        if (res.data.status === "success")
          toast.success(`updated successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

        navigate("/inventory/allstores");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {store ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Edit Store
                    </Card.Title>
                  </div>
                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Store Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.store_name}
                          onChange={(e) =>
                            setData({ ...data, store_name: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>City:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.city}
                          onChange={(e) =>
                            setData({ ...data, city: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Region:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.region}
                          onChange={(e) =>
                            setData({ ...data, region: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Address:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.address}
                          onChange={(e) =>
                            setData({ ...data, address: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Email:</label>
                        <input
                          required
                          type="email"
                          className="form-control"
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Contact:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          value={data.contact}
                          onChange={(e) =>
                            setData({ ...data, contact: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>timing:</label>
                        <input
                          type="text"
                          placeholder="00 AM - 00 PM"
                          className="form-control"
                          value={data.timing}
                          onChange={(e) =>
                            setData({ ...data, timing: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Latitude:</label>
                        <input
                          type="number"
                          className="form-control"
                          value={data.latitude}
                          onChange={(e) =>
                            setData({ ...data, latitude: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Longitude:</label>
                        <input
                          type="number"
                          className="form-control"
                          value={data.longitude}
                          onChange={(e) =>
                            setData({ ...data, longitude: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <Button type="submit" className="btn btn-warning">
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default EditStore;
