import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { RiStackLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getCountPurchaseOrder } from "../../../store/actionCreators/Inventory/InventoryAction";
import AllPurchasedOrdersList from "./AllPurchasedOrdersList";
import { useState } from "react";
import Unauthorized from "../../unauthorized";

const AllPurchasedOrder = (props) => {
  const dispatch = useDispatch();
  const purchasedCount = useSelector(
    (state) => state.get_count_purchased_order
  );

  const [query, setQuery] = useState("");
  const [clickedData, setClickedData] = useState({});

  console.log(purchasedCount);

  useEffect(() => {
    dispatch(getCountPurchaseOrder());
  }, []);

  if (props.viewPermission) {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
          Inventory Purchased Orders{" "}
        </Col>
        <Row>
          {purchasedCount.data &&
            purchasedCount?.data?.arr.map((item) => (
              <Col
                lg
                sm={6}
                xs={6}
                key={item.total}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setQuery(item.status);
                  setClickedData(item);
                }}
              >
                <Card className="dash-inv-card">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="flex-1">
                        <Card.Title className="card-title">
                          {item.status}
                        </Card.Title>
                        <Card.Text>{item.count}</Card.Text>
                      </div>
                      <div
                        className="text-primary icon-color"
                        style={{ fontSize: "24px" }}
                      >
                        <RiStackLine />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <AllPurchasedOrdersList
          query={query}
          clickedData={clickedData}
          editPermission={props.editPermission}
          props={props}
        />
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AllPurchasedOrder;
