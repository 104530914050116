export const allNotificationListReducer = (state = "", action) => {
    switch (action.type) {
      case "GET_ALL_NOTIFICATIONS":
        return action.notifications;
      default:
        return state;
    }
  };

  export const getNotificationBranchesReducer = (state = "", action) => {
    switch (action.type) {
      case "GET_ALL_NOTIFICATION_BRANCHES":
        return action.notificationAllbranches;
      default:
        return state;
    }
  };
  export const notificationPaginationReducer = (state = 1, action) => {
    switch (action.type) {
      case "SET_NOTIFICATION_LIST_PAGINATION":
        return action.page;
      default:
        return state;
    }
  };

  
  export const getSingleNotificationReducer = (state = "", action) => {
    switch (action.type) {
      case "GET_SINGLE_NOTIFICATION":
        return action.singleNotification;
      default:
        return state;
    }
  };
