import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

const ViewCustomerRoles = ({ show, close, data }) => {
  const [customerRole, setCustomerRole] = useState({});

  useEffect(() => {
    setCustomerRole(data);
  }, [data]);

  const handleClose = () => {
    close();
  };

  //   const dispatch = useDispatch();
  // MOdal popup vars end

  return (
    <Modal className="remove-border" show={show} onHide={handleClose} centered>
      <Modal.Header className=" bg-light text-dark remove-border" closeButton>
        <Modal.Title>{customerRole.customer_type}</Modal.Title>
      </Modal.Header>
      <Modal.Body className=" bg-light text-light remove-border">
        <table className="table table-borderless indi-table mb-0">
          <tbody>
            <tr>
              <th scope="row">Minimum Purchase</th>
              <td>{customerRole.min_purchase}</td>
            </tr>
            <tr>
              <th scope="row">Total Days</th>
              <td>{customerRole.total_days}</td>
            </tr>

            <tr>
              <th scope="row">Reduce Purchase</th>
              <td>{customerRole.reduce_purchase}</td>
            </tr>
            <tr>
              <th scope="row">Upgrade Purchase</th>
              <td>{customerRole.upg_purchase}</td>
            </tr>
            <tr>
              <th scope="row">Upgrade Days</th>
              <td>{customerRole.upg_days}</td>
            </tr>
            <tr>
              <th scope="row">Kitchen Discount Percent</th>
              <td>{customerRole.kitchen_disc_percent}</td>
            </tr>
            <tr>
              <th scope="row">Barista Discount Percent</th>
              <td>{customerRole.barista_disc_percent}</td>
            </tr>
            <tr>
              <th scope="row">Created At</th>
              <td>{customerRole.createdAt}</td>
            </tr>
            <tr>
              <th scope="row">Updated At</th>
              <td>{customerRole.updatedAt}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default ViewCustomerRoles;
