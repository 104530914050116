export const AllInvestorReducers = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_INVESTORS":
      return action.investors;
    default:
      return state;
  }
};
export const AllCompanyReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_COMPANY":
      return action.company;
    default:
      return state;
  }
};
export const deleteInvestorReducer = (state = "", action) => {
  switch (action.type) {
    case "DEL_INVESTOR":
      return state;
    default:
      return state;
  }
};
export const deleteBranchInvestorReducer = (state = "", action) => {
  switch (action.type) {
    case "DELETE_BRANCH_INVESTOR":
      return state;
    default:
      return state;
  }
};

export const addBranchInvestor = (state = "", action) => {
  switch (action.type) {
    case "ADD_BRANCH_INVESTOR":
      return action.payload;
    default:
      return state;
  }
};
