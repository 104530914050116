import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ItemsAvailable } from "../../../store/actionCreators/Catalog/Catalog";
import { toast } from "react-toastify";
import { getSingleBranch } from "../../../store/actionCreators/Branch/BranchAction";
import { getSingleFranchise } from "../../../store/actionCreators/Franchise/AddNewFranchiseAction";
import { useRef } from "react";

const EditItemAvailableModalF = ({ branch, data, close, show, franchise }) => {
  useEffect(() => {
    console.log(data.items_available, "DATAITEM");
  }, [data]);

  const dispatch = useDispatch();

  const [itemsAvailable, setItemsAvailable] = useState(
    parseInt(data.items_available)
  );
  const [price, setPrice] = useState(parseInt(data.price));
  const [basePrice, setBasePrice] = useState(
    parseInt(data?.product_list?.price)
  );

  const dataRef = useRef(data);
  dataRef.current = data;

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const val = {
        product_id: data.product_id,
        items_available: itemsAvailable,
        price,
      };
      dispatch(ItemsAvailable(val))
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(getSingleFranchise(franchise));
            setTimeout(() => {
              dispatch(getSingleBranch(branch));
            }, 1000);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(getSingleBranch(branch));
          }, 2000);
        });
      closeModal();
    },
    [closeModal, dispatch, branch, data.product_id, itemsAvailable, price]
  );

  const onChangeFields = useCallback((fieldName, value) => {
    if (fieldName === "items_available") {
      setItemsAvailable(parseInt(value));
    } else if (fieldName === "price") {
      setPrice(parseInt(value));
    }
  }, []);

  useEffect(() => {
    setItemsAvailable(parseInt(dataRef.current.items_available));
    setPrice(parseInt(dataRef.current.price));
    setBasePrice(parseInt(dataRef.current?.product_list?.price));
  }, [show]);

  return (
    <>
      <Modal show={show} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Row>
              <div className="mb-2">
                <label>Items Available</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  value={itemsAvailable}
                  onChange={(e) =>
                    onChangeFields("items_available", e.target.value)
                  }
                />
              </div>
              <div className="mb-2">
                <label>Additional Price</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  value={price}
                  min={0}
                  onChange={(e) => onChangeFields("price", e.target.value)}
                />
              </div>
              <div className="mb-2">
                <label>Base Price</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  value={basePrice}
                  disabled
                />
              </div>
            </Row>
            <div>
              <Button className="btn btn-warning" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditItemAvailableModalF;
