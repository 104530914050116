import React, { useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { RiStackLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getCountCafeRequests } from "../../../store/actionCreators/Inventory/InventoryAction";
import { useState } from "react";
import { getAllBranchesInventory } from "../../../store/actionCreators/Inventory/InventoryAction";
import AllCafeRequestList from "./AllCafeRequestList";
import Unauthorized from "../../unauthorized";

const AllCafeRequests = (props) => {
  const dispatch = useDispatch();
  const cafeRequestCount = useSelector((state) => state.cafe_request_count);
  const AllBranchList = useSelector((state) => state.get_all_branches);
  const [query, setQuery] = useState("");
  const [clickedData, setClickedData] = useState({});
  const [options, setOptions] = useState([]);
  const [branchValue, setCurrBranchValue] = useState("");
  const [branchName, setBranchName] = useState("");

  console.log({ cafeRequestCount, branchValue, branchName });

  useEffect(() => {
    let array = [];
    if (AllBranchList.data) {
      array = AllBranchList.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [AllBranchList]);

  useEffect(() => {
    dispatch(getCountCafeRequests(branchValue));
  }, [branchValue]);

  useEffect(() => {
    dispatch(getAllBranchesInventory());
  }, []);

  const displayBranches = () => {
    return [{ label: "All", value: "All" }, ...options].map((item, index) => {
      return (
        <Dropdown.Item key={index} eventKey={`${item?.label},${item?.value}`}>
          {" "}
          {item?.label}
        </Dropdown.Item>
      );
    });
  };

  const handleSelectBranch = (e) => {
    const value = e.split(",")[1];
    const name = e.split(",")[0];
    setBranchName(name);
    setCurrBranchValue(value);
  };


    if (props.viewPermission) {
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Col lg={8} sm={4} xs={12} className="inventory-dash-head ">
        CAFE REQUESTS{" "}
        <div className="d-flex mt-2" style={{ gap: "12px" }}>
          <DropdownButton
            variant="light"
            title={branchName ? branchName : "All Branches"}
            id="dropdown-menu-align-right"
            onSelect={handleSelectBranch}
          >
            {displayBranches()}
          </DropdownButton>
        </div>
      </Col>
      <Row>
        {cafeRequestCount.data &&
          cafeRequestCount?.data?.arr.map((item, i) => (
            <Col
              lg
              sm={6}
              xs={6}
              key={i}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setQuery(item.status);
                setClickedData(item);
              }}
            >
              <Card className="dash-inv-card">
                <Card.Body>
                  <div className="d-flex">
                    <div className="flex-1">
                      <Card.Title className="card-title">
                        {item.status}
                      </Card.Title>
                      <Card.Text>{item.count}</Card.Text>
                    </div>
                    <div
                      className="text-primary icon-color"
                      style={{ fontSize: "24px" }}
                    >
                      <RiStackLine />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        {cafeRequestCount?.data?.arr.length === 0 && (
          <strong> NO REQUEST FOUND</strong>
        )}
      </Row>
      <AllCafeRequestList
        query={query}
        branchValue={branchValue}
        clickedData={clickedData}
        editPermission={props.editPermission}
        props={props}
      />
    </Container>
  );
    } else {
    return <Unauthorized />;
    }
};

export default AllCafeRequests;
