import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import plusSignImg from "../../assets/images/plusSign.jpg";

const AddOndcProduct = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    inventory_quantity: "",
    images: [{ image: { type: "", uploadType: "url", name: "", data: "" } }],
  });

  const handleChooseFile = (index) => {
    document.getElementById(`file-input-${index}`).click();
  };

  const handleImageChange = (index, event) => {
    const { files } = event.target;

    if (files.length > 0) {
      const selectedFile = files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImages = [...formData.images];
        newImages[index] = {
          image: {
            type: selectedFile.type,
            uploadType: "file",
            name: selectedFile.name,
            data: e.target.result,
          },
        };
        setFormData((prevFormData) => ({
          ...prevFormData,
          images: newImages,
        }));
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleAddImage = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [
        ...prevFormData.images,
        { image: { type: "", uploadType: "url", name: "", data: "" } },
      ],
    }));
  };

  const handleRemoveImage = (index) => {
    const newImages = [...formData.images];
    newImages.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      images: newImages,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Make API call to submit the form data
    const apiUrl = "{{BASE_URL}}/api/1.1/entity/ms.products";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-key": "{{access-key}}",
      },
      body: JSON.stringify(formData),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="form-head mb-2">Add ONDC Product</div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="price">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="inventory_quantity">
          <Form.Label>Inventory Quantity</Form.Label>
          <Form.Control
            type="number"
            name="inventory_quantity"
            value={formData.inventory_quantity}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {formData.images.map((image, index) => (
          <Row key={index} className="mb-3 align-items-center">
            <Col xs={10}>
              <Form.Group controlId={`image-${index}`}>
                <Form.Label>Images </Form.Label>
                <div className="input-group">
                  <Form.Control
                    type="text"
                    className="form-control"
                    readOnly
                    value={image.image.name || ""}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={(event) => handleImageChange(index, event)}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleChooseFile(index)}
                  >
                    Choose File
                  </Button>
                </div>

                {image.image && image.image.data ? (
                  <img
                    src={image.image.data}
                    alt={image.image.name}
                    className="mt-2"
                    style={{ maxWidth: "100px" }}
                  />
                ) : (
                  <img
                    src={plusSignImg}
                    alt="Placeholder"
                    className="mt-2"
                    style={{ maxWidth: "100px" }}
                  />
                )}
              </Form.Group>
            </Col>
            <Col xs={2} className="text-end">
              {index === formData.images.length - 1 ? (
                <Button
                  variant="link"
                  className="text-success"
                  onClick={handleAddImage}
                >
                  ADD
                  <AiOutlinePlusCircle />
                </Button>
              ) : (
                <Button
                  variant="link"
                  className="text-danger"
                  onClick={() => handleRemoveImage(index)}
                >
                  Remove
                </Button>
              )}
            </Col>
          </Row>
        ))}

        <Button className="mt-2" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default AddOndcProduct;
