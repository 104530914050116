import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getSalesByStaffReport } from "../../../store/actionCreators/Reports/StaffReportsAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { CSVLink } from "react-csv";
import { fetchEmployees } from "../../../store/actionCreators/Employees/EmployeeAction";
import Async from "react-select/async";
import axios from "axios";
import { toast } from "react-toastify";
import ScrollButton from "../../scrolltotop/ScrollButton";
import StaffLogoutReportTable from "./StaffLogoutReportTable";
import Loader from "../../Loader/LoaderTable";

const StaffLogoutReport = (props) => {
  const dispatch = useDispatch();
  const sbsReport = useSelector((state) => state.sales_by_staff);
  const sbsdReport = useSelector((state) => state.sales_by_staff_daily);
  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);

  const [page, setPage] = useState(1);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [empArr, setEmpArr] = useState({
    label: "All",
    value: "All",
  });
  const [query, setQuery] = useState({
    employee_id: "All",
    branch_id: "All",
    employee: "",
    // status: "All",
    // start: "2021-01-19",
    // end: "2022-01-02",

    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [empSelected, setEmpSelected] = useState(
    sessionStorage.getItem("employee")
      ? JSON.parse(sessionStorage.getItem("employee"))
      : {
          value: "",
          label: "Search Employee",
        }
  );
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [durationSelected, setDurationSelected] = useState({
    label: "All",
    value: "All",
  });

  const [isDaily, setIsDaily] = useState(false);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setProductData([]);
    dispatch(fetchEmployees(1, "All", "All", query.employee));
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [branch, employees]);

  useEffect(() => {
    if (
      sbsReport.data &&
      sbsReport.data.status === "success" &&
      durationSelected.value === "All" &&
      showReportClicked
    ) {
      setIsDaily(false);
      const productDataClone = sbsReport.data.data.map((e) => {
        return {
          ...e,
          // full_name: e.emplyees?.full_name,
          // paid_price: e.paid_price.toLocaleString(),
          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
        };
      });
      productDataClone.push(total);
      setProductData(productDataClone);
      setPage(1);
    }
  }, [sbsReport]);

  useEffect(() => {
    if (
      sbsdReport.data &&
      sbsdReport.data.status === "success" &&
      durationSelected.value !== "All" &&
      showReportClicked
    ) {
      setIsDaily(true);
      setProductData(
        sbsdReport.data.data.map((e) => ({
          ...e,
          // full_name: e.emplyees.full_name,
          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
        }))
      );
      setPage(1);
    }
  }, [sbsdReport]);

  const loadData = async (emp) => {
    console.log(emp);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((e) => ({
          // label: `${e?.full_name}`,
          // value: e?.employee_id,
          label: ` ${e?.full_name}`,
          value: e?.full_name,
          branch_id: e?.branch_id,
          employee_id: e?.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };
  const loadDataemplo = () => {
    return (
      !!employees?.data?.data &&
      employees?.data?.data.map((item, index) => {
        return {
          label: item.full_name,
          value: item.full_name,
          branch_id: item.branch_id,
          employee_id: item.employee_id,
        };
      })
    );
  };

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }

    if (employees.data && employees.data.data) {
      const empArrClone = employees.data.data.map((b) => ({
        label: b?.full_name,
        value: b?.employee_id,
      }));
      empArrClone.unshift({ label: "All", value: "All" });
      setEmpArr(empArrClone);
    }
  };

  const onShowReport = () => {
    if (!empSelected.employee_id) {
      // alert("PLEASE SELECT EMPLOYEE NAME");
      toast.error(`PLEASE SELECT EMPLOYEE NAME`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setShowReportClicked(true);
    }
  };

  const columnsDaily = [
    {
      dataField: "day",
      text: "Day",
      sort: true,
    },
    {
      dataField: "staff",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "order_count",
      text: "Order Count",
      sort: true,
    },
    {
      dataField: "sales_total",
      text: "Sales Total",
      sort: true,
    },
  ];


  const total = {
    not_include: true,
    full_name: <strong>Total</strong>,
    paid_price: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(sbsReport.data.total_sales).toLocaleString()
          : ""}
      </strong>
    ),
    order_count: (
      <strong>{sbsReport.data ? sbsReport.data.total_order_count : ""}</strong>
    ),
  };

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">STAFF LOGOUT REPORT</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body style={{ height: "200px" }}>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={loadDataemplo()}
                          cacheOptions={loadDataemplo()}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => {
                            sessionStorage.setItem(
                              "employee",
                              JSON.stringify(e)
                            );
                            setEmpSelected(e);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked || !!empSelected.employee_id ? (
        <StaffLogoutReportTable empSelected={empSelected} />
      ) : (
        ""
      )}
      <ScrollButton />
    </React.Fragment>
    // </Container>
  );
};

export default StaffLogoutReport;
