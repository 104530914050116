import axios from "axios";

export const getCustomerByGroupReport = (query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/customer_by_group`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_CBG_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getCustomerSalesSummaryReport = (query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/cust_sale_sum`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_CSS_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getCustomerSalesOrderList = (query, page) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/cust_sale_order_list/${page}`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_CSOL_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setCBGPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_CBG_PAGE",
      page,
    });
  };
};

export const getCustomerListByGroupReport = (query, page, records) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/customer_list_by_group/${page}?show=${records}`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_CLBG_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getCustomerGroupChangeLogs = (query, page, records) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/customer_group_change_logs/${page}?show=${records}`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_CGCL_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getCustomerWalletBalanceSummary = (query, page, records) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/cust_wallet_balance_report/${page}?show=${records}`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_CWB_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setCLBGPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_CLBG_PAGE",
      page,
    });
  };
};

export const getPerCustomerAnalysis = (query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/per_customer_analysis`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_PCA_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getPerCustomerOrderList = (query, page, records) => {
  console.log(query);
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/per_customer_orderlist/${page}?show=${records}`,
        query
      )
      .then((res) => {
        console.log("order list", res);
        dispatch({
          type: "SET_PCOL",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setPCOLPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_PCOL_PAGE",
      page,
    });
  };
};
