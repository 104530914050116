import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

export const getAllCategories = () => {
  return (dispatch, getState) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/admin/product/get_category`)
      .then((categories) => {
        dispatch({
          type: "GET_ALL_CATEGORIES",
          categories,
        });
      });
  };
};
export const getAllProducts = () => {
  return (dispatch, getState) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/admin/product/get_products`)
      .then((products) => {
        dispatch({
          type: "GET_ALL_PRODUCTS",
          products,
        });
      });
  };
};
export const getAddons = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/getaddons_w_opt`
      )
      .then((addons) => {
        dispatch({
          type: "GET_ALL_ADDONS",
          addons,
        });
      });
  };
};
export const addNewCategory = (object) => {
  const form = new FormData();

  for (let key in object) {
    form.append(key, object[key]);
  }

 return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/product/add_category`,
      form,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((res) => {
      toast.success(`NEW CATEGORY ADDED SUCCESFULLY`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      const myTimeout = setTimeout(window.location.reload(false), 3000);
      clearTimeout(myTimeout);
    })
    .catch((err) => {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("error", err);
    });
};
export const addNewFoodItem =(object) => {
  console.log(object, "megya");
  const form = new FormData();

  for (let key in object) {
    form.append(key, object[key]);
  }

 
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/product/add_fooditem`,
      form,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((res) => {
      console.log("New product resopnse", res);
      toast.success(`New Product added successfully`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // const myTimeout = setTimeout(window.location.reload(false), 3000);
      // clearTimeout(myTimeout);
      setTimeout(() => {
        window.location.href = "/catalog/AddProduct"
      }, 1000);
    })
    .catch((err) => {
      console.log("error", err);
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
};

export const addNewAddon = (title, type, options) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}api/v1/admin/product/add_addons`, {
      title: title,
      add_on_type: type,
      add_on_options: options,
    })
    .then((res) => {
      console.log(res);
      toast.success(`New Addon added successfully`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(window.location.reload(false), 3000);
    })
    .catch((err) => {
      console.log("error", err);
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
};
export const get_category_branches = (cat_list_id, branch_id) => {
  console.log(cat_list_id, branch_id);
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/get_category_by_branches?category_list_id=${cat_list_id}&branch_id=${branch_id}`
      )
      .then((data) => {
        console.log("result", data);
        dispatch({
          type: "GET_BRANCH_PRODUCT",
          data: data.data,
        });
      });
  };
};

export const get_product_branch = (cat_list_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/get_category_branches?category_list_id=${cat_list_id}`
      )
      .then((data) => {
        dispatch({
          type: "GET_CATEGORY_BRANCHES",
          data: data.data,
        });
      });
  };
};

export const get_all_qrcodes = () => {
  return (dispatch, getState) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/admin/product/fetch_all_qr`)
      .then((data) => {
        dispatch({
          type: "GET_ALL_QRCODES",
          data: data,
        });
      });
  };
};

export const get_single_qrcode = (qr_code_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/fetch_single_qr?qr_code_id=${qr_code_id}`
      )
      .then((data) => {
        dispatch({
          type: "GET_SINGLE_QRCODE",
          data: data,
        });
      });
  };
};

export const addQrBranch = (data) => {
  return (dispatch, getState) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/add_qr_branch`,
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data && res.data.status === "Success") {
          toast.success(`QRCode added to branch successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "ADD_BRANCH_TO_QR",
            data,
          });
        }

        // setTimeout(window.location.reload(false), 3000);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
};

export const deleteQrBranch = (data) => {
  console.log(data);
  return (dispatch, getState) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/del_qr_branch`,
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data && res.data.status === "Success") {
          toast.success(`QRCode deleted from branch successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DEL_BRANCH_FROM_QR",
            data,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
};

export const deleteQrCode = (data) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}api/v1/admin/product/del_qr`, data)
    .then((res) => {
      if (res.data && res.data.status === "Success")
        toast.success(`QRCode deleted successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      setTimeout(window.location.reload(false), 5000);
    })
    .catch((err) => {
      console.log("error", err);
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
};

export const updateQrCode = (data) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}api/v1/admin/product/upd_qr`, data)
    .then((res) => {
      if (res.data && res.data.status === "Success") {
        toast.success(`QRCode updated successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        saveAs(res.data.data.url, "qrcode");
        setTimeout(window.location.reload(false), 3000);
      }
    })
    .catch((err) => {
      console.log("error", err);
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
};

export const addNewQrCode = (data) => {
  console.log(data);
  axios
    .post(`${process.env.REACT_APP_API_URL}api/v1/admin/product/add_qr`, data)
    .then((res) => {
      console.log(res);
      if (res.data && res.data.data) {
        toast.success(`New QRCode added successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        saveAs(res.data.data.url, "qrcode");
        setTimeout(window.location.reload(false), 5000);
      }
    })
    .catch((err) => {
      console.log("error", err);
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
};

export const getSingleCategory = (category) => {
  return (dispatch, getState) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/admin/product/get_category`)
      .then((categories) => {
        dispatch({
          type: "GET_ALL_CATEGORIES",
          categories,
        });
      });
  };
};

export const updateCategory = (category) => {
  const form = new FormData();
  for (let key in category) {
    form.append(key, category[key]);
  }

  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/edit_category_list`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`Category Updated succesfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(window.location.reload(false), 2000);

          dispatch({
            type: "UPDATE_CATEGORY",
            payload: category,
          });
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const addProductToCategory = (productdata) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/product/add_product_branch`,
      productdata
    )
    .then((res) => {
      console.log("success", res);
      if (res.data) {
        toast.success(`${res.data.msg}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return res.data.data;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });
};


export const currentCat = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CURRENT_CAT",
      payload: data,
    });
  };
};

export const currentBranch = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "get_current_branch",
      payload: data,
    });
  };
};
export const deleteProductCat = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/product/del_product_branch?product_id=${data}`
    );
    if (response.data.status === "success") {
      toast.success(`Product Deleted successfully`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      // Perform additional actions after successful API call here
      // ...

      return response;
    } else {
      toast.error(`Failure`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      throw new Error("API call failed");
    }
  } catch (error) {
    console.error(error);
  }
};


export const updateProduct = (product) => {
  const form = new FormData();
  for (let key in product) {
    form.append(key, product[key]);
  }

  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/edit_product_list`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`Product Updated succesfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(window.location.reload(false), 3000);

          dispatch({
            type: "UPDATE_PRODUCT",
            payload: product,
          });
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const GetProductCat = (data) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/del_product_branch?product_id=${data}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`Product Deleted successfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "DELETE_PRODUCT",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

export const ItemsAvailable = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/edit_itemavailable`,
        data
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`Updated successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "ITEMS_AVAILABLE",
          });
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        return res; // Return response
      });
  };
};

export const getSingleAddon = (addon) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/get_single_addons?add_ons_id=${addon}`
      )
      .then((data) => {
        dispatch({
          type: "GET_SINGLE_ADDON",
          data,
        });
      });
  };
};

export const updateAddonProduct = (product) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/update_addons`,
        product
      )
      .then((res) => {
        if (res.data.status === "Success") {
          console.log(res.data);
          toast.success(`Addon Updated succesfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "UPDATE_ADDON_PRODUCT",
          });
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const deleteSingleAddon = (id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/del_single_addons?add_ons_id=${id}`
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success(`Deleted successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DELETE_SINGLE_ADDON",
          });
          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 2500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

export const deleteCategory = (id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/delete_category_list?id=${id}`
      )
      .then((res) => {
        if (res.data.msg === "deleted") {
          toast.success(`Deleted successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DELETE_CATEGORY",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 2500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

export const deleteProduct = (id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/delete_product_list?id=${id}`
      )
      .then((res) => {
        if (res.data.msg === "deleted") {
          toast.success(`Deleted successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DELETE__PRODUCT",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 2500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

// delete addon options

export const deleteAddonOption = (id) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/del_addons_option?add_on_option_id=${id}`
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success(`Deleted successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DELETE_ADDON_OPTION",
          });
          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 2500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

export const deleteAddonProduct = (id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/delete_addon_product?per_product_add_ons_id=${id}`
      )
      .then((res) => {
        console.log(res.data.msg);
        if (res.data.msg === "deleted") {
          toast.success(`Deleted successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch({
            type: "DELETE_ADDON_PRODUCTS",
          });
          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 2500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};

// add new addons options

export const AddMoreAddonOptions = (id, options, setFormData) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/product/add_addons_option`,
      {
        add_ons_id: id,
        add_on_options: options,
      }
    )
    .then((res) => {
      // console.log(res);

      toast.success(`New Addon Options added successfully`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/v1/admin/product/get_single_addons?add_ons_id=${id}`
        )
        .then((data) => {
          // console.log(data, "ggg");
          setFormData(data?.data?.data);
        });

      // setTimeout(window.location.reload(false), 3000);
    })
    .catch((err) => {
      console.log("error", err);
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
};

export const getAddonProduct = (id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/get_add_on_products?add_ons_id=${id}`
      )
      .then((addon_products) => {
        console.log(addon_products, "products");
        dispatch({
          type: "GET_ADDON_PRODUCTS",
          addon_products,
        });
      });
  };
};

// add product to addons modal

export const addProductToAddon = (productdata) => {
  console.log(productdata);
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/add_product_addons`,
        {
          add_ons_id: productdata?.add_ons_id,
          product_list_id: productdata?.product_list_id,
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          toast.success(`Product Added successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "ADD_PRODUCT_TO_ADDON",
            payload: res.data.data,
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};
// get product addons
export const getProductAddons = (id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/get_product_add_ons?product_list_id=${id}`
      )
      .then((get_product_addons) => {
        console.log("get_product_addons", get_product_addons);
        dispatch({
          type: "GET_PRODUCT_ADDONS",
          get_product_addons,
        });
      });
  };
};

// update addons order
export const updateAddonsOrder = (data) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/product/edit_product_addon_order`,
        { data }
      )
      .then((res) => {
        console.log(res);
        if (res.data.msg === "success") {
          toast.success(`Addons order updated successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`Failure`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
};
