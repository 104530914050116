import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { updateGiftCard } from "../../../store/actionCreators/Coupons/CouponsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleGiftCard } from "../../../store/actionCreators/Coupons/CouponsAction";
import { useMemo } from "react";
import axios from "axios";
import Async from "react-select/async";
import Spinner from "react-bootstrap/Spinner";
import Loader from "../../Loader/Loader";
import { TailSpin } from "react-loader-spinner";

const EditGiftCard = () => {
  const gift_card = useSelector((state) => state.gift_card);

  const dispatch = useDispatch();
  const params = useParams();

  const [custArray, setCustArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    title: "",
    customer_id: "",
    description: "",
    created_by: "Heebee",
    amount: "",
    status: false,
    createdAt: "",
  });

  useEffect(() => {
    if (!gift_card) {
      dispatch(getSingleGiftCard(params.gift_card_id));
    }
    dispatch(getSingleGiftCard(params.gift_card_id));
  }, [params.gift_card_id]);

  useEffect(() => {
    if (!gift_card) {
      return;
    }
    setData(gift_card?.data);
    setCustArray(
      ((gift_card && gift_card?.customers) || []).map((c) => ({
        label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
          c.mobile_no
        })`,
        value: c.customer_id,
      }))
    );
  }, [gift_card]);

  const handleUpdateGiftCard = () => {
    dispatch(updateGiftCard(data, customerString));
  };

  const onChangeFields = (field_name, value) => {
    const dataClone = { ...data };

    dataClone[field_name] = value;
    setData(dataClone);
  };

  const loadData = async (customer) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_customers/1?search=${customer}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
            c.mobile_no
          })`,
          value: c.customer_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  let customerString = useMemo(() => {
    let customerString = "";
    for (let i = 0; i < custArray.length; i++) {
      if (i === custArray.length - 1) {
        customerString = customerString + custArray[i].value;
      } else {
        customerString = customerString + custArray[i].value + ",";
      }
    }

    return customerString;
  }, [custArray]);

  // const defaultArray = () => {
  //   const custA = custArray.map((c) => ({
  //     label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
  //       c.mobile_no
  //     })`,
  //     value: c.customer_id,
  //   }));
  //   return custA;
  // };

  if (!gift_card.data) {
    return (
      <Row>
        <Col lg={9} sm={6} xs={12} className="dash-head">
          Updating your data...
        </Col>
        <React.Fragment>
          <div className="page-content">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <Card.Title className="h4 mb-2 ">
                      Please Hold on...{" "}
                    </Card.Title>
                    <div style={{ height: "60vh", display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <TailSpin
                          color="orange"
                          height={50}
                          width={50}
                          className="mx-auto my-auto"
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      </Row>
    );
  }
  const style = {
    position: "fixed",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div className="page-content ">
      {gift_card && gift_card?.data ? (
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <Card.Title className="h4 mb-4 my-auto">
                    Update Gift Card Data
                  </Card.Title>
                </div>
                <form onSubmit={handleUpdateGiftCard}>
                  <Row className="mb-2">
                    <Col>
                      <label></label>
                      <div
                        className=""
                        // style={{ width: "20rem" }}
                      >
                        <Async
                          defaultOptions={[]}
                          value={custArray}
                          cacheOptions={[{ label: "1234", value: "1234" }]}
                          isLoading={isLoading}
                          loadOptions={(e) => loadData(e)}
                          onChange={(e) => setCustArray(e)}
                          isMulti={true}
                        />
                      </div>
                    </Col>
                    <Col>
                      <label>Title</label>
                      <input
                        onChange={(e) =>
                          onChangeFields("title", e.target.value)
                        }
                        type="text"
                        className="form-control"
                        id="title"
                        value={data?.title}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Description</label>
                      <input
                        onChange={(e) =>
                          onChangeFields("description", e.target.value)
                        }
                        type="text"
                        className="form-control"
                        id="description"
                        value={data?.description}
                        required
                      />
                    </Col>
                    <Col>
                      <label>Created By</label>
                      <input
                        onChange={(e) =>
                          onChangeFields("created_by", e.target.value)
                        }
                        type="text"
                        className="form-control"
                        id="created_by"
                        value={data?.created_by}
                        required
                      />
                    </Col>
                    <Col>
                      <label>Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount"
                        value={data?.amount}
                        onChange={(e) =>
                          onChangeFields("amount", e.target.value)
                        }
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Status:</label>
                      <Select
                        value={{
                          label: String(data?.status),
                          value: data?.status,
                        }}
                        options={[
                          { label: "True", value: true },
                          { label: "False", value: false },
                        ]}
                        onChange={(e) => {
                          console.log(e.value, "edits");
                          setData({ ...data, status: e.value });
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </Row>

                  <Row className="d-flex">
                    <div className="d-flex">
                      <Button
                        className="btn btn-warning ml-auto"
                        style={{ marginLeft: "auto" }}
                        onClick={handleUpdateGiftCard}
                      >
                        Update Gift Card
                      </Button>
                    </div>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <div style={style}>
          {" "}
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default EditGiftCard;
