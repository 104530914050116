import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import WCHPagination from "./WCHPagination";
import { CSVLink } from "react-csv";
import Async from "react-select/async";
import { getWalletCreditHistory } from "./../../../store/actionCreators/Reports/PaymentReportsAction";
import axios from "axios";
import ScrollButton from "../../scrolltotop/ScrollButton";
import Loader from "../../Loader/LoaderTable";
import { toast } from "react-toastify";

const WalletCreditHistory = (props) => {
  const dispatch = useDispatch();

  const branch = useSelector((state) => state.branch);
  const page = useSelector((state) => state.sbpmd_page);

  const WCHReport = useSelector((state) => state.wallet_credit_history);

  const [RecordPage, setRecordPage] = useState(1);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [records, setRecords] = useState(100);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });

  const [query, setQuery] = useState({
    customer_no: "All",
    employee_id: "All",
    branch_id: "All",
    payment_method: "All",
    add_from: "All",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });
  const [empSelected, setEmpSelected] = useState({
    label: "All",
    value: "All",
  });
  const [custSelected, setCustSelected] = useState({
    label: "All",
    value: "All",
  });
  const [CreditFromSelected, setCreditFromSelected] = useState({
    label: "All",
    value: "All",
  });
  const [paymentMethodSelected, setPaymentMethodSelected] = useState({
    label: "All",
    value: "All",
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    console.log({ query, RecordPage, records }, "chck");
    dispatch(getWalletCreditHistory(query, RecordPage, records));
  }, [RecordPage, records]);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  useEffect(() => {
    if (WCHReport.data && WCHReport.data.status === "success") {
      setisDataFetched(true);
      const productDataClone = WCHReport.data.data.map((e) => {
        return {
          ...e,
          customer_name: e.customer?.first_name,
          branch_name: e.branch_name,
          createdAt: new Date(e.createdAt).toLocaleString(),
        };
      });
      setProductData(productDataClone);
    } else if (WCHReport?.data?.status === "failure") {
      setProductData([]);
      setisDataFetched(true);
    }
  }, [WCHReport]);

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };
  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_cust?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.mobile_no}`,
          value: e.mobile_no,
        }));
        // data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const loadDataEmp = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.full_name}`,
          value: e.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const onShowReport = () => {
    setProductData([]);
    setRecordPage(1);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.employee_id = empSelected.value;
    queryClone.customer_no = custSelected.value;
    dispatch(getWalletCreditHistory(queryClone, RecordPage, records));
    setShowReportClicked(true);
  };

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;
  queryClone.customer_no = custSelected.value;
  queryClone.employee_id = empSelected.value;

  const exportWCHCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_wallet_transaction_report`,
        queryClone
      );

      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  const columns = [
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: true,
    },
    {
      dataField: "customer_no",
      text: "Customer Number",
      sort: true,
    },

    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "add_from",
      text: "Credit From",
      sort: true,
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      sort: true,
    },

    {
      dataField: "paid",
      text: "Paid",
      sort: true,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch Name",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "promo_code_title",
      text: "Promo Code",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "branch_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 100,
    totalSize: WCHReport.data?.total_count, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const paymentMethodOptions = [
    { label: "All", value: "All" },

    { label: "card", value: "card" },
    { label: "cash", value: "cash" },
    { label: "Paytm", value: "Paytm" },
  ];
  const creditFromOptions = [
    { label: "All", value: "All" },

    { label: "card", value: "card" },
    { label: "cash", value: "cash" },
    { label: "Paytm", value: "Paytm" },
  ];

  return (
    // <Container
    //   fluid
    //   className={props.sideToggle === true ? "closeDash" : "openDash"}
    // style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    // >
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">WALLET CREDIT HISTORY</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Customer No:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "", value: "" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={custSelected}
                          onChange={(e) => setCustSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadDataEmp(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Method:</td>
                      <td className="">
                        <Select
                          options={paymentMethodOptions}
                          value={{
                            label: query.payment_method,
                            value: query.payment_method,
                          }}
                          onChange={(e) =>
                            setQuery({ ...query, payment_method: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Credit From:</td>
                      <td className="">
                        <Select
                          options={creditFromOptions}
                          value={{
                            label: query.add_from,
                            value: query.add_from,
                          }}
                          onChange={(e) =>
                            setQuery({ ...query, add_from: e.value })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "xls", value: "xls" }]}
                value={{ label: "xls", value: "xls" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportWCHCSV();
                }}
              >
                {/* <CSVLink
                  data={productData.map((p) => {
                    return {
                      Branch: p.branch_name,
                      Date: p.date,
                      Sales_Total: p.paid_price,
                      Payment_method: p.payment_method,
                      Order_Count: p.order_count,
                    };
                  })}
                  filename={"report.csv"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Export
                </CSVLink> */}
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Card
                                style={{
                                  background: "transparent",
                                  width: "fit-content",
                                  color: "#8d8d8d",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderRadius: "5px",
                                }}
                              >
                                <Card.Body>
                                  <Row>
                                    <Col>
                                      {" "}
                                      <strong> TOTAL CREDIT : </strong>
                                      <span className="text-capitalize">
                                        {WCHReport?.data?.total_credit
                                          ? WCHReport?.data?.total_credit
                                          : "---"}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {!productData.length > 0 &&
                                  WCHReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {WCHReport?.data?.status === "failure" &&
                                    !WCHReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <WCHPagination
                                  pageNum={Math.ceil(
                                    WCHReport.data
                                      ? WCHReport.data.total_count / records
                                      : 1
                                  )}
                                  page={RecordPage}
                                  setRecordPage={setRecordPage}
                                />
                              </Col>
                              <Col className="d-flex">
                                <div style={{ marginLeft: "auto" }}>
                                  <Select
                                    options={[
                                      { label: "100", value: "100" },
                                      { label: "500", value: "500" },
                                      { label: "1000", value: "1000" },
                                    ]}
                                    onChange={(e) => {
                                      setRecordPage(1);
                                      dispatch(
                                        getWalletCreditHistory(
                                          query,
                                          e.value,
                                          RecordPage
                                        )
                                      );
                                      setRecords(e.value);
                                    }}
                                    className="ml-auto"
                                    value={{ label: records, value: records }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default WalletCreditHistory;
