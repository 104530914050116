import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import Unauthorized from "../../unauthorized";
import {
  deleteQrCode,
  get_all_qrcodes,
} from "./../../../store/actionCreators/Catalog/Catalog";
import AddQrModal from "./AddQrModal";
import { ReactMultiselectCheckboxes } from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import ViewQrModal from "./ViewQrModal";
import EditQrModal from "./EditQrModal";

const QRCode = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const qrcodes = useSelector((state) => state.qrcodes);
  const [item, setItem] = useState({});
  const [addqrmodal, setAddqrmodal] = useState(false);
  const [viewqrmodal, setViewqrmodal] = useState(false);
  const [single_qr_code_id, setSingle_qr_code_id] = useState("");
  const [editqrmodal, setEditqrmodal] = useState(false);

  useEffect(() => {
    dispatch(get_all_qrcodes());
  }, []);

  useEffect(() => {
    console.log("qrcodes", qrcodes);
    if (qrcodes.data && qrcodes.data.data) setProductData(qrcodes.data.data);
  }, [qrcodes]);

  const [productData, setProductData] = useState([]);

  function rankFormatter1(cell, row, rowIndex, formatExtraData) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <a href={row.url} download="qrcode">
            <Button className="btn btn-sm btn-warning me-2">Download</Button>
          </a>
        </div>
      );
    }
  }

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            className="btn btn-sm btn-warning me-2"
            onClick={() => {
              setSingle_qr_code_id(row.qr_code_id);
              setEditqrmodal(true);
            }}
          >
            Edit
          </Button>

          <Button
            className="btn btn-sm btn-warning"
            onClick={() => {
              setSingle_qr_code_id(row.qr_code_id);
              setViewqrmodal(true);
            }}
          >
            View
          </Button>
          <Button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => {
              const del = window.confirm(
                "Are you sure you want to delete this qrcode?"
              );
              if (del) {
                console.log({ qr_code_id: row.qr_code_id });
                dispatch(deleteQrCode({ qr_code_id: row.qr_code_id }));
              }
            }}
          >
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button className="btn btn-sm btn-warning">View</Button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "table_name",
      text: "Table Name",
      sort: true,
    },
    {
      dataField: "view",
      text: "Download",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter1,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "table_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;
  const main = () => {
    if (props.viewPermission) {
      return (
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <ViewQrModal
            show={viewqrmodal}
            handleClose={() => setViewqrmodal(false)}
            qr_code_id={single_qr_code_id}
          />
          <AddQrModal
            show={addqrmodal}
            handleClose={() => setAddqrmodal(false)}
            item={item}
          />
          <EditQrModal
            show={editqrmodal}
            handleClose={() => setEditqrmodal(false)}
            qr_code_id={single_qr_code_id}
          />
          <Row>
            <div className="page-content ">
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <Card.Title className="d-flex mb-2 ">
                        <div>
                          <h4>QRCode Datatable</h4>
                        </div>
                        <div style={{ marginLeft: "auto" }} className="ml-auto">
                          <Button
                            className="btn btn-warning"
                            onClick={() => setAddqrmodal(true)}
                          >
                            Add New QRCode
                          </Button>
                        </div>
                      </Card.Title>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="category_list_id"
                        columns={columns}
                        data={productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="category_list_id"
                            columns={columns}
                            data={productData}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="search-box chat-search-box" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="category_list_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                        className="table-pagination"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      );
    }
    return <Unauthorized />;
  };
  return <React.Fragment>{main()}</React.Fragment>;
};

export default QRCode;
