import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerVars } from "../../store/actionCreators/Customers/CustomerAction";
import { clearDashBoard } from "../../store/actionCreators/dashboard/dasboardActions";
import StaffReports from "./Staff/StaffReports";
import AllReports from "./AllReports";
import OrderReports from "./Orders/OrderReports";
import LocationReport from "./Location/LocationReport";
import PaymentReport from "./Payment/PaymentReport";
import CustomerReport from "./Customer/CustomerReport";
import ItemReport from "./Item Related report/ItemReport";
import WalletReport from "./Wallet report/WalletReport";
import SingleEmployeeLogoutReport from "./SingleEmployeeLogoutReport";
import SalesReports from "./Sales/SalesReport";
import ExportReport from "./Export Report/ExportReport";

const ReportDashboard = (props) => {
  const branch = useSelector((state) => state.branch);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [branchObj, setBranchObj] = useState({});
  const [branchArray, setBranchArray] = useState([]);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const convertArrayToObj = () => {
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
      setBranchArray(branch.data.data);
    }
  };

  useEffect(() => {
    editPermissions();
    convertArrayToObj();
  }, [branch]);

  useEffect(() => {
    dispatch(setCustomerVars());
    dispatch(clearDashBoard());
  }, [viewPermission, editPermission]);

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "Reports") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <AllReports
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/staff/*"
          element={
            <StaffReports
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/orders/*"
          element={
            <OrderReports
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/item/*"
          element={
            <ItemReport
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/sales/*"
          element={
            <SalesReports
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/customer/*"
          element={
            <CustomerReport
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchObj={branchObj}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/wallet/*"
          element={
            <WalletReport
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/exports/*"
          element={
            <ExportReport
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/staff/stafflogoutreport/:report"
          element={
            <SingleEmployeeLogoutReport
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default ReportDashboard;
