import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewQrCode,
  addQrBranch,
  deleteQrBranch,
  get_single_qrcode,
} from "./../../../store/actionCreators/Catalog/Catalog";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import { getBranches } from "./../../../store/actionCreators/Branch/BranchAction";
import ReactSelect from "react-select";

const ViewQrModal = ({ show, handleClose, qr_code_id }) => {
  const [item, setItem] = useState("");
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedBranch, setSelected] = useState([]);
  const branch = useSelector((state) => state.branch);
  const singleqr = useSelector((state) => state.single_qrcode);
  const [branchObj, setBranchObj] = useState({});
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [associatedBranchObj, setAssociatedBranchObj] = useState({});

  const convertArrayToObj = () => {
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
    }
  };

  useEffect(() => {
    convertArrayToObj();
  }, [branch]);
  useEffect(() => {
    dispatch(get_single_qrcode(qr_code_id));
  }, [qr_code_id]);
  useEffect(() => {
    dispatch(getBranches());
  }, []);

  useEffect(() => {
    if (singleqr.data && singleqr.data.data) {
      setItem(singleqr.data.data);
      const branches = singleqr.data.data.qr_code_branches;
      const obj = {};
      branches.forEach((element) => {
        obj[element.branch_id] = true;
      });
      setAssociatedBranchObj(obj);
    }
  }, [singleqr]);

  useEffect(() => {
    const array = [];
    if (branch.data) {
      branch.data.data.map((item, index) => {
        array.push({ label: item.branch_name, value: item.branch_id });
      });
    }
    setOptions(array);
  }, [branch]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    return `${placeholderButtonLabel}: ${value.length} selected`;
  }

  const displayBranch = () => {
    if (options) {
      const newOptions = options
        ? options.filter((op) => associatedBranchObj[op.value] !== true)
        : [];

      return (
        <div className="categorySelect">
          <div className="categorySelect">
            <ReactSelect
              options={[...newOptions]}
              placeholderButtonLabel="Branches"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={selectedBranch}
              onChange={(e) => setSelected(e)}
              setState={setSelected}
              required
            />
          </div>
        </div>
      );
    }
  };

  const addBranch = () => {
    if (selectedBranch.value)
      dispatch(addQrBranch({ branch_id: selectedBranch.value, qr_code_id }));
  };

  return (
    <React.Fragment>
      (
      <Modal
        className="remove-border"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className=" bg-light text-dark remove-border" closeButton>
          <Modal.Title>{item.table_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" bg-light remove-border">
          <div className="mt-0 d-flex">
            <img
              className="mx-auto "
              src={item.url}
              height="170px"
              width="170px"
            />
          </div>
          <div className="d-flex">
            <div className="mx-auto ">
              <Button
                className="btn btn-warning px-2 py-1"
                onClick={() => setShowBranchModal(true)}
              >
                Show Branches
              </Button>
              <Modal
                show={showBranchModal}
                onHide={() => setShowBranchModal(false)}
                centered
              >
                <Modal.Header
                  className=" bg-light text-dark remove-border"
                  closeButton
                >
                  <Modal.Title>View Associated Branches</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <table bordered={false} width={"100%"}>
                    {item.qr_code_branches?.map((br) => (
                      <tr>
                        <td>{branchObj[br.branch_id]}</td>
                        <td className="d-flex">
                          <Button
                            style={{ marginLeft: "auto" }}
                            className="ml-auto btn btn-danger btn-sm"
                            onClick={() => {
                              const del = window.confirm(
                                "Are you sure you want to delete this branch ?"
                              );
                              if (del) {
                                dispatch(
                                  deleteQrBranch({
                                    id: br.id,
                                  })
                                );
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                  <div className="mt-2 d-flex">
                    <div className="mx-auto d-flex">
                      {displayBranch()}
                      <Button
                        className="btn btn-warning"
                        style={{ marginLeft: "1rem" }}
                        onClick={addBranch}
                      >
                        Add Branch(es)
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <a href={item.url} download="qrcode" className="">
                <Button
                  className="btn btn-warning px-2 py-1 ml-2"
                  style={{ marginLeft: "1rem" }}
                >
                  Download
                </Button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      )
    </React.Fragment>
  );
};

export default ViewQrModal;
