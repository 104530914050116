import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Badge, Button, Card } from "react-bootstrap";
import OrderIcon from "../../../assets/images/order-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../../../store/actionCreators/Orders/OrdersAction";

const OrderModal = ({ show, close, order_id }) => {
  console.log("error", order_id);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getSingleOrder(order_id));
  }, [order_id]);

  useEffect(() => {
    if (order.data) setData(order.data);
  }, [order]);

  const orderStatus = {
    Preparing: "green",
    Hold: "yellow",
    Completed: "dark-green",
    Cancelled: "red",
  };

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Order data</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <Row>
          <Col>
            <Card bg="light-blue" className="mb-2">
              <Card.Body>
                <Row>
                  <Col className="">
                    <div style={{}} className="my-auto h5 d-flex mx-auto">
                      <span style={{ color: "dark-grey", marginLeft: "auto" }}>
                        ORDER:
                      </span>{" "}
                      <span style={{ marginRight: "auto" }}>
                        {data.order_id}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card bg="light-blue" className="mb-2">
          <Card.Body>
            <Row>
              <Col className="d-flex" xl="4">
                <div className="my-auto h4">₹{data.paid_price}</div>
              </Col>
              <Col xl="8">
                <Row>
                  <Col>
                    <div className="d-flex ">
                      <div
                        className="ml-auto"
                        style={{
                          marginLeft: "auto",
                          marginRight: "0.2rem",
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        Status:
                      </div>
                      <div
                        style={{
                          color: orderStatus[data.status],
                          fontWeight: "bold",
                        }}
                      >
                        {data.status}
                      </div>{" "}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div
                        className="ml-auto"
                        style={{
                          marginLeft: "auto",
                          marginRight: "0.2rem",
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        Order Date:
                      </div>
                      <div>
                        {new Date(data.createdAt).toLocaleString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </div>{" "}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div
                        className="ml-auto"
                        style={{
                          marginLeft: "auto",
                          marginRight: "0.2rem",
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        Estimated Time:
                      </div>
                      <div>10 min</div>{" "}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div
                        className="ml-auto"
                        style={{
                          marginLeft: "auto",
                          marginRight: "0.2rem",
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        Membership Name:
                      </div>
                      <div>{data.membership_name}</div>{" "}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row className="mb-2">
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div style={{ fontSize: "0.8rem" }}>
                      <div>
                        <strong>NAME</strong>
                      </div>
                      <div>
                        <strong style={{ color: "grey" }}>
                          {data.customer_name}
                        </strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  {" "}
                  <Col>
                    <div style={{ fontSize: "0.8rem" }}>
                      <div>
                        <strong>ADDRESS</strong>
                      </div>
                      <div>
                        <strong style={{ color: "grey" }}>
                          {data.address}
                        </strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <div style={{ fontSize: "0.8rem" }}>
                      <div>
                        <strong>PAYMENT METHOD</strong>
                      </div>
                      <div>
                        <strong style={{ color: "grey" }}>
                          {data.payment_method}
                        </strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <div className="d-flex" style={{ fontSize: "0.8rem" }}>
                      <div className="d-block">
                        <div>
                          <strong>ORDER TYPE</strong>
                        </div>
                        <div>
                          <strong style={{ color: "grey" }}>
                            {data.order_type}
                          </strong>
                        </div>
                      </div>
                      <div className="d-block" style={{ marginLeft: "auto" }}>
                        <div>
                          <strong>DELIVERY CHARGES</strong>
                        </div>
                        <div>
                          <strong style={{ color: "grey" }}>
                            {data.delivery_charges}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h6>ORDER ITEMS</h6>
          </Col>
        </Row>
        {!!data.order_items &&
          data.order_items?.map((oi) => (
            <Row className="mb-2">
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-flex">
                      <div style={{ marginRight: "0.6rem" }}>
                        <img
                          style={{ height: "3.5rem", width: "3.5rem" }}
                          src={OrderIcon}
                        />
                      </div>
                      <div style={{ fontSize: "0.8rem" }}>
                        <div>
                          <strong>
                            {oi.product_name} x {oi.quantity}
                          </strong>
                        </div>
                        <div>
                          <strong style={{ color: "grey" }}>
                            Food Type: {oi.food_type}
                          </strong>
                        </div>
                        <div>
                          <strong style={{ color: "grey" }}>
                            {" "}
                            {oi.add_ons !== null &&
                              Object.keys(oi.add_ons)
                                .map((k) => oi.add_ons[k][0].value)
                                .join(", ")}
                          </strong>
                        </div>
                      </div>
                      <div
                        className="my-auto h5 "
                        style={{ marginLeft: "auto", color: "grey" }}
                      >
                        {oi.total_price}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}

        <Row className="mb-2">
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>SubTotal</div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.sub_total}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>Discount</div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.discount}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>Membership Discount</div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.membership_discount}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>SGST</div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.sgst}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>CGST</div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.cgst}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>
                        <strong>Total</strong>
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          // color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.paid_price}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div>Change</div>
                      <div
                        style={{
                          marginLeft: "auto",
                          fontWeight: "bold",
                          color: "grey",
                        }}
                      >
                        {" "}
                        ₹{data.change}
                      </div>
                    </div>
                  </Col>
                </Row>{" "}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default OrderModal;
