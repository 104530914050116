import Table from "react-bootstrap/Table";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Container } from "react-bootstrap";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllCompany } from "../../store/actionCreators/Investors/InvestorsAction";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import DownLoadPreview from "./DownloadPreview";
import Pdf from "react-to-pdf";

function InvestorMailPreview(props) {
  const [processMail, setProcessMail] = useState(false);
  const ref = React.createRef();
  var d = new Date();
  d = new Date(d.getFullYear(), d.getMonth() + 1, 10);

  const nextMonth = d.toLocaleDateString();

  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const company = useSelector((state) => state.allCompany);
  const companyD = company?.data?.data;
  const dispatch = useDispatch();
  console.log(companyD, "5555");
  console.log(data, "5555");
  const handlePrint = useReactToPrint({
    content: () => componentref.current,
  });
  const componentref = useRef();

  useEffect(() => {
    dispatch(getAllCompany());
  }, []);

  // jspdf things

  const inputRef = useRef(null);
  const printDocument = () => {
    inputRef.current.classList.remove("d-none");
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
      inputRef.current.classList.add("d-none");
    });
  };

  const sendInvestorMail = async () => {
    setProcessMail(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/mail_report`,
        {
          mail: data.email,
          pay_period: data?.date,
          percentage_cut: data?.percentage_cut,
          sale_punched: data?.sale,
          gst: data?.gst,
          sale_after_tax: data?.sale_after_tax,
          commission: data?.commission,
          tds: data?.tds,
          new_pay_amt: data?.net_payable,
          comp: companyD?.name,
          comp_contact: companyD?.contact,
          gst_numb: companyD?.gst_no,
          contact: data.contact,
          investor_name: data?.name,
          banking_details: data?.bank,
          location: data?.branch_name,
          ifsc: data?.ifsc,
          acc_no: data?.account_no,
          due_date: nextMonth,
          investor_id: data.investor_id,
          branch_id: data.branch_id,
        }
      );
      if (response && response.data?.status === "success") {
        setProcessMail(false);
        toast.success(`Mail Sent Successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(` ERROR`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error, "oooo");
    }
  };

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <button
        className="btn btn-primary my-3 mx-2"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
      <Pdf targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) => (
          <button className="btn btn-primary" onClick={toPdf}>
            Generate Pdf
          </button>
        )}
      </Pdf>
      <button className="btn btn-primary mx-2 my-3" onClick={sendInvestorMail}>
        Mail Report {processMail && <Spinner animation="border" size="sm" />}
      </button>

      <DownLoadPreview componentref={ref} data={data} companyD={companyD} />

      {/* <button className="btn btn-primary my-3" onClick={handlePrint}>
     
      </button> */}
      {/* <button className="btn btn-primary mx-2 my-3 " onClick={printDocument}>
        Download Report{" "}
      </button> */}

      {/* <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Pay Period</th>
              <th>Percentage Cut</th>

              <th>Sale Punched</th>
              <th>GST</th>
              <th>Sale After Tax</th>
              <th>Commission</th>
              <th>TDS</th>
              <th>New Payable Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.date}</td>
              <td>{data?.percentage_cut}</td>

              <td>{data?.sale}</td>
              <td>{data?.gst}</td>
              <td>{data?.sale_after_tax}</td>
              <td>{data?.commission}</td>
              <td>{data?.tds}</td>
              <td>{data?.net_payable}</td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Company</th>
              <th>GST Number</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{companyD?.name}</td>
              <td>{companyD?.gst_no}</td>
              <td>{companyD?.contact}</td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Investor Name</th>
              <th>Contact</th>
              <th>Banking Details:</th>
              <th>Location</th>
              <th>IFSC</th>
              <th>Account no.</th>

              <th>Due Date of Credit:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.name}</td>

              <td>{data?.contact}</td>

              <td>{data?.bank}</td>
              <td>{data?.branch_name}</td>
              <td>{data?.ifsc}</td>
              <td>{data?.account_no}</td>

              <td>{nextMonth}</td>
            </tr>
          </tbody>
        </Table>
      </div> */}
    </Container>
  );
}

export default InvestorMailPreview;
