import axios from "axios";
import { toast } from "react-toastify";

export const fetchAllNotification = (page) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/notifications/fetch_all_notifications/${page}`
      )
      .then((notifications) => {
        dispatch({
          type: "GET_ALL_NOTIFICATIONS",
          notifications,
        });
      });
  };
};

export const getAllNotificationBranches = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/notifications/fetch_all_branches`
      )
      .then((branches) => {
        dispatch({
          type: "GET_ALL_NOTIFICATION_BRANCHES",
          notificationAllbranches: branches,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setNotificationListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_NOTIFICATION_LIST_PAGINATION",
      page,
    });
  };
};

export const getSingleNotification = (notifications_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/notifications/fetch_single_notification?notifications_id=${notifications_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_NOTIFICATION",
          singleNotification:res,
        });
      });
  };
};