import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../css/catalog/editCategory.css";
import Select from "react-select";
import {
  editEmployeeDetails,
  fetchSingleEmployee,
} from "../../store/actionCreators/Employees/EmployeeAction";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";

const EditEmployeeModal = ({ show, close, data }) => {
  const role = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const [empData, setEmpData] = useState({});
  const [pwd, setPwd] = useState("");
  const branch = useSelector((state) => state.branch);
  const [selectedBranches, setSelected] = useState([]);
  const [changePwd, setChangePwd] = useState(false);
  const [branchObj, setBranchObj] = useState({});
  const [branchArray, setBranchArray] = useState([]);
  const [options, setOptions] = useState([]);

  console.log(
    data.employee_login_branches,
    { selectedBranches, data: data.branch, branchid: data.branch_id },
    "predef"
  );

  const convertArrayToObj = () => {
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
      setBranchArray(branch.data.data);
    }
  };

  function capitalize(s) {
    if (s && s.length > 0) return s[0].toUpperCase() + s.slice(1);
  }

  useEffect(() => {
    convertArrayToObj();
  }, [branch]);

  useEffect(() => {
    setEmpData(data);
    setSelected(
      (data?.employee_login_branches || []).map((c) => ({
        label: `${c.branch_name} `,
        value: c.branch_id,
      }))
    );
  }, [data]);

  const onChangeFields = (field_name, value) => {
    const empDataClone = { ...empData };
    empDataClone[field_name] = value;
    setEmpData(empDataClone);
  };

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  useEffect(() => {
    let array = [];
    if (branch.data) {
      array = branch.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [branch]);

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value?.length} selected`;
    }
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    let branch_id = "";

    for (let i = 0; i < selectedBranches.length; i++) {
      branch_id += `${selectedBranches[i].value},`;
    }
    if (branch_id[0] === "*") {
      branch_id = branch_id.slice(2, branch_id.length);
    }
    branch_id = branch_id.slice(0, branch_id.length - 1);

    const {
      full_name,
      email,
      mobile_no,
      date_of_birth,
      address,
      status,
      gender,

      employee_role,
      employee_role_id,
      employee_id,
    } = empData;
    const empDataClone = {
      full_name,
      email,
      mobile_no,
      date_of_birth,
      address,
      status,
      gender,
      branch_id,
      employee_role,
      employee_role_id,
    };
    if (changePwd) empDataClone.password = pwd;
    console.log(empDataClone);
    editEmployeeDetails(empDataClone, employee_id).then((res) => {
      dispatch(fetchSingleEmployee(employee_id));
    });
  };

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitForm}>
          <Row>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Full Name</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={capitalize(empData.full_name)}
                    onChange={(e) => {
                      onChangeFields("full_name", capitalize(e.target.value));
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Mobile Number</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={empData.mobile_no}
                    onChange={(e) =>
                      onChangeFields("mobile_no", e.target.value)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    value={empData.date_of_birth}
                    onChange={(e) =>
                      onChangeFields("date_of_birth", e.target.value)
                    }
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Change password ?</label>
                  <Form.Check
                    type="checkbox"
                    checked={changePwd}
                    onChange={() => {
                      setChangePwd(!changePwd);
                      setPwd("");
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Password</label>
                  <input
                    required
                    type="password"
                    className="form-control"
                    disabled={!changePwd}
                    onChange={(e) => setPwd(e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    value={empData.email}
                    onChange={(e) => onChangeFields("email", e.target.value)}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Address</label>
                  <input
                    required
                    type="text-area"
                    className="form-control"
                    value={empData.address}
                    onChange={(e) => onChangeFields("address", e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Status</label>
                  <Select
                    options={[
                      { label: "Active", value: "Active" },
                      { label: "Inactive", value: "Inactive" },
                    ]}
                    value={{ label: empData.status, value: empData.status }}
                    onChange={(e) => onChangeFields("status", e.value)}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Gender</label>
                  <Select
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                    ]}
                    value={{
                      label: capitalize(empData.gender),
                      value: empData.gender,
                    }}
                    onChange={(e) => onChangeFields("gender", e.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Branch</label>
                  {/* <Select
                    options={branchArray.map((b) => ({
                      label: b.branch_name,
                      value: b.branch_id,
                    }))}
                    value={{
                      label: branchObj[empData.branch_id],
                      value: empData.branch_id,
                    }}
                    onChange={(e) => onChangeFields("branch_id", e.value)}
                  /> */}
                  {displayBranch()}
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ maxHeight: "60vh" }}>
                <div className="mb-2">
                  <label>Employee Role</label>
                  <Select
                    options={[
                      { label: "manager", value: "manager" },
                      { label: "branch head", value: "branch head" },
                      { label: "region head", value: "region head" },
                      { label: "barista", value: "barista" },
                      { label: "kitchen", value: "kitchen" },
                      { label: "server", value: "server" },
                    ]}
                    value={{
                      label: capitalize(empData.employee_role),
                      value: empData.employee_role_id,
                    }}
                    onChange={(e) => {
                      console.log(e);
                      const empDataClone = { ...empData };
                      empDataClone["employee_role"] = e.label;
                      empDataClone["employee_role_id"] = e.value;
                      setEmpData(empDataClone);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <Button
              disabled={selectedBranches?.length === 0}
              className="btn btn-warning"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditEmployeeModal;
