import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import AddOrEditVariantModal from "./AddOrEditVariantModal";

const AddOptionsAndValueVariants = () => {
  const [formData, setFormData] = useState([{ textFieldValue: "", tags: [] }]);
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleTagChange = (index, tags) => {
    const updatedFormData = [...formData];
    updatedFormData[index].tags = tags;
    setFormData(updatedFormData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({ formData });
  };

  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setIsTextFieldFocused(false);
  };

  const handleTextFieldChange = (index, e) => {
    const updatedFormData = [...formData];
    updatedFormData[index].textFieldValue = e.target.value;
    setFormData(updatedFormData);
  };

  const handleAddField = () => {
    setFormData([...formData, { textFieldValue: "", tags: [] }]);
  };

  const handleRemoveField = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Row>
      <Col className="col-12 mb-5">
        <Card>
          <Card.Body style={{ padding: "40px" }}>
            <Card.Title className="h4 mb-2">
              Add Options & Customize Variants
            </Card.Title>
            <Form onSubmit={handleFormSubmit}>
              {formData.map((field, index) => (
                <Form.Group as={Row} key={index}>
                  <Form.Label column sm={2}>
                    Options[{index + 1}]:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      placeholder="Enter options"
                      value={field.textFieldValue}
                      onChange={(e) => handleTextFieldChange(index, e)}
                      required
                      onFocus={handleTextFieldFocus}
                      onBlur={handleTextFieldBlur}
                      style={{
                        borderBottom: isTextFieldFocused
                          ? "1px solid green"
                          : "1px solid #ced4da",
                      }}
                    />
                    <Form.Text className="text-muted">
                      E.g. size,color (use small case)
                    </Form.Text>
                  </Col>
                  <Col sm={4}>
                    <TagsInput
                      value={field.tags}
                      placeholder="Enter values"
                      onChange={(tags) => handleTagChange(index, tags)}
                    />
                    <Form.Text className="text-muted">
                      E.g. Small, Medium, Large
                    </Form.Text>
                  </Col>

                  {index > 0 && (
                    <Col sm={2}>
                      <Button
                        variant="link"
                        onClick={() => handleRemoveField(index)}
                      >
                        <RiDeleteBin6Line />
                      </Button>
                    </Col>
                  )}
                </Form.Group>
              ))}
              <Button variant="link" onClick={handleAddField}>
                + Add Field
              </Button>
              <Button variant="primary mt-2" type="submit">
                Add Options
              </Button>
            </Form>
            <Form className="m-2">
              <Form.Label column sm={2}>
                Variants:
              </Form.Label>
              {formData[0].textFieldValue && formData[0].tags.length !== 0 ? (
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={handleModalOpen}
                  column
                  sm={2}
                >
                  Add New Variants Manually
                </button>
              ) : (
                <span>Please add options to generate the variants!</span>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <AddOrEditVariantModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        options={formData.map((field) => field.textFieldValue)}
        tags={formData.flatMap((field) => field.tags)}
      />
    </Row>
  );
};

export default AddOptionsAndValueVariants;
