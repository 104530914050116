import axios from "axios";
import { toast } from "react-toastify";

export const fetchSingleCredit = (id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_single_credit?id=${id}`
      )
      .then((credit) => {
        dispatch({
          type: "GET_SINGLE_CREDIT",
          credit,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const fetchAllAvailableCredit = (page, storeID) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_credit/${page}`,
        {
          store_id: storeID,
          credit_status: true,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_AVAILABLE_CREDIT",
          availableCredit: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const fetchAllUsedCredit = (page, storeId) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/fetch_all_credit/${page}`,
        {
          store_id: storeId,
          credit_status: false,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_USED_CREDIT",
          usedCredit: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const updateInventoryCredit = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_invt_credit`,
      {
        credit_status: data.credit_status,
        id: data.id,
      }
    )
    .then((res) => {
      if (res.data.status === "success") {
        toast.success(`successFully updated`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(`${res.data.msg ?? "error"}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      return res;
    })
    .catch((err) => {
      console.log("error", err);
    });
};

export const setAvailableCreditListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_AVAILABLE_CREDIT_LIST_PAGINATION",
      page,
    });
  };
};

export const setUsedCreditListPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_USED_CREDIT_LIST_PAGINATION",
      page,
    });
  };
};
