export const fetchSalesByLocation = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_LOCATION":
      return state;
    case "SET_SALES_BY_LOCATION":
      return action.res;
    default:
      return state;
  }
};

export const SalesByLocationPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SBL_PAGE":
      return state;
    case "SET_SBL_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const fetchSalesByLocationDaily = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_LOCATION_DAILY":
      return state;
    case "SET_SALES_BY_LOCATION_DAILY":
      return action.res;
    default:
      return state;
  }
};

export const SalesByLocationDailyPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SBLD_PAGE":
      return state;
    case "SET_SBLD_PAGE":
      return action.page;
    default:
      return state;
  }
};
