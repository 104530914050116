import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleCredit } from "../../store/actionCreators/Credits/CreditActions";
import Select from "react-select";
import { BiArrowBack } from "react-icons/bi";
import { updateInventoryCredit } from "../../store/actionCreators/Credits/CreditActions";
import { useMemo } from "react";

const EditCredit = () => {
  const singleCredit = useSelector((state) => state.get_single_credit);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState({
    credit_status: "",
  });

  const [orderItemData, setOrderItemData] = useState([]);

  useEffect(() => {
    dispatch(fetchSingleCredit(params.id));
  }, []);

  useEffect(() => {
    const singleCreditData = singleCredit?.data?.data;
    setData(singleCreditData);

    setOrderItemData(
      singleCreditData?.purchase_order?.purchase_order_items || []
    );
  }, [singleCredit]);

  const allStatus = [
    { label: "Available", value: true },
    { label: "Used", value: false },
  ];

  const statusFc = useMemo(() => {
    let status = "";
    if (data?.credit_status === false) {
      status = "Used";
    } else if (data?.credit_status === true) {
      status = "Available";
    }
    return status;
  }, [data]);

  return (
    <div className="page-content ">
      <button
        className="btn btn-primary m-2"
        onClick={() => {
          navigate(-1);
        }}
      >
        {" "}
        <BiArrowBack className="mr-2" />
        Back
      </button>
      {singleCredit ? (
        <Row>
          <Col className="col-12 mb-5">
            <Card>
              <Card.Body style={{ padding: "40px" }}>
                <div className="d-flex">
                  <Card.Title className="h4 mb-4 my-auto">
                    Edit Credit Status
                  </Card.Title>
                </div>

                <div className="d-flex flex-column">
                  <table>
                    <th>Inventory Item ID:</th>
                    <th>Purchase Order Item ID:</th>
                    <th>Name:</th>
                    <th>Unit:</th>
                    <th>Quantity:</th>
                    <th>Price:</th>
                    <th>Total Tax:</th>
                    <th>Total Price:</th>
                    <th>Vendor Name:</th>
                    <th>Date:</th>
                    <th>Credit Status:</th>
                    <th>Action:</th>
                    <tr>
                      <td>{data?.inventory_items_id}</td>

                      <td>{data?.purchase_order_items_id}</td>

                      <td>{data?.name}</td>

                      <td>{data?.unit}</td>

                      <td>{data?.quantity}</td>
                      <td>{data?.price}</td>
                      <td>{data?.tax}</td>
                      <td>{data?.total_price}</td>
                      <td>{data?.vendor_name}</td>
                      <td>
                        {new Date(`${data?.createdAt}`).toLocaleString(
                          undefined,
                          {
                            timeZone: "Asia/Kolkata",
                          }
                        )}
                      </td>

                      <td>
                        <Select
                          value={{
                            label: statusFc,
                            value: data?.credit_status,
                          }}
                          options={allStatus.map((i) => {
                            return {
                              label: i.label,
                              value: i.value,
                            };
                          })}
                          onChange={(e) =>
                            setData({
                              ...data,
                              credit_status: e.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <Button
                          type="submit"
                          className="btn btn-warning"
                          onClick={() =>
                            updateInventoryCredit(data).then(() => {
                              dispatch(fetchSingleCredit(params.id));
                            })
                          }
                        >
                          Update
                        </Button>
                      </td>
                    </tr>
                  </table>{" "}
                </div>

                <br />
                <b className="mt-5">Purchase Order Items:</b>
                {orderItemData.length === 0 && (
                  <strong className="mx-3">EMPTY</strong>
                )}

                {orderItemData.map((data, i) => {
                  return (
                    <Card.Body key={i}>
                      <b className="fw-bold">
                        Order Id: {data.purchase_orders_id}
                      </b>
                      <hr className="my-1" />
                      <div className="d-flex flex-column">
                        <table>
                          <tr></tr>
                          <th>Name:</th>
                          <th>Unit:</th>
                          <th>Tax:</th>
                          <th>Total Price :</th>
                          <th>Vendor Name:</th>

                          <tr>
                            <td>{data?.name}</td>

                            <td>{data?.unit}</td>

                            <td>{data?.tax}</td>

                            <td>{data?.total_price}</td>

                            <td>{data?.vendor_name}</td>

                            <td>
                              <td>{data?.credit_status}</td>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </Card.Body>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <div>PLease Wait..</div>
      )}
    </div>
  );
};

export default EditCredit;
