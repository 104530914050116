import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Modal, Row, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  ItemsAvailable,
  get_category_branches,
} from "../../../store/actionCreators/Catalog/Catalog";
import { useParams } from "react-router-dom";

const EditItemAvailableModal = ({ show, close, data, currbranch }) => {
  const dispatch = useDispatch();
  const { product_id, items_available, price, status,product_list } = data;
  const basePrice = product_list?.price;

  const params = useParams();

  const [val, setVal] = useState({
    product_id,
    items_available,
    price,
    status,
  });

  useEffect(() => {
    setVal({
      product_id,
      items_available,
      price,
      status,
    });
  }, [data]);

  const onChangeFields = (name, value) => {
    const clone = { ...val };
    clone[name] = value;
    setVal(clone);
  };

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newObject = {
      ...val,
      price: parseInt(val.price),
      items_available: parseInt(val.items_available),
    };

    dispatch(ItemsAvailable(newObject)).then((res) => {
      if (res && currbranch.branch) {
        dispatch(get_category_branches(params.id, currbranch.branch.branch_id));
      }
    });
    closeModal();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product to category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Row>
              <div className="mb-2">
                <label>Items Available</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  value={val.items_available}
                  onChange={(e) =>
                    onChangeFields("items_available", e.target.value)
                  }
                />
              </div>
              <div className="mb-2">
                <label>Additional Price</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  value={val.price}
                  min={0}
                  onChange={(e) => onChangeFields("price", e.target.value)}
                />
              </div>
              <div className="mb-2">
                <label>Base Price</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  value={basePrice}
                  disabled
                />
              </div>
              <div className="mb-2">
                <label>Status</label>
                {/* <input
              required
              type="text"
              className="form-control"
              value={val.status}
              onChange={(e)=>onChangeFields("status",e.target.value)} 
            /> */}
                {val.status === true || val.status === "true" ? (
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => onChangeFields("status", e.target.value)}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Form.Select>
                ) : (
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => onChangeFields("status", e.target.value)}
                  >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                  </Form.Select>
                )}
              </div>
            </Row>
            <div>
              <Button className="btn btn-warning" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditItemAvailableModal;
