import React, { useEffect, useRef } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { Link, useNavigate } from "react-router-dom";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../css/customer/customerTable.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerTablePagination from "./CustomerTablePagination";
import { toast } from "react-toastify";
import { getAllCustomerRoles } from "../../store/actionCreators/Customers/CustomerRolesActions";

import AddCustomerToModal from "../Orders/AddCustomerToGroupModal";
import { fetchOnlyCustomerList } from "../../store/actionCreators/Customers/CustomerAction";
import { setCustomerPagination } from "../../store/actionCreators/Customers/CustomerAction";
import LoaderTable from "../Loader/LoaderTable";
import Select from "react-select";
import axios from "axios";
import Unauthorized from "../unauthorized";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";

const CustomerTable = ({ permission }) => {
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = useState(1);
  const page = useSelector((state) => state.mostOrderPage);
  const customer_roles = useSelector((state) => state.customer_roles);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const branch = useSelector((state) => state.branch);
  const [records, setRecords] = useState(10);
  const [productData, setProductData] = useState([]);

  // console.log(customer_roles.data.all_customer_roles, "rolesdata");

  const [addCustomerToGrpModal, setAddCustomerToGrpModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);

  const filterInputValues = {
    mobile_no: "",
    name: "",
    max_price: "",
    min_price: "",
    memb_upg_categ: "",
    customer_type: "",
    // created at
    start_date: "",
    end_date: "",
    birth_start_date: "",
    birth_end_date: "",
    memb_upg_amount_max: "",
    memb_upg_amount_min: "",
    memb_reduce_amount_max: "",
    memb_reduce_amount_min: "",
  };

  const [filters, setFilters] = useState(filterInputValues);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  const setDefaultData = () => {
    if (branch.data && branch.data?.data) {
      const branchArrClone = branch?.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };

  const showFilteredCustomers = (page) => {
    setLoading(true);
    setProductData([]);
    const queryString = getFilterQuery();
    dispatch(fetchOnlyCustomerList(page, queryString, records));
    dispatch(setCustomerPagination(page));
    setFilteredList(true);
  };

  const showFilteredCustomersBySearch = (page) => {
    // setProductData([]);
    const queryString = getFilterQuery();

    dispatch(fetchOnlyCustomerList(page, queryString, records));
    dispatch(setCustomerPagination(page));
    setFilteredList(true);
  };
  const [filteredList, setFilteredList] = useState(false);
  const [branchArr, setBranchArr] = useState(["All"]);
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });
  const customers = useSelector((state) => state.customersList);

  const pageOH = useSelector((state) => state.mostOrderPage);
  const filtered_customers = useSelector((state) => state.filtered_customers);

  const getFilterQuery = () => {
    let qs = "";

    if (filters.mobile_no !== "") {
      qs += "&";
      qs += `mobile_no=${filters.mobile_no}`;
    }
    if (filters.name !== "") {
      qs += "&";
      qs += `name=${filters.name}`;
    }
    if (filters.max_price !== "") {
      qs += "&";
      qs += `max_price=${filters.max_price}`;
    }
    if (filters.min_price !== "") {
      qs += "&";
      qs += `min_price=${filters.min_price}`;
    }
    if (filters.memb_upg_categ !== "") {
      qs += "&";
      qs += `memb_upg_categ=${filters.memb_upg_categ}`;
    }
    if (filters.customer_type !== "") {
      qs += "&";
      qs += `customer_type=${filters.customer_type}`;
    }
    if (filters.start_date !== "") {
      qs += "&";
      qs += `start_date=${filters.start_date}`;
    }
    if (filters.end_date !== "") {
      qs += "&";
      qs += `end_date=${filters.end_date}`;
    }
    if (filters.birth_start_date !== "") {
      qs += "&";
      qs += `birth_start_date=${filters.birth_start_date}`;
    }
    if (filters.birth_end_date !== "") {
      qs += "&";
      qs += `birth_end_date=${filters.birth_end_date}`;
    }
    if (filters.memb_upg_amount_max !== "") {
      qs += "&";
      qs += `memb_upg_amount_max=${filters.memb_upg_amount_max}`;
    }
    if (filters.memb_upg_amount_min !== "") {
      qs += "&";
      qs += `memb_upg_amount_min=${filters.memb_upg_amount_min}`;
    }
    if (filters.memb_reduce_amount_max !== "") {
      qs += "&";
      qs += `memb_reduce_amount_max=${filters.memb_reduce_amount_max}`;
    }
    if (filters.memb_reduce_amount_min !== "") {
      qs += "&";
      qs += `memb_reduce_amount_min=${filters.memb_reduce_amount_min}`;
    }
    if (branchSelected.value !== "") {
      qs += "&";
      qs += `branch_id=${branchSelected.value}`;
    }

    qs = qs.substring(1);
    return qs;
  };

  // console.log(customers, "customertablepage");

  const onChangeForSearchFields = (field, value) => {
    const filters_clone = { ...filters };
    filters_clone[field] = value;
    setFilters(filters_clone);
    // showFilteredCustomersBySearch(1);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      showFilteredCustomersBySearch(1);
    }, 200);
    return () => {
      clearTimeout(getData);
    };
  }, [filters]);

  useEffect(() => {
    getAllCustomerRoles();
  }, []);

  useEffect(() => {
    setLoading(false);
    let arrayCust = [];
    if (customers.data) {
      if (customers.data.status !== "failure") {
        setPageNum(Math.ceil(customers.data.total_customers / 10));
        for (let i = 0; i < customers.data.data.length; i++) {
          arrayCust.push({
            name: `${customers.data.data[i].first_name} ${customers.data.data[i].last_name
                ? customers.data.data[i].last_name
                : ""
              }`,
            telephone: customers.data.data[i].mobile_no,
            type: customers.data.data[i].customer_type,
            city: customers.data.data[i].branch,
            dob: customers.data.data[i].date_of_birth,
            // gender: customers.data.data[i].gender,
            mail: customers.data.data[i].email,
            upgradeAmount: customers.data.data[i].memb_upg_amount,
            total_spend: customers.data.data[i].total_spend,
            upgradeCategory: customers.data.data[i].memb_upg_categ,
            memb_reduce_amount: customers.data.data[i].memb_reduce_amount,
            createdAt: new Date(
              customers.data.data[i].createdAt
            ).toLocaleString(),
          });
        }

        // arrayCust.sort((a,b)=>{
        //   if(a.name<b.name){
        //     return -1;
        //   }
        //   else if(a.name>b.name)
        //   {
        //     return 1;
        //   }
        //   return 0;
        // });
        setProductData(arrayCust);
        console.log("arrayCust", arrayCust);
      }
    }
  }, [customers]);

  const onClickError = () => {
    toast.error(`No Contact Available`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (permission.editPermission) {
      if (row.telephone) {
        return (
          <div
            style={{
              cursor: "pointer",
              lineHeight: "normal",
            }}
          >
            <Link
              exact="true"
              to={`/customer/${row.telephone}`}
              // onClick={() => onClickFunction(rowIndex, row)}
              className="btn btn-sm btn-warning"
            >
              View
            </Link>
            <Link
              exact="true"
              to={`/customer/editCustomer/${row.telephone}`}
              className="btn btn-sm btn-warning"
              style={{ marginLeft: "0.6rem" }}
            >
              Edit
            </Link>
          </div>
        );
      } else {
        return (
          <div
            style={{
              cursor: "pointer",
              lineHeight: "normal",
            }}
          >
            <div
              exact="true"
              onClick={() => onClickError(rowIndex)}
              className="btn btn-sm btn-warning"
            >
              View
            </div>
            <div
              exact="true"
              onClick={() => onClickError(rowIndex)}
              className="btn btn-sm btn-warning"
              style={{ marginLeft: "0.6rem" }}
            >
              Edit
            </div>
          </div>
        );
      }
    } else {
      if (row.telephone) {
        return (
          <div
            style={{
              cursor: "pointer",
              lineHeight: "normal",
            }}
          >
            <Link
              exact="true"
              to="/customer/individual"
              // onClick={() => onClickFunction(rowIndex, row)}
              className="btn btn-sm btn-warning"
            >
              View
            </Link>
          </div>
        );
      } else {
        return (
          <div
            style={{
              cursor: "pointer",
              lineHeight: "normal",
            }}
          >
            <div
              exact="true"
              onClick={() => onClickError(rowIndex)}
              className="btn btn-sm btn-warning"
            >
              View
            </div>
          </div>
        );
      }
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
  }

  // const customerTypeArray = ["General", "silver", "Gold", "Platinum"];

  const customerDrop = () => {
    return customer_roles?.data?.all_customer_roles?.map((item, index) => {
      return (
        <option key={index} value={item.customer_type}>
          {item.customer_type}
        </option>
      );
    });
  };

  const searchBoxes = {
    name: (
      <input
        type="text"
        id="name"
        placeholder="search by name"
        value={filters.name}
        onChange={(e) => onChangeForSearchFields("name", e.target.value)}
      />
    ),

    telephone: (
      <input
        type="text"
        id="telephone"
        placeholder="search by Telephone"
        value={filters.mobile_no}
        onChange={(e) => onChangeForSearchFields("mobile_no", e.target.value)}

      />
    ),
    type: (
      <select
        className="form-control form-select form-select-sm"
        onChange={(e) => {
          // console.log(e.target.value, "checkvalu");
          onChangeForSearchFields("customer_type", e.target.value);
        }}
        value={filters.customer_type}
      >
        <option value="" selected>
          All
        </option>
        {customerDrop()}
      </select>
    ),
    city: (
      <Select
        options={branchArr}
        value={branchSelected}
        onChange={(e) => {
          setBranchSelected(e);
          onChangeForSearchFields(e.value);
        }}
      />
    ),
    dob: (
      <span className="d-flex">
        <div>
          <div>Start Date</div>
          <input
            type="date"
            id="start_date"
            placeholder="start date"
            style={{ width: "7rem" }}
            value={filters.birth_start_date}
            onChange={(e) =>
              onChangeForSearchFields("birth_start_date", e.target.value)
            }
          />
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <div>End Date</div>
          <input
            type="date"
            id="end_date"
            placeholder="end date"
            style={{ width: "7rem" }}
            value={filters.birth_end_date}
            onChange={(e) =>
              onChangeForSearchFields("birth_end_date", e.target.value)
            }
          />
        </div>
      </span>
    ),
    createdAt: (
      <span className="d-flex">
        <div>
          <div>Start Date</div>
          <input
            type="date"
            id="start_date"
            placeholder="start date"
            style={{ width: "7rem" }}
            value={filters.start_date}
            onChange={(e) =>
              onChangeForSearchFields("start_date", e.target.value)
            }
          />
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <div>End Date</div>
          <input
            type="date"
            id="end_date"
            placeholder="end date"
            style={{ width: "7rem" }}
            value={filters.end_date}
            onChange={(e) =>
              onChangeForSearchFields("end_date", e.target.value)
            }
          />
        </div>
      </span>
    ),
    // gender: (
    //   <input
    //     type="text"
    //     id="gender"
    //     placeholder="search by gender"
    //     // onChange={(e) =>
    //     //   onChangeForSearchFields("product_name", e.target.value)
    //     // }
    //   />
    // ),
    upgradeAmount: (
      <span className="d-flex">
        <input
          type="number"
          id="memb_upg_amount_min"
          placeholder="min amount"
          style={{ width: "6rem" }}
          value={filters.memb_upg_amount_min}
          onChange={(e) =>
            onChangeForSearchFields("memb_upg_amount_min", e.target.value)
          }
        />

        <input
          type="number"
          id="memb_upg_amount_max"
          placeholder="max amount"
          style={{ width: "6rem", marginLeft: "1rem" }}
          value={filters.memb_upg_amount_max}
          onChange={(e) =>
            onChangeForSearchFields("memb_upg_amount_max", e.target.value)
          }
        />
      </span>
    ),
    total_spend: (
      <span>
        <input
          type="number"
          id="min_price"
          placeholder="min price"
          style={{ width: "6rem" }}
          value={filters.min_price}
          onChange={(e) => onChangeForSearchFields("min_price", e.target.value)}
        />
        <input
          type="number"
          id="max_price"
          placeholder="max price"
          style={{ width: "6rem", marginLeft: "1rem" }}
          value={filters.max_price}
          onChange={(e) => onChangeForSearchFields("max_price", e.target.value)}
        />
      </span>
    ),
    memb_reduce_amount: (
      <span>
        <input
          type="number"
          id="memb_reduce_amount_min"
          placeholder="min amount"
          style={{ width: "6rem" }}
          value={filters.memb_reduce_amount_min}
          onChange={(e) =>
            onChangeForSearchFields("memb_reduce_amount_min", e.target.value)
          }
        />
        <input
          type="number"
          id="memb_reduce_amount_max"
          placeholder="max amount"
          style={{ width: "6rem", marginLeft: "1rem" }}
          value={filters.memb_reduce_amount_max}
          onChange={(e) =>
            onChangeForSearchFields("memb_reduce_amount_max", e.target.value)
          }
        />
      </span>
    ),

    upgradeCategory: (
      <Select
        options={[
          { value: "", label: "All" },
          { value: "General", label: "General" },
          { value: "Silver", label: "Silver" },
          { value: "Gold", label: "Gold" },
          { value: "Platinum", label: "Platinum" },
        ]}
        // value={{
        //   value: filters.memb_upg_categ ? filters.memb_upg_categ : "",
        //   label: filters.memb_upg_categ
        //     ? capitalize(filters.memb_upg_categ)
        //     : "All",
        // }}
        onChange={(e) => onChangeForSearchFields("memb_upg_categ", e.value)}
      />
    ),

    action: "search",
  };

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }



  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown color="#FFC006" />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp color="#FFC006" />
            </span>
          );
        return null;
      },


    },
    {
      dataField: "telephone",
      text: "Telephone",
      sort: false,

    },
    {
      dataField: "type",
      text: "Membership Type",
      sort: false,

    },
    {
      dataField: "city",
      text: "Branch",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown color="#FFC006" />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp color="#FFC006" />
            </span>
          );
        return null;
      },

    },
    {
      dataField: "dob",
      text: "DOB",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown color="#FFC006" />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp color="#FFC006" />
            </span>
          );
        return null;
      },

    },
    // {
    //   dataField: "gender",
    //   text: "Gender",
    //   sort: false,
    // },
    {
      dataField: "upgradeAmount",
      text: "Member Upgrade Amount",
      sort: false,

    },
    {
      dataField: "total_spend",
      text: "Member Total Spend",
      sort: false,

    },

    {
      dataField: "upgradeCategory",
      text: "Member Upgrade Category",
      sort: false,

    },
    {
      dataField: "memb_reduce_amount",
      text: "Retaining Amount",
      sort: false,

    },
    {
      dataField: "createdAt",
      text: "created At",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown color="#FFC006" />
              <FaLongArrowAltUp />
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <FaLongArrowAltDown />
              <FaLongArrowAltUp color="#FFC006" />
            </span>
          );
        return null;
      },

    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: records,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const getAllRows = () => {
    const rows = [...productData];
    rows.unshift(searchBoxes);
    return rows;
  };

  const { SearchBar } = Search;

  useEffect(() => {
    setPageNo(1);
    console.log("Customer list required");
    dispatch(fetchOnlyCustomerList(pageNo, getFilterQuery(), records));
  }, []);

  useEffect(() => {
    dispatch(getAllCustomerRoles());
  }, []);

  const onClickFunction = (row) => {
    if (!isNaN(row.telephone)) navigate("/customer/" + row.telephone);
  };
  const onExportReport = async (queries) => {
    setLoading(true); // Start loader
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/export_customer_list/?${queries}`
      );

      if (response && response.data?.status === "success") {
        window.open(response?.data?.url);
        toast.success(`Export Data SuccessFully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setFilters(filterInputValues);
      } else if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false); // Stop loader
      return response.data.data;
    } catch (error) {
      setLoading(false); // Stop loader in case of error
    }
  };

  if (permission.viewPermission)
    if (loading === false) {
      return (
        <React.Fragment>
          <div className="page-content">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <Card.Title className="h4 mb-2">
                        Customer Datatable{" "}
                      </Card.Title>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="customer_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="telephone"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block col-12 d-flex justify-content-between">
                                      {/* <div className="position-relative"> */}
                                      {/* <SearchBar {...toolkitProps.searchProps} /> */}
                                      {/* <i className="search-box chat-search-box" /> */}
                                      {/* </div> */}
                                      <div className="d-flex">
                                        <Button
                                          className="btn btn-warning mb-3 "
                                          onClick={() =>
                                            showFilteredCustomers(1)
                                          }
                                          style={{ marginRight: "1rem" }}
                                        >
                                          Show Customer List
                                        </Button>
                                        {/* <Button
                                        className="btn btn-warning mb-3 "
                                        onClick={() =>
                                          setAddCustomerToGrpModal(true)
                                        }
                                      >
                                        Add Customers to Group
                                      </Button> */}
                                        <Button
                                          className="btn btn-warning mb-3"
                                          onClick={() => onExportReport(getFilterQuery())}
                                          disabled={loading} // Disable button while loading
                                        >
                                          {loading ? "Exporting..." : "Export Filtered Customer List"}
                                        </Button>

                                        <AddCustomerToModal
                                          show={addCustomerToGrpModal}
                                          close={() =>
                                            setAddCustomerToGrpModal(false)
                                          }
                                          queryString={getFilterQuery()}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">

                                      <BootstrapTable
                                        keyField="customer_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        rowEvents={{
                                          onClick: function (e, row) {
                                            onClickFunction(row);
                                          },
                                        }}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        // headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />

                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col xl={6}>
                                    <CustomerTablePagination
                                      pageNum={Math.ceil(
                                        (customers.data
                                          ? customers.data.total_customer_count
                                          : 0) / records
                                      )}
                                      onPageChange={showFilteredCustomers}
                                    />
                                  </Col>
                                  <Col className="d-flex">
                                    <div style={{ marginLeft: "auto" }}>
                                      <Select
                                        options={[
                                          { label: "10", value: "10" },
                                          { label: "100", value: "100" },
                                          { label: "500", value: "500" },
                                          { label: "1000", value: "1000" },
                                        ]}
                                        onChange={(e) => {
                                          dispatch(
                                            fetchOnlyCustomerList(
                                              pageNo,
                                              getFilterQuery(),
                                              e.value
                                            )
                                          );
                                          setRecords(e.value);
                                        }}
                                        className="ml-auto"
                                        value={{
                                          label: records,
                                          value: records,
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
        </React.Fragment>
      );
    } else return <LoaderTable />;
  return <Unauthorized />;
};

export default CustomerTable;
