import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../Loader/LoaderTable";

import { getCustomInvestorReport } from "../../store/actionCreators/Reports/InvestorReportAction";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const InvestorCustomReport = ({
  show,
  setCustomReportOpen,
  customReportData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cgclReport = useSelector((state) => state.customInvestorReport);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [productData, setProductData] = useState([]);

  const [query, setQuery] = useState({
    // investor_id: props.investor_id,
    // branch_id: props.branchId,
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  // useEffect(() => {
  //   const queryClone = { ...query };

  //   dispatch(getCustomInvestorReport(queryClone));
  // }, []);

  useEffect(() => {
    if (cgclReport.data && cgclReport.data.status === "success") {
      setisDataFetched(true);

      const productDataClone = cgclReport.data.data.map((e) => {
        return {
          ...e,
        };
      });
      setProductData(productDataClone);
    } else if (cgclReport?.data?.status === "failure") {
      setProductData([]);
      setisDataFetched(true);
    }
  }, [cgclReport]);

  const exportCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/export_custom_investor_report`,
        customReportData
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      } else {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data?.data;
    } catch (error) {
      console.log(error, "oooo");
    }
  };

  // const onShowReport = () => {
  //   setProductData([]);

  //   const queryClone = { ...query };

  //   dispatch(getCustomInvestorReport(queryClone));
  //   setShowReportClicked(true);
  // };
  const mailFunction = (row) => {
    navigate("/investor/investorReports/mail", { state: { data: row } });
  };

  function rankFormatter(cell, row) {
    return (
      <div
        style={{
          textAlign: "start",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Button
          className="btn btn-sm btn-warning me-2"
          onClick={() => mailFunction(row)}
        >
          Mail
        </Button>
      </div>
    );
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "percentage_cut",
      text: "Percentage Cut",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "sale",
      text: "Sale",
      sort: true,
    },
    {
      dataField: "gst",
      text: "GST",
      sort: true,
    },
    {
      dataField: "sale_after_tax",
      text: "Sale After Tax",
      sort: true,
    },
    {
      dataField: "commission",
      text: "Commission",
      sort: true,
    },
    {
      dataField: "tds",
      text: "TDS",
      sort: true,
    },
    {
      dataField: "net_payable",
      text: "Net Payable Amount",
      sort: true,
    },
    {
      dataField: "Mail",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <Modal
      centered
      show={show}
      size="xl"
      closeButton
      onHide={() => setCustomReportOpen(false)}
    >
      <Modal.Header closeButton>Custom Investor Report</Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <React.Fragment>
            <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Col className="d-flex">
                <label
                  className="my-auto "
                  style={{ marginLeft: "auto", marginRight: "0.5rem" }}
                >
                  Export to:
                </label>
                <Select
                  options={[{ label: "xls", value: "xls" }]}
                  value={{ label: "xls", value: "xls" }}
                />
                <Button
                  className="btn btn-warning"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    exportCSV();
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="employee_id"
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="employee_id"
                          columns={columns}
                          data={productData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        srText=""
                                        {...toolkitProps.searchProps}
                                        onChange={(e) => {
                                          console.log(e);
                                        }}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="employee_id"
                                      responsive
                                      bordered={true}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                    {!productData.length > 0 &&
                                    cgclReport?.data?.status !== "failure" ? (
                                      <Loader />
                                    ) : (
                                      ""
                                    )}
                                    {cgclReport?.data?.status === "failure" &&
                                      !cgclReport?.data?.data &&
                                      !!isDataFetched &&
                                      "No data found"}
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </React.Fragment>
      </Modal.Body>
    </Modal>

    // </Container>
  );
};

export default InvestorCustomReport;
