import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Loader from "../Loader/LoaderTable";
import ScrollButton from "../scrolltotop/ScrollButton";
import { toast } from "react-toastify";
import { getDailyInvestorReport } from "../../store/actionCreators/Reports/InvestorReportAction";
import { useLocation, useNavigate } from "react-router-dom";
import ShowCustomInvestorReport from "./ShowCustomInvestorReport";
import IRPagination from "./IRPagination";

const ViewInvestor = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sbsReport = useSelector((state) => state.dailyInvestorReport);
  const [customReportModal, setCustomReportModal] = useState(false);
  const branch = useSelector((state) => state.branch);
  const [page, setPage] = useState(1);
  const [showReportClicked, setShowReportClicked] = useState(false);

  const [isDataFetched, setisDataFetched] = useState(false);
  const [branchArr, setBranchArr] = useState({
    label: "",
    value: "",
  });

  const [query, setQuery] = useState({
    investor_id: location?.state?.data?.investor_id,
    branch_id: branchArr[0],

    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [empSelected, setEmpSelected] = useState({
    label: "All",
    value: "All",
  });
  const [branchSelected, setBranchSelected] = useState({
    label: "Ghumar Mandi",
    value: "f048edca-f663-4df4-bd54-ae5563d9fc6e",
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;

    dispatch(getDailyInvestorReport(queryClone, page));
  }, [page]);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  useEffect(() => {
    if (sbsReport?.data?.status !== "success") {
      setisDataFetched(false);
    }
    if (
      sbsReport.data &&
      sbsReport?.data?.status === "success" &&
      showReportClicked
    ) {
      setisDataFetched(true);

      const productDataClone = sbsReport?.data?.data.map((e) => {
        return {
          ...e,
        };
      });

      setProductData(productDataClone);
    } else if (sbsReport?.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [sbsReport, sbsReport?.data, page]);

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;

  queryClone.employee_id = empSelected.value;

  const exportSBSCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/export_daily_investor_report`,
        queryClone
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      } else {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data?.data;
    } catch (error) {}
  };

  const setDefaultData = () => {
    if (branch.data && branch.data?.data) {
      const branchArrClone = branch.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));

      setBranchArr(branchArrClone);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    dispatch(getDailyInvestorReport(queryClone, page));
    setShowReportClicked(true);
  };

  const mailFunction = (row) => {
    navigate("/investor/investorReports/mail", { state: { data: row } });
  };

  function rankFormatter(cell, row) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            className="btn btn-sm btn-warning me-2"
            onClick={() => mailFunction(row)}
          >
            Mail
          </Button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "percentage_cut",
      text: "Percentage Cut",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "sale",
      text: "Sale",
      sort: true,
    },
    {
      dataField: "gst",
      text: "GST",
      sort: true,
    },
    {
      dataField: "sale_after_tax",
      text: "Sale After Tax",
      sort: true,
    },
    {
      dataField: "commission",
      text: "Commission",
      sort: true,
    },
    {
      dataField: "tds",
      text: "TDS",
      sort: true,
    },
    {
      dataField: "net_payable",
      text: "Net Payable Amount",
      sort: true,
    },
    {
      dataField: "Mail",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const searchBoxes = {
    Cash_Sales_s: "Cash_Sales_s",

    Discount_s: "Discount_s",
    Order_Count_s: "Order_Count_s",
    Total_Tax_s: "Total_Tax_s",
    card_sales_s: "card_sales_s",

    action: "total",
  };

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: productData.length,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Card
        style={{
          background: "transparent",
          width: "100%",
          color: "#8d8d8d",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Card.Body>
          <Row>
            <Col sm>
              {" "}
              <strong>Investor Name: </strong>
              <span className="text-capitalize">
                {location?.state?.data.name}
              </span>
            </Col>
            <Col sm>
              {" "}
              <strong>Contact: </strong>
              {location?.state?.data.contact}
            </Col>
            <Col sm>
              {" "}
              <strong>GST No: </strong>
              {location?.state?.data.gst_no}
            </Col>
            <Row />

            <Row>
              <Col sm>
                {" "}
                <strong>Email: </strong>
                {location?.state?.data.email}
              </Col>
              <Col sm>
                {" "}
                <strong>Address: </strong>
                {location?.state?.data.address}
              </Col>
              <Col sm>
                {" "}
                <strong>Bank: </strong>
                {location?.state?.data.bank}
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                {" "}
                <strong>IFSC: </strong>
                {location?.state?.data.ifsc}
              </Col>
              <Col>
                {" "}
                <strong>Account No: </strong>
                {location?.state?.data.account_no}
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>

      <React.Fragment>
        <Row className="d-flex mb-2 mt-4">
          <Col xl="8 my-auto">
            <h5 className="my-auto">INVESTOR DAILY REPORT</h5>
          </Col>
          <Col xl="2" className="d-flex">
            <Button
              className="btn btn-warning "
              onClick={() => {
                setCustomReportModal(true);
              }}
            >
              Custom Report
            </Button>
            <ShowCustomInvestorReport
              show={customReportModal}
              setCustomReportModal={setCustomReportModal}
              data={location?.state}
            />
          </Col>
          <Col xl="2" className="d-flex">
            <Button className="btn btn-warning my-auto" onClick={onShowReport}>
              Show Report
            </Button>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>Filter</Card.Header>
              <Card.Body>
                <Form>
                  <Table style={{ width: "50%" }}>
                    <tbody>
                      <tr>
                        <td>From:</td>
                        <td className="py-2">
                          <Form.Control
                            type="date"
                            value={query.start}
                            onChange={(e) =>
                              setQuery({ ...query, start: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>To:</td>
                        <td className="py-2">
                          <Form.Control
                            type="date"
                            value={query.end}
                            onChange={(e) =>
                              setQuery({ ...query, end: e.target.value })
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Branch:</td>
                        <td className="">
                          <Select
                            options={branchArr}
                            value={branchSelected}
                            onChange={(e) => setBranchSelected(e)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {showReportClicked ? (
          <React.Fragment>
            <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Col className="d-flex">
                <label
                  className="my-auto "
                  style={{ marginLeft: "auto", marginRight: "0.5rem" }}
                >
                  Export to:
                </label>
                <Select
                  options={[{ label: "xls", value: "xls" }]}
                  value={{ label: "xls", value: "xls" }}
                />
                <Button
                  className="btn btn-warning"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    exportSBSCSV();
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="employee_id"
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="employee_id"
                          columns={columns}
                          data={productData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        srText=""
                                        {...toolkitProps.searchProps}
                                        onChange={(e) => {
                                          console.log(e);
                                        }}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="employee_id"
                                      responsive
                                      bordered={true}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                    {/* {sbsReport?.data?.status === "failure" &&
                                    "Something went Wrong"} */}
                                    {!productData.length > 0 &&
                                    sbsReport?.data?.status !== "failure" ? (
                                      <Loader />
                                    ) : (
                                      ""
                                    )}
                                    {sbsReport?.data?.status === "failure" &&
                                      !sbsReport?.data?.data &&
                                      !!isDataFetched &&
                                      "No data found"}
                                  </div>
                                </Col>
                              </Row>
                              {/* pagination component */}
                              <Row>
                                <IRPagination
                                  page={page}
                                  setPage={setPage}
                                  pageNum={Math.ceil(
                                    sbsReport.data
                                      ? sbsReport.data.total_count
                                        ? sbsReport.data.total_count / 10
                                        : 1
                                      : 1
                                  )}
                                />
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <ScrollButton />
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    </Container>
  );
};

export default ViewInvestor;
