import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateQrCode } from "./../../../store/actionCreators/Catalog/Catalog";

const EditQrModal = ({ show, handleClose, qr_code_id }) => {
  const [qrname, setQrname] = useState("");
  const dispatch = useDispatch();

  const updateqr = (e) => {
    e.preventDefault();

    const finalProd = {
      table_name: qrname,
      qr_code_id,
    };

    dispatch(updateQrCode(finalProd));
    handleClose();
  };

  return (
    <React.Fragment>
      (
      <Modal
        className="remove-border"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className=" bg-light text-dark remove-border" closeButton>
          <Modal.Title>Update QRCode Name</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" bg-light  remove-border">
          <div className="mx-auto mt-0">
            <form onSubmit={(e) => updateqr(e)}>
              <div>
                <div className="mb-2">
                  <label
                    style={{ fontColor: "black" }}
                    onChange={(e) => setQrname(e.target.value)}
                  >
                    QRCode Name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={qrname}
                    onChange={(e) => {
                      setQrname(e.target.value);
                    }}
                  />
                </div>

                <Button className="btn btn-warning" type="submit">
                  Update
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      )
    </React.Fragment>
  );
};

export default EditQrModal;
