import React from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";

const EditAddonsOrder = ({
  show,
  closeModal,
  selectedAddons,
  orderAddons,
  onSubmit,
}) => {
  console.log(selectedAddons,"checkout");
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Addons Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Row>
            <Col lg={8}>
              <div className="d-flex align-items-center">
                <h1 className="mb-2">Edit Addons Order</h1>
              </div>
              <Table bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>ADD ON</th>
                    <th>ORDER</th>
                    <th>REQUIRED</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedAddons.map((item, index) => (
                    <tr>
                      <td>{item.title}</td>
                      <td>
                        <Form.Control
                          onChange={(e) =>
                            orderAddons(
                              item.per_product_add_ons_id,
                              e.target.value,
                              item.required
                            )
                          }
                          value={item.order || ""}
                          type="number"
                          required
                          className="w-50"
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={item.required}
                          onChange={() =>
                            orderAddons(
                              item.per_product_add_ons_id,
                              item.order,
                              !item.required
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <div className="p-2">
            <Button className="btn btn-primary" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditAddonsOrder;
