import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import { useRef } from "react";

const EditProductForm = ({ step, setStep, setProductArr, EditProductData }) => {
  const [data, setData] = useState([]);
  // const [step, setStep] = useState(1);
  const groups = useSelector((state) => state.groups);
  const productData = useSelector((state) => state.products);

  const setInitialDataRef = useRef(false);

  const dataForAPI = data.filter((item) => {
    return item.selected;
  });

  useEffect(() => {
    setProductArr(dataForAPI);
  }, [data]);

  useEffect(() => {
    if( setInitialDataRef.current){
      return;
    }
    // console.log("RUNRUN");
    // listing data from store
    if (productData && productData.data.data) {
      setInitialDataRef.current = true;

      // modified data of store
      const newData = productData.data.data
        .map((item, index) => {
          return { ...item, index };
        })
        .map((item) => {
          const isSelected =
            (
              (EditProductData && EditProductData?.coupon_products) ||
              []
            ).findIndex((i) => {
              return i?.product_list_id === item?.product_list_id;
            }) > -1;

          if (isSelected) {
            // console.log("TEST isSelected", { item });
            item.selected = isSelected;
          }

          return item;
        });
      setData(newData);
    }
  }, [productData, EditProductData]);

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    // const matchingProduct = () => {
    //   return (
    //     ((EditProductData && EditProductData?.coupon_products) || []).findIndex(
    //       (i) => {
    //         return i?.product_list_id === row?.product_list_id;
    //       }
    //     ) > -1
    //   );
    // };

    return (
      <div
        style={{
          textAlign: "start",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Form.Check
          type="checkbox"
          // checked={matchingProduct()}
          checked={!!data[rowIndex]?.selected}
          onChange={(e) => {
            // data[rowIndex] = { ...data[rowIndex] };
            // data[rowIndex].selected = e.target.checked;
            setData((data) => {
              const updatedData = [...data];
              updatedData[rowIndex] = {
                ...data[rowIndex],
                selected: e.target.checked,
              };
              return updatedData;
            });
          }}
        />
      </div>
    );
  }
  const columns = [
    {
      dataField: "Select",
      text: "Select",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      editable: false,
    },
    {
      dataField: "product_name",
      text: "Product Name",
      sort: true,
      editable: false,
    },

    {
      dataField: "price",
      text: "Price",
      sort: true,
      editable: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "category_name",
      order: "asc",
    },
  ];
  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <div className="page-content ">
      <div className="mb-3">
        <strong>Product Discount Discount</strong>{" "}
      </div>
      <Row>
        <Col className="col-12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="product_list_id"
            columns={columns}
            data={data}
            paginationProps={{
              sizePerPageList: [50, 100, 200],
            }}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="product_list_id"
                columns={columns}
                data={data}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="search-box chat-search-box" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="product_list_id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            cellEdit={cellEditFactory({
                              blurToSave: true,
                              mode: "dbclick",
                              afterSaveCell: (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                data[row.index][column.dataField] = newValue;
                                setData([...data]);
                                console.log(
                                  { oldValue, newValue, row, column },
                                  "After Saving Cell!!"
                                );
                              },
                            })}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                            sizePerPageList={[50, 100, 200]}
                          />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            {...paginationProps}
                            className="table-pagination"
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>

      <Button
        className=" mx-2"
        type="warning"
        onClick={() => {
          setStep(step - 1);
        }}
      >
        Back
      </Button>
      <Button
        className="m-2"
        type="warning"
        onClick={() => {
          setStep(5);
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default EditProductForm;
