import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { MdCreditScore } from "react-icons/md";
import { NavLink } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Unauthorized from "../unauthorized";

const AllCredits = (props) => {


  
  if (props.viewPermission) {
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Row className="mb-3">
        <Col>
          <h5>CREDITS</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <NavLink to="/credits/credits/available">
            <Card>
              <div className="p-3 py-4" style={{ display: "flex" }}>
                <MdCreditScore
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "0.5rem",
                  }}
                />
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">Available Credit</Tooltip>
                  }
                >
                  <div className="my-auto">Available Credit</div>
                </OverlayTrigger>
              </div>
            </Card>
          </NavLink>
        </Col>
        <Col>
          <NavLink to="/credits/credits/used">
            <Card>
              <div className="p-3 py-4" style={{ display: "flex" }}>
                <MdCreditScore
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "0.5rem",
                  }}
                />
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-2">Used Credit</Tooltip>}
                >
                  <div className="my-auto">Used Credit</div>
                </OverlayTrigger>
              </div>
            </Card>
          </NavLink>
        </Col>
      </Row>
    </Container>
  );

} else {
  return <Unauthorized />;
}
};

export default AllCredits;
