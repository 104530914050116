import { toUpper } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getSinglePurchaseOrder } from "../../../store/actionCreators/Inventory/InventoryAction";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
const icon = require("../../../assets/logo.png");

export const PurchaseOrderPrint = ({ componentref, row }) => {
  const dispatch = useDispatch();
  const purchaseOrder = useSelector((state) => state.get_single_purchase_order);

  useEffect(() => {
    if (!!row) {
      dispatch(getSinglePurchaseOrder(row.purchase_orders_id));
    }
  }, [row]);

  return (
    <>
      {!!purchaseOrder && (
        <div ref={componentref} className="px-3 border m-2 border-dark">
          <div className="d-flex justify-content-between align-items-center my-4 p-2 ">
            <div>
              <img
                src={icon}
                alt=""
                // style={{
                //   opacity: "0.7",
                // }}
                height={50}
                width={50}
                className="m-2"
              />

              <div className="d-flex justify-content-start">
                <p>Order Id: &nbsp; </p>{" "}
                <strong className="font-weight-normal">
                  {purchaseOrder?.purchase_orders_id}
                </strong>
              </div>
              <div className="d-flex justify-content-start">
                <p>Ordered On: &nbsp; </p>
                <strong className="font-weight-normal">
                  {" "}
 {new Date(`${purchaseOrder?.createdAt}`).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})}
                  {/* {purchaseOrder?.createdAt?.toLocaleString().split("T")[0]} */}
                </strong>
              </div>
              <div className="d-flex justify-content-start">
                <p>Order Status: &nbsp; </p>
                <strong>{purchaseOrder.status}</strong>
              </div>
            </div>
            <div className="d-flex flex-column ">
              <div className="d-flex justify-content-end">
                <p>Store Address: &nbsp; </p>
                <strong>{purchaseOrder.store_name}</strong>
              </div>
              <div className="d-flex justify-content-end">
                <p>Vendor Name: &nbsp; </p>
                <strong className="font-weight-normal">
                  {purchaseOrder?.vendor_name}
                </strong>
              </div>
            </div>
          </div>

          <div>
            <div className="border border-dark">
              <div className="text-center ">
                {" "}
                <b>ORDER ITEMS</b>{" "}
              </div>
              <Card.Body className="border border-dark m-3">
                {/* <b className="font-weight-bold">
                  Order Id: {item.purchase_orders_id}
                </b> */}
                <hr className="my-1" />
                <div className="d-flex flex-column">
                  <table>
                    <tr></tr>
                    <th>S.No</th>
                    <th>Items</th>
                    <th>Unit</th>
                    <th>Tax</th>
                    <th>Total Price</th>
                    {/* <th>Vendor Name:</th> */}
                    <th>Status:</th>
                    {purchaseOrder &&
                      purchaseOrder?.purchase_order_items.map((item, i) => {
                        return (
                          <tr key={i + 1}>
                            <td>{i + 1}</td>
                            <td>{item.name}</td>

                            <td>{item.unit}</td>

                            <td>{item.tax}</td>

                            <td>{item.total_price}</td>

                            {/* <td>{item.vendor_name}</td> */}
                            <td>{item.status}</td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </Card.Body>
            </div>
            <div
              className="d-block p-2 m-2"
              style={{ width: "12rem", position: "relative", left: "70%" }}
            >
              <div className="d-flex flex-row justify-content-between  font-weight-normal ">
                <p>Sub Total:</p>
                <strong className="font-bold">
                  ₹{Math.round(purchaseOrder?.sub_total * 100) / 100}
                </strong>
              </div>
              <div className="d-flex flex-row justify-content-between  font-weight-normal ">
                <p>Tax:</p>
                <strong className="font-bold">
                  ₹{Math.round(purchaseOrder?.tax * 100) / 100}
                </strong>
              </div>
              <div className="d-flex flex-row justify-content-between font-weight-normal">
                <p>Discount:</p>
                <strong className="font-weight-bold">
                  ₹{Math.round(purchaseOrder?.discount * 100) / 100}
                </strong>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-between font-weight-normal ">
                <p>Total</p>
                <strong className="font-bold">
                  ₹{Math.round(purchaseOrder?.total_price * 100) / 100}
                </strong>
              </div>
            </div>
          </div>

          {/* <div className="font-weight-bold text-center my-2 ">
          -------- ****** --------
        </div>
        <div className="text-xs self-center text-center">
          <h6 className="font-bold mb-0">Heebee coffee private limited</h6>
          <br />
        </div> */}
        </div>
      )}
    </>
  );
};
export default PurchaseOrderPrint;
