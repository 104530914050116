import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  CalendarIcon,
  ClipboardListIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import Unauthorized from "../../unauthorized";

// import PerCustomerAnalysis from "./PerCustomerAnalysis";
// import CustomerListByGroup from "./CustomerListByGroup";
import CustomerSalesSummary from "./CustomerSalesSummary";
import CustomerGroupChangeLogs from "./CustomerGroupChangeLogs";
import CustomerWalletBalanceSummary from "./CustomerWalletBalanceSummary";
import CustomerSaleOrderList from "./CustomerSaleOrderList";

const CustomerReport = (props) => {
  const navigate = useNavigate();
  if (props.viewPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <Col>
            <Button className="my-2" onClick={() => navigate("/reports")}>
              Back to All Reports Section
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>CUSTOMER REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/customer/customersalessummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Customer Sales Summary</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/customer/customersaleorderlist">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Customer Sales Order List</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/customer/customergroupchangelogs">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Customer Group Change Logs</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/customer/customerwalletbalancesummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Customer Wallet Balance Summary</div>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Routes>
            <Route
              path="customersalessummary"
              element={
                <CustomerSalesSummary
                  branchObj={props.branchObj}
                  sideToggle={props.sideToggle}
                />
              }
            />
            <Route
              path="customersaleorderlist"
              element={<CustomerSaleOrderList sideToggle={props.sideToggle} />}
            />
            <Route
              path="customergroupchangelogs"
              element={
                <CustomerGroupChangeLogs sideToggle={props.sideToggle} />
              }
            />
            <Route
              path="customerwalletbalancesummary"
              element={
                <CustomerWalletBalanceSummary sideToggle={props.sideToggle} />
              }
            />
          </Routes>
        </Row>
      </Container>
    );
  else return <Unauthorized />;
};

export default CustomerReport;
