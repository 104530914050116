export function getAddonSizeData(addonList = []) {
  const addonSizesMap = addonList.reduce(
    (map, { add_on_option, size, quantity }) => {
      if (!map[add_on_option]) {
        map[add_on_option] = {};
      }

      map[add_on_option][size] = quantity;

      return map;
    },
    {}
  );

  const addonSizesData = Object.entries(addonSizesMap).map(
    ([name, sizeData]) => ({ name, ...sizeData })
  );

  return addonSizesData;
}
