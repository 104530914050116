export const fetchSalesByStaff = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_STAFF":
      return state;
    case "SET_SALES_BY_STAFF":
      return action.res;
    default:
      return state;
  }
};

export const fetchSalesByBranch = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_BRANCH":
      return state;
    case "SET_SALES_BY_BRANCH":
      return action.res;
    default:
      return state;
  }
};

export const SalesByStaffPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SBS_PAGE":
      return state;
    case "SET_SBS_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const fetchSalesByStaffDaily = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_STAFF_DAILY":
      return state;
    case "SET_SALES_BY_STAFF_DAILY":
      return action.res;
    default:
      return state;
  }
};

export const fetchSalesByBranchDaily = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_BRANCH_DAILY":
      return state;
    case "SET_SALES_BY_BRANCH_DAILY":
      return action.res;
    default:
      return state;
  }
};

export const SalesByStaffDailyPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SBSD_PAGE":
      return state;
    case "SET_SBSD_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const fetchStaffOrderList = (state = "", action) => {
  switch (action.type) {
    case "SET_STAFF_ORDER_LIST":
      return action.res;
    case "GET_STAFF_ORDER_LIST":
      return state;
    default:
      return state;
  }
};

export const StaffOrderListPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SOL_PAGE":
      return state;
    case "SET_SOL_PAGE":
      return action.page;
    default:
      return state;
  }
};

// export const exportsbsCSV = (state = "", action) => {
//   switch (action.type) {
//     case "GET_SBS_CSV":
//       return state;
//     case "GET_SBS_CSV":
//       return action.res;
//     default:
//       return state;
//   }
// };
export const fetchCustomLogoutReport = (state = "", action) => {
  switch (action.type) {
    case "GET_CUSTOM_LOGOUT_REPORT":
      return state;
    case "SET_CUSTOM_LOGOUT_REPORT":
      return action.res;
    default:
      return state;
  }
};
export const fetchSingleEmployeeLogoutReport = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_EMPLOYEE_REPORT":
      return { state, loading: true };
    case "SET_SINGLE_EMPLOYEE_REPORT":
      return { ...action.res, loading: false };
    default:
      return state;
  }
};

// INVESTORS SECTION
export const fetchDailyInvestorReport = (state = "", action) => {
  switch (action.type) {
    case "GET_DAILY_INVESTOR_REPORT":
      return { state, loading: true };
    case "SET_DAILY_INVESTOR_REPORT":
      return { ...action.res, loading: false };
    default:
      return state;
  }
};
export const fetchCustomInvestorReport = (state = "", action) => {
  switch (action.type) {
    case "GET_CUSTOM_INVESTOR_REPORT":
      return { state, loading: true };
    case "SET_CUSTOM_INVESTOR_REPORT":
      return { ...action.res, loading: false };
    default:
      return state;
  }
};
