import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
// import Unauthorized from "../unauthorized";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllNotificationBranches } from "../../store/actionCreators/Notification/NotificationAction";
import Async from "react-select/async";
import Unauthorized from "../unauthorized";

const AddNotification = ({ sideToggle, editPermission }) => {
  const [options, setOptions] = useState([]);
  const [selectedBranches, setSelected] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AllBranchList = useSelector(
    (state) => state.get_all_notification_branches
  );
  const [empSelected, setEmpSelected] = useState([
    {
      label: "All",
      value: "All",
    },
  ]);

  const [data, setData] = useState({
    title: "",
    body: "",
    branch_id: [],
    employee_id: [],
    notification_date: null,
  });
  useEffect(() => {
    dispatch(getAllNotificationBranches());
  }, []);

  useEffect(() => {
    let array = [];
    if (AllBranchList.data) {
      array = AllBranchList.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [AllBranchList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayBranches = () => {
    if (options) {
      return (
        <div className="branchSelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const loadDataEmp = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.full_name}`,
          value: e.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const branchString = useMemo(() => {
    return selectedBranches.filter(({ label }) => label !== "All");
  }, [selectedBranches]);

  const branchStringArr = useMemo(() => {
    return branchString.map(({ value }) => value);
  }, [branchString]);

  const empArr = useMemo(() => {
    return (empSelected || [])
      .filter(({ label }) => label !== "All")
      .map(({ value }) => value);
  }, [empSelected]);

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, body, branch_id, employee_id, notification_date } = data;

    const dataClone = {
      title,
      body,
      branch_id,
      employee_id,
      notification_date,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/notifications/add_new_notification`,
        {
          ...dataClone,
          branch_id: [...branchStringArr],
          employee_id: [...empArr],
          ...(notification_date && { notification_date }),
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`Successfully added`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/notifications");
        } else {
          toast.error(`Please check your input`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        if (err.response.data.name === "SequelizeValidationError") {
          toast.error(
            "There was a validation error. Please check your input.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else {
          console.log("error", err);
        }
      });
  };

  if (editPermission) {
    return (
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="page-content ">
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="">
                    <Card.Title className="h4 mb-4 my-auto">
                      Add New Notification
                    </Card.Title>
                    <form onSubmit={onSubmit}>
                      <Row className="mb-2">
                        <Col>
                          <label>Title:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.title}
                            onChange={(e) =>
                              setData({ ...data, title: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Body:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.body}
                            onChange={(e) =>
                              setData({ ...data, body: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Notification Date:</label>
                          <input
                            type="date"
                            className="form-control"
                            value={data.notification_date || ""}
                            onChange={(e) =>
                              setData({
                                ...data,
                                notification_date: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Employee:</label>
                          <Async
                            defaultOptions={[{ label: "All", value: "All" }]}
                            cacheOptions={[{ label: "All", value: "All" }]}
                            isLoading={false}
                            loadOptions={(e) => loadDataEmp(e)}
                            value={empSelected}
                            onChange={(e) => setEmpSelected(e)}
                            isMulti={true}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          {" "}
                          <div className="mb-3 p-2 ">
                            <label className="form-label">
                              Select Branches
                            </label>
                            <div className="d-flex">
                              {displayBranches()}
                              <span className="ms-3 p-2">{data.branch}</span>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <Button type="submit" className="btn btn-warning">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddNotification;
