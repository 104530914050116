import axios from "axios";
import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Table } from "react-bootstrap";

const Offer_Date = () => {
  const [dateRange, setDateRange] = useState({ start: "", end: ""});

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/update_offer_date`,
          dateRange
        );
  
        if (res.data && res.data.data) {
          console.log("API Response:", res.data.data);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
  };

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">SALES BY STAFF</h5>
        </Col>
      </Row>
      
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={dateRange.start}
                          onChange={(e) =>
                            setDateRange({ ...dateRange, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={dateRange.end}
                          onChange={(e) =>
                            setDateRange({ ...dateRange, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Button type="submit" className="btn btn-primary">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Offer_Date;
