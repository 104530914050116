import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getCustomInvestorReport } from "../../store/actionCreators/Reports/InvestorReportAction";
import InvestorCustomReport from "./InvestorCustomReport";

const ShowCustomInvestorReport = ({ show, setCustomReportModal, data }) => {
  const dispatch = useDispatch();
  const [customReportOpen, setCustomReportOpen] = useState(false);
  const [customReportData, setCustomReportData] = useState({
    investor_id: data?.data?.investor_id,
    branch_id: "",
    start: "",
    end: "",
  });

  const branch = useSelector((state) => state.branch);

  return (
    <>
      <Modal
        centered
        show={show}
    
        closeButton
        onHide={() => setCustomReportModal(false)}
      >
        <Modal.Header closeButton>Get Custom Investor Report</Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(
                getCustomInvestorReport({
                  ...customReportData,
                })
              );
              setCustomReportOpen(true);
            }}
          >
            <label>Branch: </label>
            <Select
              required
              onChange={(e) =>
                setCustomReportData({
                  ...customReportData,
                  branch_id: e.value,
                })
              }
              options={
                branch.data
                  ? branch.data.data
                    ? branch.data.data.map((b) => ({
                        label: b.branch_name,
                        value: b.branch_id,
                      }))
                    : []
                  : []
              }
            />

            <label>From: </label>
            <Form.Control
              type="date"
              name="from"
              required
              onChange={(e) =>
                setCustomReportData({
                  ...customReportData,
                  start: e.target.value,
                })
              }
            />
            <label>To: </label>
            <Form.Control
              type="date"
              name="to"
              required
              onChange={(e) =>
                setCustomReportData({
                  ...customReportData,
                  end: e.target.value,
                })
              }
            />

            <Button className="mt-2" type="submit">
              Get Report
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <InvestorCustomReport
        show={customReportOpen}
        setCustomReportOpen={setCustomReportOpen}
        customReportData={customReportData}
      />
    </>
  );
};

export default ShowCustomInvestorReport;
