import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../../unauthorized";
import axios from "axios";
import Async from "react-select/async";
import { getGroupList } from "../../../store/actionCreators/Groups/GroupsAction";
// components
import AddNewGroupsCoupon from "../AddCoupon/AddNewGroupsCoupon";
import { useMemo } from "react";
import { toast } from "react-toastify";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { getSingleCouponNew } from "../../../store/actionCreators/Coupons/CouponsAction";
import { useNavigate, useParams } from "react-router-dom";
import ViewFlatDiscountForm from "./ViewFlatDiscountForm";
import ViewPercentageDiscountForm from "./ViewPercentageDiscountForm";
import ViewProductDiscount from "./ViewProductDiscount";
import ViewAddonDiscountForm from "./ViewAddonDiscountForm";
import ViewLastForm from "./ViewLastForm";
import ViewLastFormPro from "./ViewLastFormPro";
import { useCallback } from "react";
import { getAllCustomerRoles } from "../../../store/actionCreators/Customers/CustomerRolesActions";

const VewCouponMain = ({
  editPermission,
  branchArray,
  groupArray,
  empArray,
  empObj,
  branchObj,
}) => {
  const coupon = useSelector((state) => state.get_single_coupon);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const groups = useSelector((state) => state.groups);
  const { isLoading, setIsLoading } = useState(false);
  const [selectedCustomers, setCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBranches, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  // from addons
  const [productFullData, setProductFullData] = useState([]);
  // const [sendGroups, setSendGroups] = useState([]);

  const [productArr, setProductArr] = useState([]);
  const [addonsArr, setAddonsArr] = useState([]);

  const specialCustomer = coupon?.data?.data?.customers || [];

  const specialEmployees = coupon?.data?.data?.employees || [];

  const fetchSpecialCustomers = async (specialCustomer) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/fetch_special_customer`,
        { customers: [...specialCustomer] }
      );
      if (response && response.data?.status === "success") {
        setCustomers(
          (response?.data?.data || []).map((c) => ({
            label: `${c?.first_name} ${c.last_name ? c.last_name : ""}(${
              c?.mobile_no
            })`,
            value: c?.customer_id,
          }))
        );
      } else {
      }
    } catch (error) {
      console.log(error, "oooo");
    }
  };

  const fetchSpecialEmployees = async (specialEmployees) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/fetch_special_employees`,
        { employees: [...specialEmployees] }
      );
      if (response && response.data?.status === "success") {
        setEmployees(
          (response?.data?.data || []).map((c) => ({
            label: `${c?.full_name} ${c?.full_name}(${c?.mobile_no})`,

            value: c?.employee_id,
          }))
        );
      } else {
      }
    } catch (error) {
      console.log(error, "oooo");
    }
  };

  const newProductData = useMemo(
    () => productFullData.filter(({ selected }) => selected),
    [productFullData]
  );

  const addOnData = useMemo(
    () => newProductData.map(({ addonData }) => addonData),
    [newProductData]
  );

  // useEffect(() => {
  //   console.log("SMARTSHIVA", { newProductData });
  // }, [newProductData]);

  // useEffect(() => {
  //   console.log("SMARTSHIVA", addOnData);
  // }, [addOnData]);
  
  useEffect(() => {
    dispatch(getAllCustomerRoles());
  }, []);

  useEffect(() => {
    dispatch(getSingleCouponNew(params.coupon_id));
    fetchSpecialCustomers(specialCustomer);

    fetchSpecialEmployees(specialEmployees);
  }, []);

  const dataForAPIProducts = useMemo(
    () =>
      productFullData
        .filter(({ selected }) => selected)
        .map(({ addonData: _, ...restFields }) => restFields),
    [productFullData]
  );

  // console.log("SMARTSHIVA", dataForAPIProducts);

  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [data, setData] = useState([]);

  // console.log(selectedCustomers, "checked");

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  function getDropdownButtonLabel1({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel1}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
            isDisabled={true}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    setData(coupon?.data?.data);
    setCustomers(
      (selectedCustomers || []).map((c) => ({
        label: `${c?.first_name} ${c?.last_name ? c?.last_name : ""}(${
          c?.mobile_no
        })`,
        value: c?.customer_id,
      }))
    );
    setEmployees(
      (employees || []).map((c) => ({
        label: `${c.full_name} ${c.full_name}(${c.mobile_no})`,

        value: c.employee_id,
      }))
    );

    setSelectedGroup(
      (data?.groups || []).map((c) => ({
        label: `${c}`,
        value: c,
      }))
    );

    setSelected(
      (data?.branches || []).map((c) => ({
        label: `${c}`,
        value: c,
      }))
    );
  }, [coupon, data]);

  useEffect(() => {
    let array = [];
    if (branchArray) {
      array = branchArray.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [branchArray]);

  useEffect(() => {
    dispatch(getGroupList());
  }, []);

  useEffect(() => {
    if (groups.data) {
      if (groups?.data.data) {
        setGroupOptions(
          groups.data.data.map((item) => ({
            label: item.customer_group_name,
            value: item.customer_group_name,
          }))
        );
      }
    }
  }, [groups]);

  const onChangeForFields = (field_name, value) => {
    const dataClone = { ...data };
    dataClone[field_name] = value;
    setData(dataClone);
  };

  let customerString = useMemo(() => {
    let customerString = "";
    for (let i = 0; i < selectedCustomers.length; i++) {
      if (i === selectedCustomers.length - 1) {
        customerString = customerString + selectedCustomers[i].value;
      } else {
        customerString = customerString + selectedCustomers[i].value + ",";
      }
    }

    return customerString;
  }, [selectedCustomers]);

  const customertypeOptions = [
    { label: "All Customers", value: "All" },
    { label: "Groups", value: "Groups" },
    { label: "Special Customers", value: "Special Customers" },
  ];

  const couponTypeOptions = [
    { label: data?.discount_type, value: data?.discount_type },
    { label: "Flat Discount", value: "flat" },
    { label: "Percentage Discount", value: "percentage" },
    { label: "Product Discount", value: "product" },
    { label: "Add on Discount", value: "addon" },
  ];

  const handleChange = (entry) => (e) => {
    setData({ ...data, [entry]: e.target.value });
  };

  const customerTypeSelection = (
    <div>
      <label className="mb-2">Select Customer Type:</label>
      <Select
        className="mb-2"
        options={customertypeOptions}
        value={{
          label: String(data?.customer_type),
          value: data?.customer_type,
        }}
        onChange={(e) => setData({ ...data, customer_type: e.value })}
        isDisabled={true}
      />
      <Button
        className=" mb-2 mx-2"
        type="warning"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <Button
        className="mb-2 mx-2"
        type="warning"
        onClick={() => {
          if (
            data?.customer_type === "All" ||
            data?.customer_type === "Platinum" ||
            data?.customer_type === "Silver" ||
            data?.customer_type === "General" ||
            data?.customer_type === "Gold"
          )
            setStep(3);
          else setStep(2);
        }}
      >
        Next
      </Button>
    </div>
  );

  const loadCustomers = async (customer) => {
    // console.log(customer);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_customers/1?search=${customer}`
      );
      console.log(res);
      if (res.data && res.data.data) {
        const data = res.data?.data.map((c) => ({
          label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
            c.mobile_no
          })`,
          value: c.customer_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_employees/1?search=${emp}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.full_name} ${c.full_name}(${c.mobile_no})`,
          value: c.employee_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const loadGroupData = async (group) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_groups/1?search=${group}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.customer_group_name} `,
          value: c.customer_group_name,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const step2 = {
    "Special Customers": (
      <div>
        <div className="mb-2" style={{ width: "20rem" }}>
          <label className="mb-2">Select Customers:</label>
          <Async
            isMulti={true}
            defaultOptions={[]}
            cacheOptions={[{ label: "1234", value: "1234" }]}
            isLoading={isLoading}
            value={selectedCustomers}
            loadOptions={(e) => loadCustomers(e)}
            onChange={(e) => setCustomers(e)}
          />
        </div>
        <Button
          className="mb-2 mx-2"
          type="warning"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        <Button
          className="mb-2"
          type="warning"
          onClick={() => {
            setStep(3);
          }}
        >
          Next
        </Button>
      </div>
    ),

    Groups: (
      <div>
        <div className="d-flex">
          <Button type="warning" onClick={() => setShowModal(true)}>
            Add New Group
          </Button>
          <AddNewGroupsCoupon
            show={showModal}
            close={() => setShowModal(false)}
          />
        </div>
        <div className="mb-2">
          <label className="mt-2 mb-2">Select a group:</label>
          <Async
            defaultOptions={[]}
            cacheOptions={[{ label: "1234", value: "1234" }]}
            isLoading={isLoading}
            value={selectedGroup}
            loadOptions={(e) => loadGroupData(e)}
            onChange={(e) => setSelectedGroup(e)}
            isMulti={true}
            isDisabled={true}
          />
        </div>
        <Button
          className="mb-2 mx-2"
          type="warning"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        <Button
          className="mb-2"
          type="warning"
          onClick={() => {
            setStep(3);
          }}
        >
          Next
        </Button>
      </div>
    ),
  };

  const step3 = (
    <div>
      <label className="mb-2">Select Coupon Type:</label>
      <Select
        className="mb-2"
        options={couponTypeOptions}
        defaultValue={couponTypeOptions[0]}
        onChange={(e) => setData({ ...data, discount_type: e.value })}
        required
        isDisabled={true}
      />
      {data?.discount_type === "flat" && step === 3 ? (
        <Col className="mb-2">
          <label> Flat Discount Amount </label>
          <input
            onChange={(e) =>
              onChangeForFields("discount_value", e.target.value)
            }
            type="number"
            className="form-control"
            value={data?.discount_value}
            required
          />
        </Col>
      ) : data?.discount_type === "percentage" && step === 3 ? (
        <Col className="mb-2">
          <label>Percentage Discount </label>
          <input
            onChange={(e) =>
              onChangeForFields("discount_value", e.target.value)
            }
            type="number"
            className="form-control"
            value={data?.discount_value}
            required
          />
        </Col>
      ) : (
        ""
      )}
      <Button
        className="mb-2 mx-2"
        type="warning"
        onClick={() => {
          if (
            data?.customer_type === "All" ||
            data?.customer_type === "Platinum" ||
            data?.customer_type === "Gold" ||
            data?.customer_type === "General" ||
            data?.customer_type === "Silver"
          ) {
            setStep(1);
          } else {
            setStep(step - 1);
          }
        }}
      >
        Back
      </Button>
      <Button
        className="mb-2"
        type="warning"
        onClick={() => {
          if (data?.discount_type === "") {
            toast.error(`Please Select Coupon Type`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else setStep(4);
        }}
      >
        Next
      </Button>
    </div>
  );

  let { branchString } = useMemo(() => {
    let branchString = "";

    for (let i = 0; i < selectedBranches.length; i++) {
      if (selectedBranches[i].label === "All") {
        branchString = "All";
        break;
      } else if (i === selectedBranches.length - 1) {
        branchString = branchString + selectedBranches[i].value;
      } else {
        branchString = branchString + selectedBranches[i].value + ",";
      }
    }

    return { branchString };
  }, [selectedBranches]);

  let { empString } = useMemo(() => {
    let empString = "";

    for (let i = 0; i < employees.length; i++) {
      if (i === employees.length - 1) {
        empString = empString + employees[i].value;
      } else {
        empString = empString + employees[i].value + ",";
      }
    }

    return { empString };
  }, [employees]);

  let { groupString } = useMemo(() => {
    let groupString = "";

    for (let i = 0; i < selectedGroup.length; i++) {
      if (i === selectedGroup.length - 1) {
        groupString = groupString + selectedGroup[i].value;
      } else {
        groupString = groupString + selectedGroup[i].value + ",";
      }
    }

    return { groupString };
  }, [selectedGroup]);

  const handleFinalSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_new_coupon`,

        {
          ...data,
          addons: addOnData,
          products: dataForAPIProducts,
          customers: customerString,
          groups: groupString,
          branches: branchString,
          employees: empString,
        }
      )
      .then(
        (response) => {
          if (response?.data?.status === "success") {
            toast.success(`Successfully Added`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleFinalSubmitPro = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_new_coupon`,

        {
          ...data,

          products: productArr,
          customers: customerString,
          groups: groupString,
          branches: branchString,
          employees: empString,
        }
      )
      .then(
        (response) => {
          if (response?.data?.status === "success") {
            toast.success(`Successfully Added`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // forms components render
  const RenderStep4 = () => {
    if (data?.discount_type === "flat" && step === 4) {
      return (
        <ViewFlatDiscountForm
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmit}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
        />
      );
    } else if (data?.discount_type === "percentage" && step === 4) {
      return (
        <ViewPercentageDiscountForm
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          handleChange={handleChange}
          handleFinalSubmit={handleFinalSubmit}
          displayBranch={displayBranch}
          empArray={empArray}
          empObj={empObj}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
        />
      );
    } else if (data?.discount_type === "product" && step === 4) {
      return (
        <ViewProductDiscount
          step={step}
          ViewProductData={data}
          setStep={setStep}
          setProductArr={setProductArr}
        />
      );
    } else if (data?.discount_type === "addon" && step === 4) {
      return (
        <ViewAddonDiscountForm
          step={step}
          data={data}
          setStep={setStep}
          setAddonsArr={setAddonsArr}
          productFullData={productFullData}
          setProductFullData={setProductFullData}
        />
      );
    } else return "";
  };

  // forms components render
  const renderStep5 = () => {
    if (step === 5 && data?.discount_type === "addon") {
      return (
        <ViewLastForm
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmit}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
        />
      );
    } else if (step === 5 && data.discount_type === "product") {
      return (
        <ViewLastFormPro
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmitPro}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
        />
      );
    } else return "";
  };

  if (editPermission) {
    return (
      <div className="page-content ">
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="">
                  <Card.Title className="h4 mb-4 my-auto">
                    View Coupon
                  </Card.Title>
                  {step === 1 ? customerTypeSelection : ""}
                  {step === 2 && data?.customer_type === "Special Customers"
                    ? step2["Special Customers"]
                    : data?.customer_type === "Groups" && step === 2
                    ? step2["Groups"]
                    : ""}
                  {step === 3 && step3}

                  {RenderStep4()}
                  {renderStep5()}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
};

export default VewCouponMain;
