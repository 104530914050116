import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getGroupList,
  DeleteGroup,
  addNewGroup,
} from "../../store/actionCreators/Groups/GroupsAction";

const AddNewGroupModal = ({ show, close }) => {
  const [newGroup, setNewGroup] = useState("");
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.coupon_groups);
  const group = useSelector((state) => state.group);
  console.log(groups.data, "group");

  const addNewGroupButton = () => {
    if (newGroup === "") {
      toast.error(`Please enter a name for the group`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      dispatch(addNewGroup(newGroup));
    }
  };
  const closeModal = () => {
    close();
  };
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
          <div className="mb-2">
            <label>Group Name</label>
            <input
              required
              type="text"
              className="form-control"
              value={newGroup}
              onChange={(e) => {
                e.preventDefault();
                setNewGroup(e.target.value);
              }}
            />
          </div>

          <Button
            className="btn btn-warning"
            type="submit"
            onClick={() => {
              addNewGroupButton();
              close();
            }}
          >
            Add
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewGroupModal;
