import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import Unauthorized from "../unauthorized";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddStore = ({ editPermission, sideToggle }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    store_name: "",
    city: "",
    region: "",
    address: "",
    email: "",
    contact: "",
    timing: "",
    latitude: "",
    longitude: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      store_name,
      city,
      region,
      address,
      email,
      contact,
      timing,
      latitude,
      longitude,
    } = data;

    const dataClone = {
      store_name,
      city,
      region,
      address,
      email,
      contact,
      timing,
      latitude,
      longitude,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/add_store`,
        dataClone
      )
      .then((res) => {
        if (res.data.status === "success")
          toast.success(`successFully Added Store`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

        navigate("/inventory/allstores");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  if (editPermission) {
    return (
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="page-content ">
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="">
                    <Card.Title className="h4 mb-4 my-auto">
                      Add Store
                    </Card.Title>
                    <form onSubmit={onSubmit}>
                      <Row className="mb-2">
                        <Col>
                          <label>Store Name:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.store_name}
                            onChange={(e) =>
                              setData({ ...data, store_name: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>City:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.city}
                            onChange={(e) =>
                              setData({ ...data, city: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Region:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.region}
                            onChange={(e) =>
                              setData({ ...data, region: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Address:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.address}
                            onChange={(e) =>
                              setData({ ...data, address: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Email:</label>
                          <input
                            required
                            type="email"
                            className="form-control"
                            value={data.email}
                            onChange={(e) =>
                              setData({ ...data, email: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Contact:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            value={data.contact}
                            onChange={(e) =>
                              setData({ ...data, contact: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Timing:</label>
                          <input
                            type="text"
                            placeholder="00:00AM to 00:00PM"
                            className="form-control"
                            value={data.timing}
                            onChange={(e) =>
                              setData({ ...data, timing: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Latitude:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={data.latitude}
                            onChange={(e) =>
                              setData({ ...data, latitude: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Longitude:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={data.longitude}
                            onChange={(e) =>
                              setData({ ...data, longitude: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <Button type="submit" className="btn btn-warning">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddStore;
